import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import Chart from "react-apexcharts";
import moment from "moment";
import PendingVendorView from "../../components/PendingVendorView";
import LatestOrderView from "../../components/LatestOrderView";
import { rulePermission } from "../../constants/Rules";

const Index = () => {
  const apiDashBoxLink = "get-orders-summary";
  const apiDashboardChartLink = "get-orders-datewise";
  const [dashData, setDashData] = useState({
    total_vendor: 0,
    total_storefront: 0,
    total_storefront_user: 0,
    total_order: 0,
    total_amount: 0.0,
    total_quantity: 0.0,
  });
  const [chartData, setChartData] = useState([]);
  const [vendorView, setVendorView] = useState(true);
  const [orderView, setOrderView] = useState(true);
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getDashboardBoxData();
    getDashboardChartData();
  }, []);

  const getDashboardBoxData = async () => {
    await axiosInstance
      .get(apiDashBoxLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setDashData(res.data.result);
        }
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const getDashboardChartData = async () => {
    await axiosInstance
      .get(apiDashboardChartLink, {
        params: { limit: 5, days: 10 },
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setChartData(res.data.result);
        }
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  let days = [];
  chartData.reverse().map((data, index) => {
    return days.push(moment(data.date).format("DD MMM"));
  });
  let amounts = [];
  chartData.map((data, index) => {
    return amounts.push(data.total_amount);
  });

  const options = {
    chart: {
      id: "Day's of sales",
    },
    xaxis: {
      categories: days,
    },
  };
  const series = [
    {
      name: "Amount ($)",
      data: amounts,
    },
  ];

  const changeVendorView = (view) => {
    setVendorView(view);
  };

  const changeOrderView = (view) => {
    setOrderView(view);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - Smooth Pay Admin Portal</title>
      </Helmet>
      <div className="dashboard-shortcut">
        <div className="shortcut-card">
          <div>
            <div>
              <FaUser className=" bg-green-700 text-white" />
            </div>
            <div>
              {user.role_id === 11 || user.role_id === 14 ? (
                <>
                  <p>Total Store Front</p>
                  <p>{dashData.total_storefront}</p>
                </>
              ) : user.role_id === 13 ? (
                <>
                  <p>Store Front User</p>
                  <p>{dashData.total_storefront_user}</p>
                </>
              ) : (
                <>
                  <p>Total Vendor</p>
                  <p>{dashData.total_vendor}</p>
                </>
              )}
            </div>
          </div>
          <hr />
          <div>
            {/* <Link to={"/admin/vendor/list"} className="!text-green-700">
              <CgDetailsMore /> Show Details
            </Link> */}
            <Link
              to={
                user.role_id === 11 || user.role_id === 14
                  ? "/admin/store-front/list"
                  : user.role_id === 13
                  ? "/admin/store-front/user/list"
                  : "/admin/vendor/list"
              }
              className="!text-green-700"
            >
              <CgDetailsMore /> Show Details
            </Link>
          </div>
        </div>
        <div className="shortcut-card">
          <div>
            <div>
              <FaUser className=" bg-purple-700 text-white" />
            </div>
            <div>
              <p>Today's Order</p>
              <p>{dashData.total_order}</p>
            </div>
          </div>
          <hr />
          <div>
            <Link to={"/admin/order/today"} className="!text-purple-700">
              <CgDetailsMore /> Show Details
            </Link>
          </div>
        </div>
        <div className="shortcut-card">
          <div>
            <div>
              <FaUser className=" bg-lime-700 text-white" />
            </div>
            <div>
              <p>Transactions</p>
              <p>${dashData.total_amount}</p>
            </div>
          </div>
          <hr />
          <div>
            {/* <Link to={"/medicine/stockout"} className="!text-lime-700">
              <CgDetailsMore /> Show Details
            </Link> */}
            <a className="!text-lime-700">
              <CgDetailsMore /> No Details
            </a>
          </div>
        </div>
        <div className="shortcut-card">
          <div>
            <div>
              <FaUser className=" bg-yellow-700 text-white" />
            </div>
            <div>
              <p>Products Sold</p>
              <p>{dashData.total_quantity}</p>
            </div>
          </div>
          <hr />
          <div>
            {/* <Link to={"/medicine/expired"} className="!text-yellow-700">
              <CgDetailsMore /> Show Details
            </Link> */}
            <a className="!text-yellow-700">
              <CgDetailsMore /> No Details
            </a>
          </div>
        </div>
      </div>

      <div className="mt-14 p-6">
        {days.length > 0 && amounts.length > 0 ? (
          <>
            <div className="loginHeader mb-4">
              <h1>10 Day's Order Report</h1>
            </div>
            <Chart
              type="area"
              series={series}
              options={options}
              width={"100%"}
              height={300}
            />
          </>
        ) : (
          ""
        )}
      </div>
      <div className="dashRowsField">
        {rulePermission(21, user) && vendorView ? (
          <div>
            <div className="loginHeader mb-4">
              <h1>Pending Vendor's</h1>
            </div>
            <PendingVendorView changeView={changeVendorView} />
          </div>
        ) : (
          ""
        )}
        {orderView ? (
          <div>
            <div className="loginHeader mb-4">
              <h1>Latest Order's</h1>
            </div>
            <LatestOrderView changeView={changeOrderView} />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;
