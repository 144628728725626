/* ---- Production Configuration ---- */
// export const BASE_API_URL = "https://apiprod.smoothpay.net";
// export const API_URL = "https://apiprod.smoothpay.net/api/v1/";
// export const client_type = 4;
// export const client_version = 30000;
/* ---- Production Configuration ---- */

/* ---- Development Configuration ---- */
export const BASE_API_URL = "https://apidev.smoothpay.net";
export const API_URL = "https://apidev.smoothpay.net/api/v1/";
export const client_type = 4;
export const client_version = 10000;
/* ---- Development Configuration ---- */

export const SECRET_KEY = "DfLMRJf2rzcP4rfBknnveAY62duPVyJu";
export const disableRole = [1, 2, 10, 11, 12, 13];
export const excelArr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

export const filterRow = [10, 25, 50, 100, 250, 500];
export const phoneNumberValidator = /^\+?\d+\+?\d*$/;
export const stringValidate = /^[a-zA-Z]+$/;
var expression =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
export const siteUrlRegex = new RegExp(expression);
export const paymentType = [
  { payment_type: 1, label: "ACH" },
  { payment_type: 2, label: "Wire" },
  { payment_type: 3, label: "Check" },
];

export const colors = [
  "#00AFF0",
  "#87C80A",
  "#8C288C",
  "#FFCD00",
  "#26425A",
  "#43BD8E",
  "#568204",
  "#00A692",
  "#008080",
  "#89AA28",
  "##BB3B0E",
];

export const getPaymentType = (type) => {
  let result = paymentType.find((p_type) => p_type.payment_type === type);
  return result.label;
};

export const phonenumberFormat = (mobile_number) => {
  let phoneNumber = mobile_number.replace(/\D/g, "");

  if (phoneNumberValidator.test(phoneNumber) && phoneNumber.length < 12) {
    let match;
    let styleNumber;
    if (phoneNumber.length < 4) {
      match = phoneNumber.match(/^(\d{3})$/);
    } else if (phoneNumber.length < 7) {
      match = phoneNumber.match(/^(\d{3})(\d{3})$/);
    } else if (phoneNumber.length < 15) {
      match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    }

    if (phoneNumber.length < 4) {
      if (match) {
        styleNumber = "+(" + match[1];
      } else {
        styleNumber = mobile_number;
      }
    }
    if (phoneNumber.length > 3 && phoneNumber.length < 7) {
      if (match) {
        styleNumber = "+(" + match[1] + ") " + match[2];
      } else {
        styleNumber = mobile_number;
      }
    }
    if (phoneNumber.length > 6 && phoneNumber.length < 11) {
      if (match) {
        styleNumber = "+(" + match[1] + ") " + match[2] + "-" + match[3];
      } else {
        styleNumber = mobile_number;
      }
    }

    if (phoneNumber.length < 12) {
      return styleNumber;
    }
  }
  if (phoneNumber.length === 0) {
    if (mobile_number === "+") {
      return "+";
    } else {
      return "";
    }
  }
};
