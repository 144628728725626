const validity = (data) => {
  let error = {};
  let regex = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
  let passwordErr = [];
  let required = " is reuqired";

  if (!data.otp || data.otp === "") {
    error.otp = "OTP" + required;
  }

  if (!data.new_password || data.new_password === "") {
    passwordErr.push("Password" + required);
  }
  if (Object.keys(error).length === 0) {
    if (!regex.test(data.new_password)) {
      passwordErr.push(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  }
  if (passwordErr.length > 0) {
    error.new_password = passwordErr.join("\n");
    passwordErr = [];
  }
  if (!data.confirm_password || data.confirm_password === "") {
    passwordErr.push("Password" + required);
  }
  if (Object.keys(error).length === 0) {
    if (!regex.test(data.new_password)) {
      passwordErr.push(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  }
  if (passwordErr.length > 0) {
    error.confirm_password = passwordErr.join("\n");
  }

  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
