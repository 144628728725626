import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import axiosInstance from "../constants/axiosInstance";
import validity from "../error/ChangePassError";
import {
  confirmPasswordField,
  newPasswordField,
  oldPasswordField,
} from "../error/FieldErrorCheck";
import { logOut, updateLoading } from "../store/actions/User";

const ChangePassword = () => {
  const hitLink = "auth/update-password";
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({});
  const [serviceError, setServiceError] = useState("");
  const { access_token } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const updatePassword = async () => {
    let passData = {
      old_password,
      new_password,
      confirm_password,
    };

    const valid = validity(passData);
    if (valid.isValid) {
      if (old_password !== new_password) {
        setError({});
        setServiceError("");
        dispatch(updateLoading(true));
        await axiosInstance
          .post(hitLink, passData, { token: access_token })
          .then((res) => {
            if (res.data.result_code === 0) {
              toast.success("Password changed successfully", {
                autoClose: 2000,
              });
              emptyField();
            } else {
              toast.error(res.data.error.message, { autoClose: 2000 });
            }
            dispatch(updateLoading(false));
          })
          .catch((err) => {
            if (err.response) {
              setServiceError(err.response.data.error.message);
              if (err.response.data.result_code === 11) {
                dispatch(logOut());
              }
            } else {
              setServiceError(err.message);
            }
            dispatch(updateLoading(false));
          });
      } else {
        setError({ new_password: "Old and New password can't be same" });
      }
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setOld_password("");
    setNew_password("");
    setConfirm_password("");
  };

  return (
    <>
      <div className="loginBox m-auto mt-14">
        <div className="loginHeader">
          <h1>Change Password</h1>
          {serviceError ? <h2 className="errorMsg">{serviceError}</h2> : ""}
        </div>

        <div className="inputBody">
          <CustomInput
            label={"Old Password"}
            value={old_password}
            type="password"
            placeholder={"Enter Old Password"}
            onChange={(e) => {
              setOld_password(e.target.value);
              let err = oldPasswordField({ old_password: e.target.value });
              err ? setError(err) : setError({});
              setServiceError("");
            }}
            error={error.old_password ? true : false}
            errorMsg={error.old_password}
            required={true}
          />
          <CustomInput
            label={"New Password"}
            value={new_password}
            type="password"
            placeholder={"Enter New Password"}
            onChange={(e) => {
              setNew_password(e.target.value);
              let err = newPasswordField({ new_password: e.target.value });
              err ? setError(err) : setError({});
              setServiceError("");
            }}
            error={error.new_password ? true : false}
            errorMsg={error.new_password}
            required={true}
          />
          <CustomInput
            label={"Confirm Password"}
            value={confirm_password}
            type="password"
            placeholder={"Enter Confirm Password"}
            onChange={(e) => {
              setConfirm_password(e.target.value);
              let err = confirmPasswordField({
                confirm_password: e.target.value,
                password: new_password,
              });
              err ? setError(err) : setError({});
              setServiceError("");
            }}
            error={error.confirm_password ? true : false}
            errorMsg={error.confirm_password}
            required={true}
          />

          <CustomButton title={"Change Password"} onClick={updatePassword} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
