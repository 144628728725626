import { Paper } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import Select from "react-select";
import { FaDollarSign } from "react-icons/fa";
import { colors } from "../../constants/ConstantData";
import { useNavigate } from "react-router-dom";

const TransactionSummery = () => {
  const hitOrderLink = "get-orders";
  const hitVendorLink = "get-vendors";
  const hitStoreFrontLink = "get-vendor-storefronts";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [lastSortType, setLastSortType] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [reports, setReports] = useState({});

  const [vendors, setVendors] = useState([]);
  const [selectVendor, setSelectVendor] = useState({
    label: "Select Vendor",
    value: "",
  });
  const [storeFronts, setStoreFronts] = useState([]);
  const [selectStoreFront, setSelectStoreFront] = useState({
    label: "Select Store Front",
    value: "",
  });
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  useEffect(() => {
    getOrderList(
      rowsPerPage,
      page,
      "",
      "",
      user.role_id !== 13
        ? selectStoreFront?.value
        : user?.others_info?.store_fronts[0]?.id,
      startDateTime,
      endDateTime
    );
    getVendorList();
  }, []);

  const getVendorList = async () => {
    const params = {
      limit: 50000,
      is_approved: 1,
      order_by: "id, desc",
    };
    await axiosInstance
      .get(hitVendorLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setVendors(res.data.result.rows);
        }
      })
      .catch((error) => {});
  };

  const getOrderList = async (
    rowsPerPage,
    cPage,
    orderBy,
    search,
    storefronts,
    startDate,
    endDate
  ) => {
    dispatch(updateLoading(true));
    let params = {
      limit: rowsPerPage,
      page: cPage + 1,
      order_by: orderBy,
      search,
      storefronts,
      start_date:
        startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    if (startDate !== "" && endDate === "") {
      params = { ...params, start_date: "", end_date: "" };
    }
    let abortController = new AbortController();
    await axiosInstance
      .get(hitOrderLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          let data = res.data.result;
          setReports(data.summary);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
    abortController.abort();
  };

  let vendorOpts = [];
  vendors.map((vendor) => {
    return vendorOpts.push({ label: vendor.vendor_name, value: vendor.id });
  });

  const getStoreFrontData = async (vendor_id) => {
    let params = {
      limit: 50000,
      vendor_id,
      order_by: "id, desc",
    };

    await axiosInstance
      .get(hitStoreFrontLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStoreFronts(res.data.result.rows);
        }
      })
      .catch((error) => {});
  };

  let storeFrontOpts = [];
  storeFronts.map((storeFront) => {
    return storeFrontOpts.push({
      label: storeFront.sf_name,
      value: storeFront.id,
    });
  });

  const orderChangeByStartDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    if (endDateTime !== "") {
      getOrderList(
        rowsPerPage,
        page,
        lastSortType,
        searchStr,
        store_Fronts,
        date,
        endDateTime
      );
    }
  };

  const orderChangeByEndDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      store_Fronts,
      startDateTime,
      date
    );
  };

  const searchOrderData = (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getOrderList(
      rowsPerPage,
      0,
      lastSortType,
      e.target.value,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  const getStoreFrontOrder = (storefronts) => {
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      storefronts,
      startDateTime,
      endDateTime
    );
  };

  return (
    <>
      <Helmet>
        <title>Reports - Smooth Pay</title>
      </Helmet>

      <div className="adminBox">
        <div className="loginHeader relative">
          <h1 className="mb-4">Transaction Summary</h1>
        </div>
        <div className="tablePlace">
          <Paper>
            <div className="flex flex-col md:flex-row gap-4 justify-between place-items-center mx-4">
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-4 place-self-center">
                <ReactDatePicker
                  selected={startDateTime}
                  onChange={(date) => {
                    setStartDateTime(date);
                    orderChangeByStartDate(date);
                  }}
                  className="searchField"
                  placeholderText="Start Date"
                />
                <div className="flex place-self-center ">
                  <span>-</span>
                </div>
                <ReactDatePicker
                  selected={endDateTime}
                  onChange={(date) => {
                    setEndDateTime(date);
                    orderChangeByEndDate(date);
                  }}
                  className="searchField"
                  placeholderText="End Date"
                />
              </div>
            </div>

            <div className="boxHolder">
              {Object.keys(reports).length > 0 &&
                Object.entries(reports).map((item, index) => {
                  return (
                    <div
                      className="box"
                      style={{
                        borderLeftWidth: 3,
                        borderLeftColor: colors[index],
                      }}
                    >
                      <div>
                        <label>{item[0].replaceAll("_", " ")}</label>
                        <span>{"$" + item[1]}</span>
                      </div>
                      <div>
                        <FaDollarSign color="#CCC" size={22} />
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* <div className="w-full flex flex-row justify-end p-4">
              <a
                className="px-6 py-2 bg-lime-500 text-white rounded cursor-pointer"
                onClick={() => {
                  navigate(
                    `/report/order/report/${moment(startDateTime).format(
                      "YYYY-MM-DD"
                    )}/${moment(endDateTime).format("YYYY-MM-DD")}/${
                      selectVendor?.value
                    }/${selectStoreFront?.value}/${searchStr}`
                  );
                }}
              >
                Order Report
              </a>
            </div> */}
          </Paper>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default TransactionSummery;
