import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import axiosInstance from "../constants/axiosInstance";
import { phonenumberFormat, stringValidate } from "../constants/ConstantData";
import {
  confirmPasswordField,
  emailField,
  firstNameField,
  lastNameFiled,
  passwordField,
  vendorAddressField,
  vendorLocationField,
  vendorNameField,
  vendorPhoneField,
  vendorWebsiteField,
} from "../error/FieldErrorCheck";
import validity from "../error/RegisterError";
import { updateLoading } from "../store/actions/User";
import "../style/login.css";

const Register = () => {
  const hitLink = "auth/sign-up";
  const hitCountryLink = "get-countries";
  const hitStateLink = "get-states";
  const navigate = useNavigate();
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [vendor_name, setVendor_name] = useState("");
  const [vendor_phone, setVendor_phone] = useState("");
  const [vendor_address, setVendor_address] = useState("");
  const [vendor_location, setVendor_location] = useState("");
  const [vendor_website, setVendor_website] = useState("");
  const [state, setState] = useState({ label: "Select State", value: "" });
  const [country, setCountry] = useState({
    label: "Select Country",
    value: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [role_id] = useState(11);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getCountryList();
  }, []);

  const vendorRegister = async () => {
    const vendorData = {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      vendor_name,
      vendor_phone,
      vendor_address,
      vendor_location,
      vendor_website,
      role_id,
      country_id: country?.value,
      state_id: state?.value,
    };

    const valid = validity(vendorData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitLink, JSON.stringify(vendorData))
        .then((res) => {
          let data = res.data;
          if (data.result_code === 0) {
            toast.success("Registered Successfully", { theme: "colored" });
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          } else {
            toast.error(res.data.error.message, { autoClose: 2000 });
          }

          dispatch(updateLoading(false));
        })
        .catch((err) => {
          toast.error(err.response.data.error.message, { theme: "colored" });
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const getCountryList = async () => {
    await axiosInstance
      .get(hitCountryLink)
      .then((res) => {
        if (res.data.result_code === 0) {
          setCountries(res.data.result);
        }
      })
      .catch((error) => {});
  };

  const changeCountryState = async (option) => {
    let country_id = option.value;
    await axiosInstance
      .get(hitStateLink, { params: { country_id } })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStates(res.data.result);
        }
      })
      .catch((error) => {});
  };

  let countryopts = [];
  let stateOpts = [];
  countries.map((country, index) => {
    return countryopts.push({ label: country.name, value: country.id });
  });

  states.map((state, index) => {
    return stateOpts.push({ label: state.name, value: state.id });
  });

  return (
    <>
      <Helmet>
        <title>Create New Account</title>
      </Helmet>
      <div className="loginField h-screen overflow-y-auto">
        <div className="loginBox registerBox">
          <div className="loginHeader">
            <h1>Create Account</h1>
          </div>

          <div className="inputBody">
            <h2 className="text-xl">User Information:</h2>
            <div className="rowBox">
              <CustomInput
                value={first_name}
                label="First Name"
                placeholder={"Enter First Name"}
                type="text"
                onChange={(e) => {
                  setFirst_name(e.target.value);
                  let err = firstNameField({ first_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                error={error.first_name ? true : false}
                errorMsg={error.first_name}
                required={true}
              />

              <CustomInput
                value={last_name}
                label="Last Name"
                placeholder={"Enter Last Name"}
                type="text"
                onChange={(e) => {
                  setLast_name(e.target.value);
                  let err = lastNameFiled({ last_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                error={error.last_name ? true : false}
                errorMsg={error.last_name}
                required={true}
              />
            </div>

            <CustomInput
              value={email}
              label="E-mail"
              placeholder={"Enter E-mail"}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                let err = emailField({ email: e.target.value });
                err ? setError(err) : setError({});
              }}
              error={error.email ? true : false}
              errorMsg={error.email}
              required={true}
            />
            <div className="rowBox">
              <CustomInput
                value={password}
                label="Password"
                placeholder={"Enter Password"}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  let err = passwordField({ password: e.target.value });
                  err ? setError(err) : setError({});
                }}
                error={error.password ? true : false}
                errorMsg={error.password}
                required={true}
              />

              <CustomInput
                value={confirm_password}
                label="Confirm Password"
                placeholder={"Enter Confirm Password"}
                type="password"
                onChange={(e) => {
                  setConfirm_password(e.target.value);
                  let err = confirmPasswordField({
                    confirm_password: e.target.value,
                    password,
                  });
                  err ? setError(err) : setError({});
                }}
                error={error.confirm_password ? true : false}
                errorMsg={error.confirm_password}
                required={true}
              />
            </div>
            <h2 className="text-xl">Vendor Information:</h2>
            <div className="rowBox">
              <CustomInput
                value={vendor_name}
                label="Vendor Name"
                placeholder={"Enter Vendor Name"}
                type="text"
                onChange={(e) => {
                  setVendor_name(e.target.value);
                  let err = vendorNameField({
                    vendor_name: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                error={error.vendor_name ? true : false}
                errorMsg={error.vendor_name}
                required={true}
              />
              <CustomInput
                value={vendor_phone}
                label="Vendor Phone"
                placeholder={"Enter Vendor Phone"}
                type="text"
                onChange={(e) => {
                  if (!stringValidate.test(e.target.value)) {
                    if (phonenumberFormat(e.target.value) !== undefined) {
                      setVendor_phone(phonenumberFormat(e.target.value));
                    }
                  }
                  let err = vendorPhoneField({
                    vendor_phone: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                error={error.vendor_phone ? true : false}
                errorMsg={error.vendor_phone}
                required={true}
              />
            </div>

            <CustomInput
              value={vendor_address}
              label="Vendor Address"
              placeholder={"Enter Vendor Address"}
              type="text"
              onChange={(e) => {
                setVendor_address(e.target.value);
                let err = vendorAddressField({
                  vendor_address: e.target.value,
                });
                err ? setError(err) : setError({});
              }}
              error={error.vendor_address ? true : false}
              errorMsg={error.vendor_address}
              required={true}
            />

            <div className="rowBox">
              <Select
                value={country}
                defaultValue={country}
                options={countryopts}
                onChange={(option) => {
                  setCountry(option);
                  changeCountryState(option);
                }}
                className={error.country ? "error" : ""}
              />

              {/* ----Store Front State---- */}
              <Select
                value={state}
                defaultValue={state}
                options={stateOpts}
                onChange={(option) => setState(option)}
                className={error.state ? "error" : ""}
              />
            </div>

            <div className="rowBox">
              <CustomInput
                value={vendor_location}
                label="Vendor Location"
                placeholder={"Enter Vendor Location"}
                type="text"
                onChange={(e) => {
                  setVendor_location(e.target.value);
                  let err = vendorLocationField({
                    vendor_location: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                error={error.vendor_location ? true : false}
                errorMsg={error.vendor_location}
                required={true}
              />

              <CustomInput
                value={vendor_website}
                label="Vendor Website"
                placeholder={"Enter Vendor Website"}
                type="text"
                onChange={(e) => {
                  setVendor_website(e.target.value);
                  let err = vendorWebsiteField({
                    vendor_website: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                error={error.vendor_website ? true : false}
                errorMsg={error.vendor_website}
                required={true}
              />
            </div>

            <CustomButton title={"Get Started"} onClick={vendorRegister} />
          </div>
          <div className="alreadyRegister">
            <p>
              Already registered? <Link to={"/login"}>Login</Link>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Register;
