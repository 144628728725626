import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../constants/axiosInstance";
import { logOut, updateLoading } from "../../../store/actions/User";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { filterRow } from "../../../constants/ConstantData";
import { checkSortOrderUpdate } from "../../../constants/SortCheckUp";
import { useLoaderData } from "react-router-dom";

const PayoutList = () => {
  const { id } = useLoaderData();
  const hitLink = "get-payouts";
  const [payoutList, setPayoutList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [loader, setLoader] = useState(true);
  const [reload] = useState(false);
  const [lastSortType, setLastSortType] = useState("");
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "paid_id", value: "asc", active: false },
    { key: "paid_amount", value: "asc", active: false },
    { key: "transaction_fee", value: "asc", active: false },
    { key: "note", value: "asc", active: false },
    { key: "paid_date", value: "asc", active: false },
  ]);

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getVendorPayoutList(page, rowsPerPage, lastSortType, "", id);
  }, [page, reload]);

  const getVendorPayoutList = async (
    cPage,
    cRows,
    order_by,
    search,
    storefronts
  ) => {
    if (loader) {
      dispatch(updateLoading(true));
    }
    await axiosInstance
      .get(hitLink, {
        params: {
          page: cPage + 1,
          limit: cRows,
          order_by,
          search,
          storefronts,
        },
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setPayoutList(res.data.result);
          let sort = order_by.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
    getVendorPayoutList(newPage, rowsPerPage, lastSortType, searchStr, id);
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
    getVendorPayoutList(0, e.target.value, lastSortType, searchStr, id);
  };

  const searchVendorData = async (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    setLoader(false);
    getVendorPayoutList(0, rowsPerPage, lastSortType, e.target.value, id);
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    setLoader(false);
    getVendorPayoutList(
      page,
      rowsPerPage,
      sort.key + "," + sort.value,
      searchStr,
      id
    );
  };

  return (
    <>
      <Helmet>
        <title>Payout List - Smooth Pay</title>
      </Helmet>
      <div className="adminBox" id="vendor">
        <div className="loginHeader">
          <h1>Paid List</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="searchBox">
              <input
                placeholder="Search here"
                value={searchStr}
                onChange={searchVendorData}
                className="searchField"
              />
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        Store Front
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        Paid ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Payable
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Transaction Fee
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Total Tips Sent
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Amount Sent
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[5].active}
                        direction={sortArr[5].value}
                        onClick={() => orderListSorting(sortArr[5])}
                      >
                        Note
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[6].active}
                        direction={sortArr[6].value}
                        onClick={() => orderListSorting(sortArr[6])}
                      >
                        Pay Date
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payoutList.total > 0 ? (
                    payoutList?.rows.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>{data.sf_name}</TableCell>
                          <TableCell>{data.paid_id}</TableCell>
                          <TableCell>${data.payable_amount}</TableCell>
                          <TableCell>${data.transaction_fee}</TableCell>
                          <TableCell>${data.total_tips}</TableCell>
                          <TableCell>${data.paid_amount}</TableCell>
                          <TableCell>{data.note}</TableCell>
                          <TableCell>{data.paid_date}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={6} className="!text-center">
                          No Payout Order Fround
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {payoutList.total > 0 ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={payoutList.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default PayoutList;
