import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axiosInstance from "../../../constants/axiosInstance";
import { logOut, updateLoading } from "../../../store/actions/User";

const Role = () => {
  const hitRoleLink = "get-roles";
  const dispatch = useDispatch();

  const [roles, setRoles] = useState([]);
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = async () => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitRoleLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setRoles(res.data.result);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  return (
    <>
      <Helmet>
        <title>Portal Role List - Smooth Pay</title>
      </Helmet>
      <div className="adminBox">
        <div className="loginHeader">
          <h1>Role Permission</h1>
        </div>
        <div className="mt-4">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial</TableCell>
                    <TableCell>Role Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.hasOwnProperty("rows") ? (
                    roles.rows.map((role, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{++index}</TableCell>
                          <TableCell>{role.title}</TableCell>
                          <TableCell>
                            <Link
                              to={
                                "/admin/setting/user/role/permission/" +
                                role.id +
                                "/" +
                                role.title
                              }
                              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 hover:shadow hover:shadow-blue-300"
                            >
                              Permission Manage
                            </Link>
                            {/* <span className='bg-slate-600 text-white px-4 py-2 rounded hover:bg-slate-700 hover:shadow hover:shadow-blue-300'>
                                                    Permission Manage
                                                    </span> */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} className="text-center">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Role;
