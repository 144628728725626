import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../constants/axiosInstance";
import { logOut, updateLoading } from "../../../store/actions/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MdEditNote } from "react-icons/md";
import ConfigUpdatePOP from "../../../components/ConfigUpdatePOP";
import { rulePermission } from "../../../constants/Rules";

const Config = () => {
  const hitConfigLink = "get-sys-configs";
  const [config, setConfig] = useState({});
  const [configKey, setConfigKey] = useState("");
  const [configValue, setConfigValue] = useState("");
  const [configDetails, setConfigDetails] = useState("");
  const [updateView, setUpdateView] = useState(false);
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getConfigData();
  }, [reload]);

  const updateConfig = async (key, value, details) => {
    setConfigKey(key);
    setConfigValue(value);
    setConfigDetails(details);
    setUpdateView(true);
  };

  const getConfigData = async () => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitConfigLink, { token: access_token })
      .then((res) => {
        console.log(res);
        setConfig(res.data.result);
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const closeUpdateView = () => {
    setUpdateView(false);
    setReload(!reload);
  };

  return (
    <>
      <Helmet>
        <title>Configuration - Smooth Pay</title>
      </Helmet>
      {updateView ? (
        <ConfigUpdatePOP
          type={configKey}
          data={configValue}
          details={configDetails}
          closeView={closeUpdateView}
        />
      ) : (
        ""
      )}

      <div className="adminBox">
        <div className="loginHeader">
          <h1 className="mb-4">Configuration</h1>
        </div>
        <Paper>
          <h1 className="ml-4 text-lg">Fee Configure:</h1>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(config).length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell>1</TableCell>
                      <TableCell>{config?.ORDER_CUSTOMER_FEE?.value}</TableCell>
                      <TableCell>
                        {config?.ORDER_CUSTOMER_FEE?.value_type === 1
                          ? "$"
                          : "%"}
                      </TableCell>
                      <TableCell>
                        {config?.ORDER_CUSTOMER_FEE?.description}
                      </TableCell>
                      <TableCell>
                        <div className="actionBody">
                          <span
                            className="greenSignal"
                            title="Edit Config"
                            onClick={() =>
                              updateConfig(
                                "ORDER_CUSTOMER_FEE",
                                config?.ORDER_CUSTOMER_FEE?.value,
                                config?.ORDER_CUSTOMER_FEE?.description
                              )
                            }
                          >
                            <MdEditNote />
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>2</TableCell>
                      <TableCell>{config?.ORDER_SERVICE_FEE?.value}</TableCell>
                      <TableCell>
                        {config?.ORDER_SERVICE_FEE?.value_type === 1
                          ? "$"
                          : "%"}
                      </TableCell>
                      <TableCell>
                        {config?.ORDER_SERVICE_FEE?.description}
                      </TableCell>
                      <TableCell>
                        <div className="actionBody">
                          <span
                            className="greenSignal"
                            title="Edit Config"
                            onClick={() =>
                              updateConfig(
                                "ORDER_SERVICE_FEE",
                                config?.ORDER_SERVICE_FEE?.value,
                                config?.ORDER_SERVICE_FEE?.description
                              )
                            }
                          >
                            <MdEditNote />
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>3</TableCell>
                      <TableCell>{config?.ORDER_TRX_FEE?.value}</TableCell>
                      <TableCell>
                        {config?.ORDER_TRX_FEE?.value_type === 1 ? "$" : "%"}
                      </TableCell>
                      <TableCell>
                        {config?.ORDER_TRX_FEE?.description}
                      </TableCell>
                      <TableCell>
                        <div className="actionBody">
                          <span
                            className="greenSignal"
                            title="Edit Config"
                            onClick={() =>
                              rulePermission(47, user)
                                ? updateConfig(
                                    "ORDER_TRX_FEE",
                                    config?.ORDER_TRX_FEE?.value,
                                    config?.ORDER_TRX_FEE?.description
                                  )
                                : null
                            }
                          >
                            <MdEditNote />
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} className="!text-center">
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <ToastContainer />
    </>
  );
};

export default Config;
