import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { MdList, MdEditNote } from "react-icons/md";
import { GoListUnordered } from "react-icons/go";
import { FaDollarSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SFUpdatePOP from "../../components/StoreFrontUpdatePOP";
import { Helmet } from "react-helmet";
import { filterRow } from "../../constants/ConstantData";
import { checkSortOrderUpdate } from "../../constants/SortCheckUp";
import { rulePermission } from "../../constants/Rules";
import FeeUpdate from "../../components/FeeUpdate";

const List = ({ vendorID }) => {
  const hitLink = "get-vendor-storefronts";
  const [storeList, setStoreList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sfUpdate, setSfUpdate] = useState(false);
  const [updateID, setUpdateID] = useState("");
  const [reload, setReload] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [loader, setLoader] = useState(true);
  const [updateFee, setUpdateFee] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastSortType, setLastSortType] = useState("");
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "sf_phone", value: "asc", active: false },
    { key: "contact_person_name", value: "asc", active: false },
    { key: "contact_person_phone", value: "asc", active: false },
    { key: "sf_address", value: "asc", active: false },
    { key: "city", value: "asc", active: false },
  ]);
  const { access_token, user, vendor } = useSelector((state) => state.user);

  // const editInfoData = async (id) => {};

  // const removeInfoData = async (id) => {};

  useEffect(() => {
    getStoreFrontList(
      user.role_id === 11 ? vendor.id : vendorID,
      page,
      rowsPerPage,
      searchStr,
      lastSortType
    );
  }, [vendorID, reload]);

  const getStoreFrontList = async (
    id,
    cPage,
    cRowsPerPage,
    search,
    order_by
  ) => {
    if (loader) {
      dispatch(updateLoading(true));
    }
    await axiosInstance
      .get(hitLink, {
        params: {
          vendor_id: id,
          page: cPage + 1,
          limit: cRowsPerPage,
          order_by,
          search,
        },
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStoreList(res.data.result);
          let sort = order_by.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const editInfoData = async (sfID) => {
    setSfUpdate(true);
    setUpdateID(sfID);
  };

  const updateStoreFee = (sfID) => {
    setUpdateFee(true);
    setUpdateID(sfID);
  };

  const changeViewField = async () => {
    sfUpdate ? setSfUpdate(false) : setSfUpdate(true);
    reload ? setReload(false) : setReload(true);
    setLoader(false);
    setUpdateID("");
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
    getStoreFrontList(
      user.role_id === 11 ? vendor.id : vendorID,
      newPage,
      rowsPerPage,
      searchStr,
      lastSortType
    );
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
    getStoreFrontList(
      user.role_id === 11 ? vendor.id : vendorID,
      page,
      e.target.value,
      searchStr,
      lastSortType
    );
  };

  const searchStoreFrontData = async (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getStoreFrontList(
      user.role_id === 11 ? vendor.id : vendorID,
      0,
      rowsPerPage,
      e.target.value,
      lastSortType
    );
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    getStoreFrontList(
      user.role_id === 11 ? vendor.id : vendorID,
      page,
      rowsPerPage,
      searchStr,
      sort.key + "," + sort.value
    );
  };

  const closeFeePanel = () => {
    setUpdateFee(false);
    setUpdateID("");
  };

  return (
    <>
      <Helmet>
        <title>Store Front List - Smooth Pay</title>
      </Helmet>
      {sfUpdate && (
        <SFUpdatePOP
          sfID={updateID}
          vendorID={
            user.role_id === 11 || user.role_id === 14 ? vendor.id : vendorID
          }
          viewChange={changeViewField}
        />
      )}

      {updateFee && <FeeUpdate sf_id={updateID} closeView={closeFeePanel} />}

      <div className="adminBox">
        <div className="loginHeader">
          <h1>Store Front List</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="searchBox">
              <input
                placeholder="Search here"
                value={searchStr}
                onChange={searchStoreFrontData}
                className="searchField"
              />
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        Store Front Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        Store Front Phone
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Contact Person Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Contact Person Phone
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[5].active}
                        direction={sortArr[5].value}
                        onClick={() => orderListSorting(sortArr[5])}
                      >
                        Address
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[6].active}
                        direction={sortArr[6].value}
                        onClick={() => orderListSorting(sortArr[6])}
                      >
                        City
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeList.total > 0 ? (
                    storeList.rows.map((tableData, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>{tableData.sf_name}</TableCell>
                          <TableCell>{tableData.sf_phone}</TableCell>
                          <TableCell>{tableData.contact_person_name}</TableCell>
                          <TableCell>
                            {tableData.contact_person_phone}
                          </TableCell>
                          <TableCell>{tableData.sf_address}</TableCell>
                          <TableCell>{tableData.city}</TableCell>
                          <TableCell>
                            <p className="actionField">
                              <span
                                className="greenSignal"
                                onClick={() =>
                                  navigate(
                                    "/admin/store-front/" +
                                      tableData.id +
                                      "/" +
                                      tableData.sf_name
                                  )
                                }
                                title="Storefront Manage"
                              >
                                <MdList size={14} />
                              </span>
                              {rulePermission(25, user) && (
                                <span
                                  onClick={() => editInfoData(tableData.id)}
                                  title="Update Storefront"
                                  className="greenSignal"
                                >
                                  <MdEditNote size={14} />
                                </span>
                              )}
                              {rulePermission(49, user) && (
                                <span
                                  onClick={() => updateStoreFee(tableData.id)}
                                  title="Service Fee"
                                  className="greenSignal"
                                >
                                  <FaDollarSign size={14} />
                                </span>
                              )}
                              <span
                                onClick={() =>
                                  navigate(
                                    "/admin/store-front/" +
                                      tableData.id +
                                      "/order/"
                                  )
                                }
                                title="View Orders"
                                className="greenSignal"
                              >
                                <GoListUnordered size={14} />
                              </span>
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} className="!text-center">
                        No Store Front Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {storeList.total > 0 ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={storeList.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default List;
