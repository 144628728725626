import React, { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validity from "../../../../error/UserError";
import axiosInstance from "../../../../constants/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { logOut, updateLoading } from "../../../../store/actions/User";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  confirmPasswordField,
  emailField,
  firstNameField,
  lastNameFiled,
  passwordField,
} from "../../../../error/FieldErrorCheck";

const Create = () => {
  const hitUserCreateLink = "save-vendor-users";
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { access_token, vendor } = useSelector((state) => state.user);

  const userCreate = async () => {
    const userData = {
      vendor_id: vendor.id,
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      role: 14,
    };
    const valid = validity(userData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitUserCreateLink, JSON.stringify(userData), {
          token: access_token,
        })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Vendor Admin Created Successfully", {
              autoClose: 2000,
            });
            emptyField();
            navigate("/admin/setting/vendor/user/list");
          } else {
            toast.error(res.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.result_code === 11) {
              dispatch(logOut());
            }
            toast.error(error.response.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setFirst_name("");
    setLast_name("");
    setEmail("");
    setPassword("");
    setConfirm_password("");
  };

  return (
    <>
      <Helmet>
        <title>Create Vendor Admin - Smooth Pay</title>
      </Helmet>
      <div className="manageActionField p-2 place-content-end">
        <Link to={"/admin/setting/vendor/user/list"}>
          <CustomButton title={"User List"} width={"!bg-teal-700"} />
        </Link>
      </div>
      <div className="adminBox createBox">
        <div className="loginHeader">
          <h1>Create Vendor User</h1>
        </div>
        <div className="inputBody">
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={first_name}
              onChange={(e) => {
                setFirst_name(e.target.value);
                let err = firstNameField({ first_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="First Name"
              placeholder="Enter First Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.first_name ? true : false}
              errorMsg={error.first_name}
              required={true}
            />
            <CustomInput
              value={last_name}
              onChange={(e) => {
                setLast_name(e.target.value);
                let err = lastNameFiled({ last_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Last Name"
              placeholder="Enter Last Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.last_name ? true : false}
              errorMsg={error.last_name}
              required={true}
            />
          </div>
          <CustomInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              let err = emailField({ email: e.target.value });
              err ? setError(err) : setError({});
            }}
            label="E-mail"
            placeholder="Enter E-mail"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.email ? true : false}
            errorMsg={error.email}
            required={true}
          />
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                let err = passwordField({ password: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Password"
              placeholder="Enter Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={
                error.password ? (error.password.length ? true : false) : false
              }
              errorMsg={error.password ? error.password : ""}
              required={true}
            />
            <CustomInput
              value={confirm_password}
              onChange={(e) => {
                setConfirm_password(e.target.value);
                let err = confirmPasswordField({
                  confirm_password: e.target.value,
                  password,
                });
                err ? setError(err) : setError({});
              }}
              label="Confirm Password"
              placeholder="Enter Confirm Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.confirm_password ? true : false}
              errorMsg={error.confirm_password}
              required={true}
            />
          </div>
          <CustomButton title={"Create User"} onClick={userCreate} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Create;
