import React from "react";
import { MdClose } from "react-icons/md";

const OrderDetails = ({ order, closePreview, top }) => {
  console.log(order);
  return (
    <div
      className={
        "absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-90 z-50 grid place-items-center"
      }
    >
      <div className="max-w-md w-full p-6 bg-white rounded relative">
        <div
          className="closeTag"
          onClick={() => {
            closePreview();
          }}
        >
          <MdClose />
        </div>
        <div>
          <div className="loginHeader mb-4">
            <h1 className="text-center text-3xl">Order Details</h1>
          </div>

          <h2 className="text-center text-xl">{order.vendor_name}</h2>
          <h3 className="text-center text-base mb-6">{order.sf_name}</h3>
        </div>
        <div className="flex flex-row gap-6 justify-between">
          <div className="flex flex-col gap-1">
            <label className="text-base">Quantity</label>
            <label>Product Price</label>
            <label>Service Fee</label>
            <label>Customer Fee</label>
            <label>Tip Amount</label>
          </div>
          <div className="flex flex-col font-light gap-1">
            <label>{order.product_qnty}</label>
            <label>${order.total_price}</label>
            <label>${order.service_fee}</label>
            <label>${order.customer_fee}</label>
            <label>${order.tip_amount}</label>
          </div>
        </div>
        <div className="flex flex-row justify-between border-t border-t-slate-300 mt-2 pt-2">
          <div className="flex flex-col gap-1">
            <label>Total Price</label>
          </div>
          <div className="flex flex-col font-light gap-1">
            <label>${order.total_received}</label>
          </div>
        </div>
        <div className="mt-9 flex flex-col gap-2 justify-between text-center mb-6">
          <div className="flex flex-row gap-2">
            <p className="text-sm w-20 text-left">Payment</p>
            <p className="font-light text-sm">{order.payment_id}</p>
          </div>
          <div className="flex flex-row gap-2">
            <p className="text-sm !w-24">Transaction</p>
            <p className="break-all font-light text-sm">
              {order.blockchain_tx1}
            </p>
          </div>
        </div>
        <div className="max-w-[120px] w-full">
          <div className="grid place-content-center">
            {order.sign_url && (
              <img
                src={order.sign_url}
                alt="Digital Signature"
                className="max-w-[90px] w-full"
              />
            )}
          </div>
          <p className="border-t border-t-slate-300 text-center font-sans font-light text-sm">
            Signature
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
