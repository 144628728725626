import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../constants/axiosInstance";
import { logOut, updateLoading } from "../store/actions/User";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validity from "../error/StoreFrontError";
import {
  phonenumberFormat,
  phoneNumberValidator,
} from "../constants/ConstantData";
import {
  storeFrontNameField,
  storeFrontPhoneField,
  contactPersonNameField,
  contactPersonPhoneField,
  sfAddressField,
  sfLocationField,
  stateField,
  cityField,
  countryField,
  companyAddressField,
  companyNameField,
  bankAccountField,
  bankAddressField,
  bankRouteField,
  bankNameField,
  phoneNumberField,
} from "../error/FieldErrorCheck";
import Select from "react-select";

const SFUpdatePOP = ({ sfID, vendorID, viewChange }) => {
  const hitStoreFrontInfoLink = "get-store-fronts/" + sfID;
  const hitStoreFrontUpdateLink = "update-store-fronts/" + sfID;
  const hitCountryLink = "get-countries";
  const hitStateLink = "get-states";
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [sf_name, setSf_name] = useState("");
  const [sf_phone, setSf_phone] = useState("");
  const [sf_address, setSf_address] = useState("");
  const [sf_location, setSf_location] = useState("");
  const [contact_person_name, setContact_person_name] = useState("");
  const [contact_person_phone, setContact_person_phone] = useState("");
  const [city, setCity] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_address, setCompany_address] = useState("");
  const [phone, setPhone] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [bank_address, setBank_address] = useState("");
  const [bank_account, setBank_account] = useState("");
  const [bank_route, setBank_route] = useState("");
  const [storeFront, setStoreFront] = useState({});
  const [state, setState] = useState({ label: "Select State", value: "" });
  const [country, setCountry] = useState({
    label: "Select Country",
    value: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const { access_token } = useSelector((state) => state.user);
  const [payValue, setPayValue] = useState({});
  const paymentType = [
    { label: "ACH", value: 1 },
    { label: "Wire", value: 2 },
    { label: "Check", value: 3 },
  ];

  useEffect(() => {
    getStoreFrontInfo(sfID);
    getCountryList();
  }, [sfID]);

  const getCountryList = async () => {
    await axiosInstance
      .get(hitCountryLink)
      .then((res) => {
        if (res.data.result_code === 0) {
          setCountries(res.data.result);
        }
      })
      .catch((error) => {});
  };

  const changeCountryState = async (option) => {
    let country_id = option.value;
    await axiosInstance
      .get(hitStateLink, { params: { country_id } })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStates(res.data.result);
        }
      })
      .catch((error) => {});
  };

  let countryopts = [];
  let stateOpts = [];
  countries.map((country, index) => {
    return countryopts.push({ label: country.name, value: country.id });
  });

  states.map((state, index) => {
    return stateOpts.push({ label: state.name, value: state.id });
  });

  const getStoreFrontInfo = async (vendorID) => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitStoreFrontInfoLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          let storeData = res.data.result[0];
          setStoreFront(storeData);
          setSf_name(storeData.sf_name);
          setSf_phone(storeData.sf_phone);
          setSf_address(storeData.sf_address);
          setSf_location(storeData.sf_location);
          setContact_person_name(storeData.contact_person_name);
          setContact_person_phone(storeData.contact_person_phone);
          setCity(storeData.city);
          setState(storeData.state);
          setCountry(storeData.country);
          setPayment_type(storeData.payment_type);
          setCompany_name(storeData.company_name);
          setCompany_address(storeData.company_address);
          setPhone(storeData.phone);
          setBank_name(storeData.bank_name);
          setBank_address(storeData.bank_address);
          setBank_account(storeData.bank_account);
          setBank_route(storeData.bank_route);
          setCountry({ label: storeData.country, value: storeData.country_id });
          setState({ label: storeData.state, value: storeData.state_id });
          changeCountryState(storeData.country_id);
          if (storeData.payment_type === 1) {
            setPayValue({ label: "ACH", value: 1 });
          } else if (storeData.payment_type === 2) {
            setPayValue({ label: "Wire", value: 2 });
          } else if (storeData.payment_type === 3) {
            setPayValue({ label: "Check", value: 3 });
          }
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const updateStoreFront = async () => {
    const storeFrontData = {
      vendor_id: vendorID,
      status: 1,
      sf_name,
      sf_phone,
      sf_address,
      sf_location,
      contact_person_name,
      contact_person_phone,
      state,
      city,
      state_id: state.value,
      country_id: country.value,
      payment_type,
      company_address,
      company_name,
      phone,
      bank_name,
      bank_address,
      bank_account,
      bank_route,
    };
    let valid = validity(storeFrontData);
    if (valid.isValid) {
      if (
        sf_name !== storeFront.sf_name ||
        sf_phone !== storeFront.sf_phone ||
        sf_address !== storeFront.sf_address ||
        sf_location !== storeFront.sf_location ||
        contact_person_name !== storeFront.contact_person_name ||
        contact_person_phone !== storeFront.contact_person_phone ||
        city !== storeFront.city ||
        state !== storeFront.state ||
        country !== storeFront.country ||
        company_name !== storeFront.company_name ||
        company_address !== storeFront.company_address ||
        phone !== storeFront.phone ||
        bank_name !== storeFront.bank_name ||
        bank_address !== storeFront.bank_address ||
        bank_account !== storeFront.bank_account ||
        bank_route !== storeFront.bank_route
      ) {
        dispatch(updateLoading(true));
        await axiosInstance
          .post(hitStoreFrontUpdateLink, storeFrontData, {
            token: access_token,
          })
          .then((res) => {
            if (res.data.result_code === 0) {
              toast.success("Store Front Updated Successfully", {
                autoClose: 2000,
              });
              viewChange();
            } else {
              toast.error("Failed to update Store Front", { autoClose: 2000 });
            }
            dispatch(updateLoading(false));
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data.result_code === 11) {
                dispatch(logOut());
              }
            }
            dispatch(updateLoading(false));
          });
      } else {
        toast.warn("No change has made!");
      }
    } else {
      setError(valid.error);
    }
  };

  return (
    <div className="popupPanel">
      <div className="updateField max-w-lg">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={viewChange}
        />
        <div className="loginHeader">
          <h1>Update Store Front</h1>
        </div>
        <div className="inputBody max-h-[480px] overflow-y-auto">
          <div className="rowBox">
            <CustomInput
              value={sf_name}
              onChange={(e) => {
                setSf_name(e.target.value);
                let err = storeFrontNameField({ sf_name: e.target.value });
                setError(err);
              }}
              label="Store Front Name"
              placeholder="Enter Store Front Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.sf_name ? true : false}
              errorMsg={error.sf_name}
              required={true}
            />
            <CustomInput
              value={sf_phone}
              onChange={(e) => {
                if (phonenumberFormat(e.target.value) !== undefined) {
                  setSf_phone(phonenumberFormat(e.target.value));
                }
                let err = storeFrontPhoneField({ sf_phone: e.target.value });
                setError(err);
              }}
              label="Store Front Phone"
              placeholder="Enter Store Front Phone"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.sf_phone ? true : false}
              errorMsg={error.sf_phone}
              required={true}
            />
          </div>
          <div className="rowBox">
            <CustomInput
              value={contact_person_name}
              onChange={(e) => {
                setContact_person_name(e.target.value);
                let err = contactPersonNameField({
                  contact_person_name: e.target.value,
                });
                setError(err);
              }}
              label="Contact Person Name"
              placeholder="Enter Contact Person Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.contact_person_name ? true : false}
              errorMsg={error.contact_person_name}
              required={true}
            />
            <CustomInput
              value={contact_person_phone}
              onChange={(e) => {
                if (phonenumberFormat(e.target.value) !== undefined) {
                  setContact_person_phone(phonenumberFormat(e.target.value));
                }
                let err = contactPersonPhoneField({
                  contact_person_phone: e.target.value,
                });
                setError(err);
              }}
              label="Contact Person Phone"
              placeholder="Enter Contact Person Phone"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.contact_person_phone ? true : false}
              errorMsg={error.contact_person_phone}
              required={true}
            />
          </div>
          <CustomInput
            value={sf_address}
            onChange={(e) => {
              setSf_address(e.target.value);
              let err = sfAddressField({
                sf_address: e.target.value,
              });
              setError(err);
            }}
            label="Address"
            placeholder="Enter Store Front Address"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.sf_address ? true : false}
            errorMsg={error.sf_address}
            required={true}
          />
          <div className="rowBox">
            <CustomInput
              value={sf_location}
              onChange={(e) => {
                setSf_location(e.target.value);
                let err = sfLocationField({
                  sf_location: e.target.value,
                });
                setError(err);
              }}
              label="Location"
              placeholder="Enter Store Front Location"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.sf_location ? true : false}
              errorMsg={error.sf_location}
              required={true}
            />
            <CustomInput
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                let err = cityField({
                  city: e.target.value,
                });
                setError(err);
              }}
              label="City"
              placeholder="Enter City"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.city ? true : false}
              errorMsg={error.city}
              required={true}
            />
          </div>

          <div className="rowBox">
            <Select
              value={country}
              defaultValue={country}
              options={countryopts}
              onChange={(option) => {
                setCountry(option);
                changeCountryState(option);
              }}
              className={error.country ? "error" : ""}
            />

            {/* ----Store Front State---- */}
            <Select
              value={state}
              defaultValue={state}
              options={stateOpts}
              onChange={(option) => setState(option)}
              className={error.state ? "error" : ""}
            />
          </div>

          <Select
            value={payValue}
            defaultValue={payValue}
            options={paymentType}
            onChange={(option) => {
              setPayValue(option);
              setPayment_type(option.value);
            }}
          />

          {payment_type === 1 || payment_type === 2 ? (
            <>
              <CustomInput
                label={"Company Name"}
                value={company_name}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Name"}
                onChange={(e) => {
                  setCompany_name(e.target.value);
                  let err = companyNameField({ company_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_name ? true : false}
                errorMsg={error.company_name}
              />
              <CustomInput
                label={"Company Address"}
                value={company_address}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Address"}
                onChange={(e) => {
                  setCompany_address(e.target.value);
                  let err = companyAddressField({
                    company_address: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_address ? true : false}
                errorMsg={error.company_address}
              />
              <CustomInput
                label={"Company Phone"}
                value={phone}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Phone Number"}
                onChange={(e) => {
                  if (phonenumberFormat(e.target.value) !== undefined) {
                    setPhone(phonenumberFormat(e.target.value));
                  }
                  let err = phoneNumberField({
                    phone: phoneNumberValidator.test(
                      e.target.value
                        .replace("(", "")
                        .replace(")", "")
                        .replace("-", "")
                        .replaceAll(" ", "")
                    )
                      ? e.target.value
                      : e.target.value !== "+(" && e.target.value.length > 1
                      ? "E"
                      : "",
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.phone ? true : false}
                errorMsg={error.phone}
              />
              <div className="rowBox">
                <CustomInput
                  label={"Bank Name"}
                  value={bank_name}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Name"}
                  onChange={(e) => {
                    setBank_name(e.target.value);
                    let err = bankNameField({
                      bank_name: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_name ? true : false}
                  errorMsg={error.bank_name}
                />
                <CustomInput
                  label={
                    payment_type === 1
                      ? "Bank Routing for ACH"
                      : "Bank Routing for Wire"
                  }
                  value={bank_route}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Route Number"}
                  onChange={(e) => {
                    setBank_route(e.target.value);
                    let err = bankRouteField({
                      bank_route: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_route ? true : false}
                  errorMsg={error.bank_route}
                />
              </div>
              <div className="rowBox">
                <CustomInput
                  label={"Bank Address"}
                  value={bank_address}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Address"}
                  onChange={(e) => {
                    setBank_address(e.target.value);
                    let err = bankAddressField({
                      bank_address: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_address ? true : false}
                  errorMsg={error.bank_address}
                />
                <CustomInput
                  label={"Bank Account"}
                  value={bank_account}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Account"}
                  onChange={(e) => {
                    setBank_account(e.target.value);
                    let err = bankAccountField({
                      bank_account: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_account ? true : false}
                  errorMsg={error.bank_account}
                />
              </div>
            </>
          ) : (
            <>
              <CustomInput
                label={"Company Name"}
                value={company_name}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Name"}
                onChange={(e) => {
                  setCompany_name(e.target.value);
                  let err = companyNameField({ company_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_name ? true : false}
                errorMsg={error.company_name}
              />
              <CustomInput
                label={"Company Address"}
                value={company_address}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Address"}
                onChange={(e) => {
                  setCompany_address(e.target.value);
                  let err = companyAddressField({
                    company_address: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_address ? true : false}
                errorMsg={error.company_address}
              />
            </>
          )}

          <CustomButton
            title={"Update Store Front"}
            onClick={updateStoreFront}
          />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default SFUpdatePOP;
