import React from "react";

const CustomButton = ({ title, onClick, width, align }) => {
  return (
    <>
      <div className={"buttonField " + align}>
        <button onClick={onClick} className={"button " + width}>
          {title}
        </button>
      </div>
    </>
  );
};

export default CustomButton;
