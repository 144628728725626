import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../store/actions/User";
import { persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();
const configPersitor = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user:userReducer,
});
const persistedReducer = persistReducer(configPersitor, rootReducer);

const smoothPayWebStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


export default smoothPayWebStore;
