const getPasswordError = (userData) => {
  const required = " is required";
  let regex = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
  let passwordErr = [];
  if (!userData.password || userData.password === "") {
    passwordErr.push("Password" + required);
  }
  if (passwordErr.length === 0) {
    if (!regex.test(userData.password)) {
      passwordErr.push(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  }

  return passwordErr.length > 0 ? passwordErr.join("\n") : "";
};
export default getPasswordError;
