import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../constants/axiosInstance";
import { configDetailsField, configValueField } from "../error/FieldErrorCheck";
import { logOut, updateLoading } from "../store/actions/User";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";

const ConfigUpdatePOP = ({ type, data, details, closeView }) => {
  const hitUpdateLink = "update-sys-configs";
  const [key] = useState(type);
  const [value, setValue] = useState(data);
  const [description, setDescription] = useState(details);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  const initialData = {
    key,
    value,
    description,
  };

  const updateConfig = async () => {
    let valid = errorCheck(initialData);
    if (valid.isValid) {
      if (value !== data || details !== description) {
        dispatch(updateLoading(true));
        await axiosInstance
          .post(hitUpdateLink, initialData, {
            token: access_token,
          })
          .then((res) => {
            if (res.data.result_code === 0) {
              toast.success("Config data updated successfully");
              closeView();
            } else {
              toast.error("Failed to update information");
            }
            dispatch(updateLoading(false));
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.result_code === 11) {
                dispatch(logOut());
              }
            }
            dispatch(updateLoading(false));
          });
      } else {
        toast.warn("No change has made");
      }
    } else {
      setError(valid.error);
    }
  };

  const errorCheck = (data) => {
    let error = {};
    if (!data.value || data.value === "") {
      error.value = "Value is required";
    }

    if (!data.description || data.description === "") {
      error.description = "Description is required";
    }
    return {
      error,
      isValid: Object.keys(error).length === 0,
    };
  };

  return (
    <div className="popupPanel">
      <div className="updateField max-w-md">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={closeView}
        />
        <div className="loginHeader">
          <h1>Update Config</h1>
        </div>
        <div className="inputBody max-h-[480px] overflow-y-auto">
          <CustomInput
            label={"Fee Value"}
            type={"text"}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              let err = configValueField({ value: e.target.value });
              setError(err);
            }}
            placeholder="Enter Value"
            error={error.value ? true : false}
            errorMsg={error.value}
          />
          <CustomInput
            label={"Description"}
            type={"text"}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              let err = configDetailsField({ description: e.target.value });
              setError(err);
            }}
            placeholder="Enter Description"
            error={error.description ? true : false}
            errorMsg={error.description}
          />
          <CustomButton title={"Update Config"} onClick={updateConfig} />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default ConfigUpdatePOP;
