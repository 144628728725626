export const checkSortOrderUpdate = (sort, key, value) => {
  let newSort;
  let sortValue = value === "asc" ? "desc" : "asc";
  newSort = sort.map((data, index) => {
    let newData;
    if (data.key === key) {
      newData = {
        ...data,
        value: sortValue,
        active: data.active ? false : true,
      };
    } else {
      newData = { ...data, value: "asc", active: false };
    }
    return newData;
  });
  return newSort;
};
