import { data } from "autoprefixer";

const validity = (storeFrontData) => {
  let error = {};
  let require = " is requried";

  if (!storeFrontData.sf_name || storeFrontData.sf_name === "") {
    error.sf_name = "Store Front name" + require;
  }
  if ((!storeFrontData.sf_phonem, storeFrontData.sf_phone === "")) {
    error.sf_phone = "Store Front phone" + require;
  }
  if (!storeFrontData.sf_address || storeFrontData.sf_address === "") {
    error.sf_address = "Store Front address" + require;
  }
  if (!storeFrontData.sf_location || storeFrontData.sf_location === "") {
    error.sf_location = "Store Front location" + require;
  }
  if (!storeFrontData.city || storeFrontData.city === "") {
    error.city = "City" + require;
  }
  if (!storeFrontData.state_id || storeFrontData.state_id === "") {
    error.state = "State" + require;
  }
  if ((!storeFrontData.country_id, storeFrontData.country_id === "")) {
    error.country = "Country" + require;
  }
  if (!storeFrontData.payment_type || storeFrontData.payment_type === "") {
    error.payment_type = "Payment type" + require;
  }
  if (
    !storeFrontData.contact_person_name ||
    storeFrontData.contact_person_name === ""
  ) {
    error.contact_person_name = "Contact Person Name" + require;
  }
  if (
    !storeFrontData.contact_person_phone ||
    storeFrontData.contact_person_phone === ""
  ) {
    error.contact_person_phone = "Contact Person Phone" + require;
  }
  if (!storeFrontData.company_name || storeFrontData.company_name === "") {
    error.company_name = "Company Name" + require;
  }
  if (
    !storeFrontData.company_address ||
    storeFrontData.company_address === ""
  ) {
    error.company_address = "Company Address" + require;
  }
  if (storeFrontData.payment_type === 1 || storeFrontData.payment_type === 2) {
    if (!storeFrontData.phone || storeFrontData.phone === "") {
      error.phone = "Phone Number" + require;
    }
    if (!storeFrontData.bank_name || storeFrontData.bank_name === "") {
      error.bank_name = "Bank Name" + require;
    }
    if (!storeFrontData.bank_address || storeFrontData.bank_address === "") {
      error.bank_address = "Bank Address" + require;
    }
    if (!storeFrontData.bank_account || storeFrontData.bank_account === "") {
      error.bank_account = "Bank Account" + require;
    }
    if (!storeFrontData.bank_route || storeFrontData.bank_route === "") {
      error.bank_route = "Bank Route" + require;
    }
  }

  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
