import React, { useEffect, useState } from "react";
import { MdNotifications, MdManageAccounts, MdLogout } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMenu, IoKey } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import axiosInstance from "../constants/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { logOut, updateLoading } from "../store/actions/User";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Header({ onClick }) {
  const hitLink = "auth/logout";
  const [accMenu, setAccMenu] = useState("");
  const [collapse, setCollapse] = useState(false);

  const { user, access_token, isLoading, isLoggedIn } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      if (typeof window !== undefined) {
        window.addEventListener("resize", onScreenChange);

        window.addEventListener("click", function (e) {
          if (document.querySelector(".header-right-menu>ul>li:last-child")) {
            if (
              !document
                .querySelector(".header-right-menu>ul>li:last-child")
                .contains(e.target)
            ) {
              setAccMenu("");
            }
          }
        });
      }
    }
  }, []);

  const onScreenChange = () => {
    if (document.body.clientWidth < 867) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  }, [isLoading]);

  function disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  function enableScrolling() {
    window.onscroll = function () {};
  }

  const userLogOut = async () => {
    dispatch(updateLoading(true));
    await axiosInstance
      .post(hitLink, {}, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          toast.success(res.data.result.message);
          dispatch(logOut());
        }
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  return (
    <>
      <div className="header">
        <div className={collapse ? "w-[98px]" : "w-[310px]"}>
          {!collapse ? (
            <img
              src="/icons/logo.png"
              className={
                collapse
                  ? "w-7 transition-all duration-500"
                  : "w-36 transition-all duration-500"
              }
            />
          ) : (
            <img src="/favicon.ico" className="w-7" />
          )}
          <IoMenu
            onClick={() => {
              onClick();
              collapse ? setCollapse(false) : setCollapse(true);
            }}
          />
        </div>
        <div className="header-right-menu">
          <ul>
            <li>
              <MdNotifications />
              <ul>
                <li></li>
              </ul>
            </li>
            <li
              onClick={(e) =>
                accMenu === "" ? setAccMenu("ActiveMenu") : setAccMenu("")
              }
            >
              <MdManageAccounts />
              <ul className={"account-sub-menu " + accMenu}>
                <li className="!py-2">
                  <Link to={"/admin/profile"}>
                    <FaUserCircle size={37} />
                    <p>
                      {user.first_name + " " + user.last_name}
                      <br />
                      {user.email}
                    </p>
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/"}>
                    <MdDashboard /> Dashboard
                  </Link>
                </li> */}
                <li>
                  <Link to={"/admin/change-password"}>
                    <IoKey /> Change Password
                  </Link>
                </li>
                <li>
                  <a onClick={(e) => userLogOut()} href="#Logout">
                    <MdLogout /> Log Out
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
