import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLoaderData } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import axiosInstance from "../../constants/axiosInstance";
import { logOut } from "../../store/actions/User";
import Create from "../StoreFront/Create";
import List from "../StoreFront/List";
import { MdArrowForward } from "react-icons/md";

const Manage = () => {
  const hitLink = "get-vendor-info";
  const { name, id } = useLoaderData();
  const [view, setView] = useState("L");
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getVendorInfo();
  }, []);

  const getVendorInfo = async () => {
    await axiosInstance
      .get(hitLink, { token: access_token })
      .then((res) => {})
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
      });
  };

  const updateAction = () => {
    view === "L" ? setView("C") : setView("L");
  };

  return (
    <>
      <div className="adminBox">
        <div className="manageActionField">
          <div className="bredcumList">
            <Link to={"/admin/vendor/list"}>Vendor List</Link>
            <MdArrowForward />
            <span>
              {view === "L" ? "Store Front List" : "Create Store Front"}
            </span>
          </div>
          {view === "L" ? (
            <>
              <div>
                <h1 className="!font-bold">
                  Vendor: <span className="font-light">{name}</span>
                </h1>
              </div>
              <div>
                <CustomButton
                  title={"Create Store Front"}
                  onClick={updateAction}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <h1 className="!font-bold">
                  Vendor: <span className="font-light">{name}</span>
                </h1>
              </div>
              <div>
                <CustomButton
                  title={"Store Front List"}
                  onClick={updateAction}
                />
              </div>
            </>
          )}
        </div>
        {view === "L" ? (
          <List vendorID={id} />
        ) : (
          <Create vendorID={id} moveToList={updateAction} />
        )}
      </div>
    </>
  );
};

export default Manage;
