import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { MdEditNote } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import { FiKey } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { rulePermission } from "../../constants/Rules";
import VendorUpdatePOP from "../../components/VendorUpdatePOP";
import { Helmet } from "react-helmet";
import { filterRow } from "../../constants/ConstantData";
import { checkSortOrderUpdate } from "../../constants/SortCheckUp";
import ChangePassword from "../../components/ChangePassword";

const List = ({ param }) => {
  const hitLink = "get-vendors";
  const approveLink = "approve-vendors/";
  const [storeList, setStoreList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vendorUpdate, setVendorUpdate] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [updateID, setUpdateID] = useState("");
  const [reload, setReload] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [loader, setLoader] = useState(true);
  const [lastSortType, setLastSortType] = useState("");
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "first_name", value: "asc", active: false },
    { key: "email", value: "asc", active: false },
    { key: "vendor_name", value: "asc", active: false },
    { key: "vendor_phone", value: "asc", active: false },
    { key: "vendor_address", value: "asc", active: false },
    { key: "payable_amount", value: "asc", active: false },
    { key: "is_approved", value: "asc", active: false },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { access_token, user } = useSelector((state) => state.user);

  useEffect(() => {
    getVendorList(page, rowsPerPage, lastSortType, "");
  }, [page, reload, param]);

  const changeVendorStatus = async (e, vendor_id) => {
    let stateText = e.target.innerText;
    let status = 0;
    if (stateText === "Pending") {
      status = 1;
    }
    const approveData = {
      is_approved: status,
    };
    confirmAlert({
      title:
        stateText === "Pending" ? "Vendor Approval" : "Vendor Inactivation",
      message:
        stateText === "Pending"
          ? "Are you sure to approve this vendor?"
          : "Are you sure to inactive this vendor?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));
            await axiosInstance
              .post(approveLink + vendor_id, JSON.stringify(approveData), {
                token: access_token,
              })
              .then((res) => {
                if (res.data.result_code === 0) {
                  toast.success("Approved successfully", { autoClose: 1000 });
                  if (status === 1) {
                    e.target.classList.remove("pending");
                    e.target.classList.add("approved");
                    e.target.innerText = "Approved";
                  } else {
                    e.target.classList.remove("approved");
                    e.target.classList.add("pending");
                    e.target.innerText = "Pending";
                  }
                } else {
                  toast.error(res.data.error.message, { autoClose: 1000 });
                }
                dispatch(updateLoading(false));
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      closeOnClickOutside: false,
    });
  };

  const doNothing = () => {};

  // const vendorManage = async (id) => {};

  // const editInfoData = async (id) => {};

  // const removeInfoData = async (id) => {};

  const getVendorList = async (cPage, cRows, order_by, search) => {
    if (loader) {
      dispatch(updateLoading(true));
    }

    let params = {
      page: cPage + 1,
      limit: cRows,
      order_by,
      search,
    };
    if (param === "Pending") {
      params = { ...params, is_approved: 0 };
    }

    await axiosInstance
      .get(hitLink, {
        params,
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStoreList(res.data.result);
          let sort = order_by.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const editInfoData = async (vendorID) => {
    setVendorUpdate(true);
    setUpdateID(vendorID);
  };

  const changeViewField = async () => {
    vendorUpdate ? setVendorUpdate(false) : setVendorUpdate(true);
    reload ? setReload(false) : setReload(true);
    setUpdateID("");
    setLoader(false);
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
    getVendorList(newPage, rowsPerPage, lastSortType, searchStr);
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
    getVendorList(0, e.target.value, lastSortType, searchStr);
  };

  const searchVendorData = async (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getVendorList(0, rowsPerPage, lastSortType, e.target.value);
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    getVendorList(page, rowsPerPage, sort.key + "," + sort.value, searchStr);
  };

  const passwordChange = (id) => {
    setUpdateID(id);
    setChangePassword(true);
  };

  const changeFieldView = () => {
    setUpdateID("");
    setChangePassword(false);
  };

  return (
    <>
      <Helmet>
        <title>Vendor List - Smooth Pay</title>
      </Helmet>
      {vendorUpdate && (
        <VendorUpdatePOP vendorID={updateID} viewChange={changeViewField} />
      )}

      {changePassword && (
        <ChangePassword userID={updateID} viewChange={changeFieldView} />
      )}

      <div className="adminBox" id="vendor">
        <div className="loginHeader">
          <h1>{param + " Vendor List"}</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="searchBox">
              <input
                placeholder="Search here"
                value={searchStr}
                onChange={searchVendorData}
                className="searchField"
              />
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        E-mail
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Vendor Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Vendor Phone
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Storefronts</TableCell>
                    <TableCell>Vendor Users</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[5].active}
                        direction={sortArr[5].value}
                        onClick={() => orderListSorting(sortArr[5])}
                      >
                        Address
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[7].active}
                        direction={sortArr[7].value}
                        onClick={() => orderListSorting(sortArr[7])}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeList?.rows && storeList?.rows.length > 0 ? (
                    storeList.rows.map((tableData, index) => {
                      let status;

                      if (tableData.is_approved === 0) {
                        status = (
                          <span
                            className="statusTag pending"
                            onClick={(e) => {
                              rulePermission(23, user)
                                ? changeVendorStatus(e, tableData.id)
                                : doNothing();
                            }}
                          >
                            Pending
                          </span>
                        );
                      } else {
                        status = (
                          <span
                            className="statusTag approved"
                            onClick={(e) => {
                              rulePermission(23, user)
                                ? changeVendorStatus(e, tableData.id)
                                : doNothing();
                            }}
                          >
                            Approved
                          </span>
                        );
                      }
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>
                            {tableData.first_name + " " + tableData.last_name}
                          </TableCell>
                          <TableCell>{tableData.email}</TableCell>
                          <TableCell>{tableData.vendor_name}</TableCell>
                          <TableCell>{tableData.vendor_phone}</TableCell>
                          <TableCell>{tableData.total_storefronts}</TableCell>
                          <TableCell>{tableData.total_vendor_users}</TableCell>
                          <TableCell>{tableData.vendor_address}</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>
                            <div className="actionBody">
                              <span
                                className="greenSignal"
                                onClick={() =>
                                  navigate(
                                    "/admin/vendor/" +
                                      tableData.id +
                                      "/" +
                                      tableData.vendor_name
                                  )
                                }
                                title="Manage"
                              >
                                <GoSettings />
                              </span>
                              <span
                                onClick={(e) => {
                                  editInfoData(tableData.id);
                                }}
                                title="Edit"
                                className="greenSignal"
                              >
                                <MdEditNote />
                              </span>
                              <span
                                onClick={(e) => {
                                  passwordChange(tableData.id);
                                }}
                                title="Change Password"
                                className="greenSignal"
                              >
                                <FiKey />
                              </span>
                              {/* {tableData.payable_amount > 0 ? (
                                <>
                                  <span
                                    onClick={() =>
                                      navigate("/admin/order/payable")
                                    }
                                    title="Payables"
                                    className="greenSignal"
                                  >
                                    <MdList />
                                  </span>
                                  <span
                                    onClick={(e) => {
                                      payableAmountPay(
                                        tableData.id,
                                        tableData.payable_amount
                                      );
                                    }}
                                    title="Payment"
                                    className="greenSignal"
                                  >
                                    <FaDollarSign />
                                  </span>
                                </>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} className="!text-center">
                        No Vendor Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {storeList?.rows && storeList?.rows.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={storeList.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default List;
