import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";
import "../style/adminLayout.css";
import "../style/global.css";
import { useSelector } from "react-redux";
import { Triangle } from "react-loader-spinner";

const AdminLayout = () => {
  const { isLoading, isLoggedIn } = useSelector((state) => state.user);
  const [expCollapse, setExpCollapse] = useState(false);

  const onScreenChange = () => {
    if (document.body.clientWidth < 867) {
      setExpCollapse(true);
    } else {
      setExpCollapse(false);
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", onScreenChange);
    }

    return () => {
      window.removeEventListener("resize", onScreenChange);
    };
  }, []);

  useEffect(() => {
    userAuthCheck();
  }, [isLoggedIn]);

  const userAuthCheck = async () => {
    if (!isLoggedIn) {
      <Navigate to={"/login"} />;
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <Header
            onClick={() =>
              expCollapse ? setExpCollapse(false) : setExpCollapse(true)
            }
          />
          <Sidebar expCollapse={expCollapse} />
          <div className={expCollapse ? "body-wrapper !ml-20" : "body-wrapper"}>
            {isLoading ? (
              <div className="loader">
                <div>
                  <Triangle
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                  <p className="loaderText">Loading....</p>
                </div>
              </div>
            ) : null}
            <Outlet />
          </div>
        </>
      ) : (
        <Navigate to={"/login"} />
      )}
    </>
  );
};

export default AdminLayout;
