import React, { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import validity from "../../error/StoreFrontError";
import Select from "react-select";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosInstance";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logOut, updateLoading } from "../../store/actions/User";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  bankAccountField,
  bankAddressField,
  bankNameField,
  bankRouteField,
  cityField,
  companyAddressField,
  companyNameField,
  confirmBankAccountField,
  contactPersonNameField,
  contactPersonPhoneField,
  phoneNumberField,
  sfAddressField,
  sfLocationField,
  storeFrontNameField,
  storeFrontPhoneField,
} from "../../error/FieldErrorCheck";
import {
  phonenumberFormat,
  phoneNumberValidator,
} from "../../constants/ConstantData";

const Create = ({ vendorID, moveToList }) => {
  const hitLink = "save-store-fronts";
  const hitCountryLink = "get-countries";
  const hitStateLink = "get-states";
  // const getVendorLink = "get-vendors";
  const [vendor_id, setVendor_id] = useState("");
  const [vendor_website, setVendor_website] = useState("");
  const [vendorList] = useState([]);
  const [vendorData, setVendorData] = useState("");
  const [sf_name, setSf_name] = useState("");
  const [sf_phone, setSf_phone] = useState("");
  const [sf_address, setSf_address] = useState("");
  const [sf_location, setSf_location] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState({ label: "Select State", value: "" });
  const [country, setCountry] = useState({
    label: "Select Country",
    value: "",
  });
  const [contact_person_name, setContact_person_name] = useState("");
  const [contact_person_phone, setContact_person_phone] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_address, setCompany_address] = useState("");
  const [phone, setPhone] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [bank_address, setBank_address] = useState("");
  const [bank_account, setBank_account] = useState("");
  const [confirmBank_account, setConfirmBank_account] = useState("");
  const [bank_route, setBank_route] = useState("");
  const [error, setError] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [accType, setAccType] = useState({
    label: "Select Account Type",
    value: "",
  });
  const accOpts = [
    { label: "ACH", value: 1 },
    { label: "Wire", value: 2 },
    { label: "Check", value: 3 },
  ];

  const { user, vendor, access_token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(vendorID);
    console.log(vendor);
    if (vendorID) {
      setVendor_id(vendorID);
    } else {
      if (user.role_id === 11 || user.role_id === 14) {
        setVendor_id(vendor.id);
        setVendor_website(vendor.vendor_website);
      }
    }
    getCountryList();
  }, []);

  const getCountryList = async () => {
    await axiosInstance
      .get(hitCountryLink)
      .then((res) => {
        if (res.data.result_code === 0) {
          setCountries(res.data.result);
        }
      })
      .catch((error) => {});
  };

  const changeCountryState = async (option) => {
    let country_id = option.value;
    await axiosInstance
      .get(hitStateLink, { params: { country_id } })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStates(res.data.result);
        }
      })
      .catch((error) => {});
  };

  let countryopts = [];
  let stateOpts = [];
  countries.map((country, index) => {
    return countryopts.push({ label: country.name, value: country.id });
  });

  states.map((state, index) => {
    return stateOpts.push({ label: state.name, value: state.id });
  });

  const createStoreFront = async () => {
    const storeFrontData = {
      vendor_id,
      sf_name,
      sf_phone,
      sf_address,
      sf_location,
      vendor_website,
      city,
      state_id: state.value,
      country_id: country.value,
      contact_person_name,
      contact_person_phone,
      payment_type: accType.value,
      company_name,
      company_address,
      phone,
      bank_name,
      bank_address,
      bank_account,
      bank_route,
    };

    const valid = validity(storeFrontData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitLink, JSON.stringify(storeFrontData), { token: access_token })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Store Front created successfully", {
              autoClose: 2000,
            });
            emptyField();
            if (user.role_id === 11) {
              navigate("/admin/store-front/list");
            } else {
              moveToList();
            }
          } else {
            toast.error(res.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.result_code === 11) {
              dispatch(logOut());
            }
            toast.error(err.response.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setVendor_id("");
    setSf_name("");
    setSf_phone("");
    setSf_address("");
    setVendor_website("");
    setCity("");
    setState({ label: "Select State", value: "" });
    setCountry({ label: "Select Country", value: "" });
    setContact_person_name("");
    setContact_person_phone("");
  };

  let vendorOpts = [];
  vendorList.map((data, index) => {
    return vendorOpts.push({ label: data.vendor_name, value: data.id });
  });

  return (
    <>
      <Helmet>
        <title>Add Store Front - Smooth Pay</title>
      </Helmet>
      <div className="adminBox createBox">
        <div className="loginHeader">
          <h1>Create Store Front</h1>
        </div>
        <div className="inputBody">
          {/* ----Vendor List---- */}
          {user.role_id !== 11 && user.role_id !== 14 && !vendorID ? (
            <Select
              value={vendorData}
              defaultValue={vendorData}
              options={vendorOpts}
              onChange={(option) => {
                setVendorData(option);
                setVendor_id(option.value);
              }}
              placeholder={"Select Vendor"}
              className="text-sm"
            />
          ) : null}

          <div className="rowBox">
            {/* ----Store Front Name---- */}
            <CustomInput
              label={"Store Front Name"}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              value={sf_name}
              placeholder={"Enter store front name"}
              onChange={(e) => {
                setSf_name(e.target.value);
                let err = storeFrontNameField({ sf_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.sf_name ? true : false}
              errorMsg={error.sf_name}
            />

            {/* ----Store Front Phone---- */}
            <CustomInput
              label={"Store Front Phone"}
              value={sf_phone}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              placeholder={"Enter store front phone"}
              onChange={(e) => {
                if (phonenumberFormat(e.target.value) !== undefined) {
                  setSf_phone(phonenumberFormat(e.target.value));
                }
                let err = storeFrontPhoneField({
                  sf_phone: phoneNumberValidator.test(
                    e.target.value
                      .replace("(", "")
                      .replace(")", "")
                      .replace("-", "")
                      .replaceAll(" ", "")
                  )
                    ? e.target.value
                    : e.target.value !== "+(" && e.target.value.length > 1
                    ? "E"
                    : "",
                });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.sf_phone ? true : false}
              errorMsg={error.sf_phone}
            />
          </div>

          <div className="rowBox">
            {/* ----Store Front Contact Person Name---- */}
            <CustomInput
              label={"Contact Person Name"}
              value={contact_person_name}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              placeholder={"Enter contact person name"}
              onChange={(e) => {
                setContact_person_name(e.target.value);
                let err = contactPersonNameField({
                  contact_person_name: e.target.value,
                });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.contact_person_name ? true : false}
              errorMsg={error.contact_person_name}
            />

            {/* ----Store Front Contact Person Phone---- */}
            <CustomInput
              label={"Contact Person Phone"}
              value={contact_person_phone}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              placeholder={"Enter contact person phone"}
              onChange={(e) => {
                if (phonenumberFormat(e.target.value) !== undefined) {
                  setContact_person_phone(phonenumberFormat(e.target.value));
                }
                let err = contactPersonPhoneField({
                  contact_person_phone: phoneNumberValidator.test(
                    e.target.value
                      .replace("(", "")
                      .replace(")", "")
                      .replace("-", "")
                      .replaceAll(" ", "")
                  )
                    ? e.target.value
                    : e.target.value !== "+(" && e.target.value.length > 1
                    ? "E"
                    : "",
                });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.contact_person_phone ? true : false}
              errorMsg={error.contact_person_phone}
            />
          </div>

          {/* ----Store Front Address---- */}
          <CustomInput
            label={"Store Front Address"}
            value={sf_address}
            labelStyle="!bg-white"
            inputStyle="!bg-white"
            placeholder={"Enter store front address"}
            onChange={(e) => {
              setSf_address(e.target.value);
              let err = sfAddressField({ sf_address: e.target.value });
              err ? setError(err) : setError({});
            }}
            type="text"
            required={true}
            error={error.sf_address ? true : false}
            errorMsg={error.sf_address}
          />

          <div className="rowBox">
            <Select
              value={country}
              defaultValue={country}
              options={countryopts}
              onChange={(option) => {
                setCountry(option);
                changeCountryState(option);
              }}
              className={error.country ? "error" : ""}
            />

            {/* ----Store Front State---- */}
            <Select
              value={state}
              defaultValue={state}
              options={stateOpts}
              onChange={(option) => setState(option)}
              className={error.state ? "error" : ""}
            />
          </div>

          <div className="rowBox">
            {/* ----Store Front City---- */}
            <CustomInput
              label={"Store Front City"}
              value={city}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              placeholder={"Enter store front city"}
              onChange={(e) => {
                setCity(e.target.value);
                let err = cityField({ city: e.target.value });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.city ? true : false}
              errorMsg={error.city}
            />
            <CustomInput
              label={"Store Front Location"}
              value={sf_location}
              labelStyle="!bg-white"
              inputStyle="!bg-white"
              placeholder={"Enter store front Location"}
              onChange={(e) => {
                setSf_location(e.target.value);
                let err = sfLocationField({ sf_location: e.target.value });
                err ? setError(err) : setError({});
              }}
              type="text"
              required={true}
              error={error.sf_location ? true : false}
              errorMsg={error.sf_location}
            />
          </div>

          <h1>Account Information</h1>

          <Select
            value={accType}
            defaultValue={accType}
            options={accOpts}
            onChange={(option) => setAccType(option)}
            className={error.payment_type ? "error" : ""}
          />

          {accType.value === 1 || accType.value === 2 ? (
            <>
              <CustomInput
                label={"Company Name"}
                value={company_name}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Name"}
                onChange={(e) => {
                  setCompany_name(e.target.value);
                  let err = companyNameField({ company_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_name ? true : false}
                errorMsg={error.company_name}
              />
              <CustomInput
                label={"Company Address"}
                value={company_address}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Address"}
                onChange={(e) => {
                  setCompany_address(e.target.value);
                  let err = companyAddressField({
                    company_address: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_address ? true : false}
                errorMsg={error.company_address}
              />
              <CustomInput
                label={"Company Phone"}
                value={phone}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Phone Number"}
                onChange={(e) => {
                  setPhone(e.target.value);
                  let err = phoneNumberField({
                    phone: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.phone ? true : false}
                errorMsg={error.phone}
              />
              <div className="rowBox">
                <CustomInput
                  label={"Bank Name"}
                  value={bank_name}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Name"}
                  onChange={(e) => {
                    setBank_name(e.target.value);
                    let err = bankNameField({
                      bank_name: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_name ? true : false}
                  errorMsg={error.bank_name}
                />
                <CustomInput
                  label={
                    accType.value === 0
                      ? "Bank Routing for ACH"
                      : "Bank Routing for Wire"
                  }
                  value={bank_route}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Route Number"}
                  onChange={(e) => {
                    setBank_route(e.target.value);
                    let err = bankRouteField({
                      bank_route: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_route ? true : false}
                  errorMsg={error.bank_route}
                />
              </div>
              <CustomInput
                label={"Bank Address"}
                value={bank_address}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Bank Address"}
                onChange={(e) => {
                  setBank_address(e.target.value);
                  let err = bankAddressField({
                    bank_address: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.bank_address ? true : false}
                errorMsg={error.bank_address}
              />
              <div className="rowBox">
                <CustomInput
                  label={"Bank Account"}
                  value={bank_account}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Bank Account"}
                  onChange={(e) => {
                    setBank_account(e.target.value);
                    let err = bankAccountField({
                      bank_account: e.target.value,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.bank_account ? true : false}
                  errorMsg={error.bank_account}
                />
                <CustomInput
                  label={"Confirm Account"}
                  value={confirmBank_account}
                  labelStyle="!bg-white"
                  inputStyle="!bg-white"
                  placeholder={"Enter Confirm Bank Account"}
                  onChange={(e) => {
                    setConfirmBank_account(e.target.value);
                    let err = confirmBankAccountField({
                      confirmBank_account: e.target.value,
                      bank_account: bank_account,
                    });
                    err ? setError(err) : setError({});
                  }}
                  type="text"
                  required={true}
                  error={error.confirmBank_account ? true : false}
                  errorMsg={error.confirmBank_account}
                />
              </div>
            </>
          ) : accType.value === 3 ? (
            <>
              <CustomInput
                label={"Company Name"}
                value={company_name}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Name"}
                onChange={(e) => {
                  setCompany_name(e.target.value);
                  let err = companyNameField({ company_name: e.target.value });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_name ? true : false}
                errorMsg={error.company_name}
              />
              <CustomInput
                label={"Company Address"}
                value={company_address}
                labelStyle="!bg-white"
                inputStyle="!bg-white"
                placeholder={"Enter Company Address"}
                onChange={(e) => {
                  setCompany_address(e.target.value);
                  let err = companyAddressField({
                    company_address: e.target.value,
                  });
                  err ? setError(err) : setError({});
                }}
                type="text"
                required={true}
                error={error.company_address ? true : false}
                errorMsg={error.company_address}
              />
            </>
          ) : (
            ""
          )}
          <CustomButton
            title={"Create Store Front"}
            onClick={() => createStoreFront()}
            align={"place-items-end"}
            width={"w-[180px]"}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Create;
