import getPasswordError from "./PasswordError";

const validity = (userData) => {
  let error = {};

  if (userData?.type !== "Admin") {
    if (!userData.old_password || userData.old_password === "") {
      error.old_password = "Old Password is requried";
    } else {
      let err = getPasswordError({ password: userData.old_password });
      if (err !== "") {
        error.old_password = err;
      }
    }
  }
  if (!userData.new_password || userData.new_password === "") {
    error.new_password = "New Password is requried";
  } else {
    let err = getPasswordError({ password: userData.new_password });
    if (err !== "") {
      error.new_password = err;
    }
  }
  if (!userData.confirm_password || userData.confirm_password === "") {
    error.confirm_password = "Confirm password is requried";
  } else {
    let err = getPasswordError({ password: userData.confirm_password });
    if (err !== "") {
      error.confirm_password = err;
    }
  }
  if (userData.new_password !== userData.confirm_password) {
    error.confirm_password = "Password didn't match";
  }
  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
