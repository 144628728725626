import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axiosInstance from "../constants/axiosInstance";
import { logOut } from "../store/actions/User";
import { FaEye } from "react-icons/fa";
import OrderDetails from "../panel/order/OrderDetails";

const LatestOrderView = ({ changeView }) => {
  const hitOrderListLink = "get-orders";
  const [orders, setOrders] = useState([]);
  const [preview, setPreview] = useState(false);
  const [orderData, setOrderData] = useState({});

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getLatestOrderList();
  }, []);

  const getLatestOrderList = async () => {
    let params = {
      page: 1,
      limit: 7,
      order_by: "id, desc",
    };
    await axiosInstance
      .get(hitOrderListLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          if (res.data.result.rows.length > 0) {
            changeView(true);
          } else {
            changeView(false);
          }
          setOrders(res.data.result.rows);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
      });
  };

  const orderPreviewDetails = async (order) => {
    setOrderData(order);
    setPreview(true);
  };

  const closePreview = () => {
    setPreview(false);
  };

  return (
    <>
      {preview ? (
        <OrderDetails
          order={orderData}
          closePreview={closePreview}
          top=" h-screen"
        />
      ) : (
        ""
      )}
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial</TableCell>
                <TableCell>Store Front</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Views</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length > 0 ? (
                orders.map((order, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{order.sf_name}</TableCell>
                      <TableCell>{order.product_qnty}</TableCell>
                      <TableCell>${order.total_price}</TableCell>
                      <TableCell>
                        <span title="View Order Details">
                          <FaEye
                            size={20}
                            color="#fff"
                            className="p-1 cursor-pointer rounded-full bg-teal-700"
                            onClick={() => {
                              orderPreviewDetails(order);
                            }}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No data found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="flex justify-end mr-2">
            <Link to={"/admin/order"} className="text-sm text-theme my-2">
              See More
            </Link>
          </div>
        </TableContainer>
      </Paper>
    </>
  );
};

export default LatestOrderView;
