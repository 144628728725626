import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import axiosInstance from "../constants/axiosInstance";
import {
  confirmPasswordField,
  newPasswordField,
  otpField,
} from "../error/FieldErrorCheck";
import validity from "../error/ResetError";
import { updateLoading, updateResetToken } from "../store/actions/User";

const ResetPassword = () => {
  const hitPasswordResetLink = "auth/reset-password";
  const { token } = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serviceError, setServiceError] = useState("");
  const [otp, setOtp] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({});
  const { resetToken } = useSelector((state) => state.user);

  useEffect(() => {
    if (token !== resetToken.token) {
      navigate("/login");
    }
  }, []);

  const resetUserPassword = async () => {
    const postData = {
      email: resetToken.email,
      otp,
      new_password,
      confirm_password,
    };

    let valid = validity(postData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitPasswordResetLink, postData)
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Password reset successfully");
            dispatch(updateResetToken({}));
            setTimeout(() => {
              navigate("/login");
            }, 1000);
          } else {
            setServiceError(res.data.error.message);
            toast.error(res.data.error.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.error.message);
          }
          dispatch(updateLoading(false));
        });
    } else {
      toast.error("Check input field validity");
      setError(valid.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="loginField">
        <div className="loginBox">
          <div className="loginHeader">
            <h1>Reset Password</h1>
            {serviceError ? <h2 className="errorMsg">{serviceError}</h2> : ""}
            <p>
              Enter OTP, New password and Confirm password to Forgot password
              reset
            </p>
          </div>
          <div className="inputBody">
            <CustomInput
              label={"OTP"}
              value={otp}
              placeholder={"Enter OTP"}
              onChange={(e) => {
                setOtp(e.target.value);
                let err = otpField({ otp: e.target.value });
                setError(err);
                setServiceError("");
              }}
              required={true}
              type="text"
              error={error.otp ? true : false}
              errorMsg={error.otp}
            />

            <CustomInput
              label={"New Password"}
              value={new_password}
              type="password"
              placeholder={"Enter New Password"}
              onChange={(e) => {
                setNew_password(e.target.value);
                let err = newPasswordField({ new_password: e.target.value });
                err ? setError(err) : setError({});
                setServiceError("");
              }}
              error={error.new_password ? true : false}
              errorMsg={error.new_password}
              required={true}
            />
            <CustomInput
              label={"Confirm Password"}
              value={confirm_password}
              type="password"
              placeholder={"Enter Confirm Password"}
              onChange={(e) => {
                setConfirm_password(e.target.value);
                let err = confirmPasswordField({
                  confirm_password: e.target.value,
                  password: new_password,
                });
                err ? setError(err) : setError({});
                setServiceError("");
              }}
              error={error.confirm_password ? true : false}
              errorMsg={error.confirm_password}
              required={true}
            />

            <CustomButton
              title={"Reset Password"}
              onClick={resetUserPassword}
            />
          </div>
          <div className="alreadyRegister">
            <p>
              Remembered your password? <Link to={"/login"}>Login</Link>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} theme={"colored"} />
    </>
  );
};

export default ResetPassword;
