import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import axiosInstance from "../constants/axiosInstance";
import { logOut, updateLoading, userStateUpdate } from "../store/actions/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firstNameField, lastNameFiled } from "../error/FieldErrorCheck";

const UserUpdatePOP = ({ f_name, l_name, onClick }) => {
  const hitUpdateLink = "auth/update-user-info";
  const [first_name, setFirst_name] = useState(f_name);
  const [last_name, setLast_name] = useState(l_name);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  const userUpdate = async () => {
    const postData = {
      first_name,
      last_name,
    };
    let valid = errorCheck(postData);
    if (valid.isValid) {
      if (first_name !== f_name || last_name !== l_name) {
        dispatch(updateLoading(true));
        await axiosInstance
          .post(hitUpdateLink, postData, { token: access_token })
          .then((res) => {
            if (res.data.result_code === 0) {
              toast.success("Information updated successfully");
              let userData = { ...user, first_name, last_name };
              dispatch(userStateUpdate(userData));
              onClick();
            } else {
              toast.error("Failed to update information");
            }
            dispatch(updateLoading(false));
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.result_code === 11) {
                dispatch(logOut());
              }
            }
            dispatch(updateLoading(false));
          });
      } else {
        toast.warn("No change has made!");
      }
    } else {
      setError(valid.error);
    }
  };

  const errorCheck = (data) => {
    let error = {};
    if (!data.first_name || data.first_name === "") {
      error.first_name = "First Name is required";
    }
    if (!data.last_name || data.last_name === "") {
      error.last_name = "Last Name is requried";
    }

    return {
      error,
      isValid: Object.keys(error).length === 0,
    };
  };

  return (
    <>
      <div className="popupPanel">
        <div className="updateField">
          <div className="closeTag" onClick={onClick}>
            <MdClose />
          </div>
          <div className="loginHeader">
            <h1>User Update</h1>
          </div>
          <div className="inputBody max-h-[480px] overflow-y-auto">
            <CustomInput
              value={first_name}
              label={"First Name"}
              placeholder={"Enter First Name"}
              labelStyle={"!bg-white"}
              inputStyle={"!bg-white"}
              type={"text"}
              error={error.first_name ? true : false}
              errorMsg={error.first_name}
              required={true}
              onChange={(e) => {
                setFirst_name(e.target.value);
                let err = firstNameField({ first_name: e.target.value });
                setError(err);
              }}
            />
            <CustomInput
              value={last_name}
              label={"Last Name"}
              placeholder={"Enter Last Name"}
              labelStyle={"!bg-white"}
              inputStyle={"!bg-white"}
              type={"text"}
              error={error.last_name ? true : false}
              errorMsg={error.last_name}
              required={true}
              onChange={(e) => {
                setLast_name(e.target.value);
                let err = lastNameFiled({ last_name: e.target.value });
                setError(err);
              }}
            />
            <CustomButton title={"Update User"} onClick={userUpdate} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserUpdatePOP;
