import validator from "validator";
import getPasswordError from "./PasswordError";

const validity = (userData) => {
  let error = {};

  if (!userData.first_name || userData.first_name === "") {
    error.first_name = "First name is requried";
  }
  if (!userData.last_name || userData.last_name === "") {
    error.last_name = "Last name is requried";
  }
  if (!userData.email || userData.email === "") {
    error.email = "E-mail is requried";
  } else {
    if (!validator.isEmail(userData.email)) {
      error.email = "Invalid email";
    }
  }
  let passErr = getPasswordError(userData);
  if (passErr !== "") {
    error.password = passErr;
  }
  if (!userData.confirm_password || userData.confirm_password === "") {
    error.confirm_password = "Confirm password is requried";
  }
  if (userData.password !== userData.confirm_password) {
    error.confirm_password = "Password didn't matched";
  }
  if (userData.type === "ADMIN_USER") {
    if (!userData.role_id || userData.role_id === "") {
      error.role_id = "User role is required";
    }
  }
  if (userData.type === "STORE_USER") {
    if (!userData.role || userData.role === "") {
      error.role = "User role is required";
    }
  }

  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
