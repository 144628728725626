import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../constants/axiosInstance";
import { logOut, updateLoading, vendorUpdate } from "../store/actions/User";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { phonenumberFormat } from "../constants/ConstantData";
import {
  vendorAddressField,
  vendorLocationField,
  vendorNameField,
  vendorPhoneField,
  vendorWebsiteField,
} from "../error/FieldErrorCheck";
import Select from "react-select";

const VendorUpdatePOP = ({ vendorID, viewChange }) => {
  const hitVendorInfoLink = "get-vendor-info";
  const hitCountryLink = "get-countries";
  const hitStateLink = "get-states";
  const hitVendorUpdateLink = vendorID?.id
    ? "update-vendors/" + vendorID.id
    : "update-vendors/" + vendorID;
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [vendor_name, setVendor_name] = useState("");
  const [vendor_phone, setVendor_phone] = useState("");
  const [vendor_address, setVendor_address] = useState("");
  const [vendor_location, setVendor_location] = useState("");
  const [vendor_website, setVendor_website] = useState("");
  const [vendor, setVendor] = useState({});
  const [state, setState] = useState({ label: "Select State", value: "" });
  const [country, setCountry] = useState({
    label: "Select Country",
    value: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    if (vendorID?.id) {
      setVendor_name(vendorID.vendor_name);
      setVendor_phone(vendorID.vendor_phone);
      setVendor_address(vendorID.vendor_address);
      setVendor_location(vendorID.vendor_location);
      setVendor_website(vendorID.vendor_website);
      setVendor(vendorID);
      setCountry({
        label: vendorID.country,
        value: vendorID.country_id,
      });
      setState({
        label: vendorID.state,
        value: vendorID.state_id,
      });
    } else {
      getVendorInfo(vendorID);
    }
    getCountryList();
    changeCountryState(country);
  }, []);

  const getVendorInfo = async (vendorID) => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitVendorInfoLink, {
        params: { vendor_id: vendorID },
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          const vendorInfo = res.data.result;
          setVendor_name(vendorInfo.vendor_name);
          setVendor_phone(vendorInfo.vendor_phone);
          setVendor_address(vendorInfo.vendor_address);
          setVendor_location(vendorInfo.vendor_location);
          setVendor_website(vendorInfo.vendor_website);
          setVendor(vendorInfo);
          setCountry({
            label: vendorInfo.country,
            value: vendorInfo.country_id,
          });
          setState({
            label: vendorInfo.state,
            value: vendorInfo.state_id,
          });
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const updateVendorInfo = async () => {
    const vendorData = {
      vendor_name,
      vendor_phone,
      vendor_address,
      vendor_location,
      vendor_website,
      country_id: country.value,
      state_id: state.value,
    };
    let valid = errorCheck(vendorData);
    if (valid.isValid) {
      if (
        vendor.vendor_name !== vendor_name ||
        vendor_phone !== vendor.vendor_phone ||
        vendor.vendor_address !== vendor_address ||
        vendor.vendor_location !== vendor_location ||
        vendor.vendor_website !== vendor_website ||
        vendor.country_id !== country.value ||
        vendor.state_id !== state.value
      ) {
        dispatch(updateLoading(true));
        await axiosInstance
          .post(hitVendorUpdateLink, vendorData, { token: access_token })
          .then(async (res) => {
            if (res.data.result_code === 0) {
              toast.success("Vendor Updated Successfully", { autoClose: 2000 });
              if (vendorID?.id) {
                let vendorData = {
                  ...vendorID,
                  vendor_name,
                  vendor_phone,
                  vendor_address,
                  vendor_location,
                  vendor_website,
                  country: country.label,
                  country_id: country.value,
                  state: state.label,
                  state_id: state.value,
                };
                dispatch(vendorUpdate(vendorData));
              }
              viewChange();
            } else {
              toast.error("Failed to update vendor");
            }
            dispatch(updateLoading(false));
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data.result_code === 11) {
                dispatch(logOut());
              }
            }
            dispatch(updateLoading(false));
          });
      } else {
        toast.warn("No change has made");
      }
    } else {
      setError(valid.error);
    }
  };

  const errorCheck = (vendorData) => {
    let error = {};

    if (!vendorData.vendor_name || vendorData.vendor_name === "") {
      error.vendor_name = "Vendor Name is required";
    }
    if (!vendorData.vendor_phone || vendorData.vendor_phone === "") {
      error.vendor_phone = "Vendor phone is required";
    }
    if (!vendorData.vendor_address || vendorData.vendor_address === "") {
      error.vendor_address = "Vendor Address is required";
    }
    if (!vendorData.vendor_location || vendorData.vendor_location === "") {
      error.vendor_location = "Vendor Location is required";
    }
    if (!vendorData.vendor_website || vendorData.vendor_website === "") {
      error.vendor_website = "Vendor Website is required";
    }

    return {
      error,
      isValid: Object.keys(error).length === 0,
    };
  };

  const getCountryList = async () => {
    await axiosInstance
      .get(hitCountryLink)
      .then((res) => {
        if (res.data.result_code === 0) {
          setCountries(res.data.result);
        }
      })
      .catch((error) => {});
  };

  const changeCountryState = async (option) => {
    let country_id = option.value;
    await axiosInstance
      .get(hitStateLink, { params: { country_id } })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStates(res.data.result);
        }
      })
      .catch((error) => {});
  };

  let countryopts = [];
  let stateOpts = [];
  countries.map((country, index) => {
    return countryopts.push({ label: country.name, value: country.id });
  });

  states.map((state, index) => {
    return stateOpts.push({ label: state.name, value: state.id });
  });

  return (
    <div className="popupPanel">
      <div className="updateField max-w-md">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={viewChange}
        />
        <div className="loginHeader">
          <h1>Update Vendor</h1>
        </div>
        <div className="inputBody max-h-[480px] overflow-y-auto">
          <CustomInput
            value={vendor_name}
            onChange={(e) => {
              setVendor_name(e.target.value);
              let err = vendorNameField({ vendor_name: e.target.value });
              setError(err);
            }}
            label="Vendor Name"
            placeholder="Enter Vendor Name"
            type={"text"}
            inputStyle={"!bg-white max-w-md"}
            labelStyle={"!bg-white"}
            error={error.vendor_name ? true : false}
            errorMsg={error.vendor_name}
            required={true}
          />
          <CustomInput
            value={vendor_phone}
            onChange={(e) => {
              if (phonenumberFormat(e.target.value) !== undefined) {
                setVendor_phone(phonenumberFormat(e.target.value));
              }
              let err = vendorPhoneField({ vendor_phone: e.target.value });
              setError(err);
            }}
            label="Vendor Phone"
            placeholder="Enter Vendor Phone"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.vendor_phone ? true : false}
            errorMsg={error.vendor_phone}
            required={true}
          />
          <CustomInput
            value={vendor_address}
            onChange={(e) => {
              setVendor_address(e.target.value);
              let err = vendorAddressField({ vendor_address: e.target.value });
              setError(err);
            }}
            label="Vendor Address"
            placeholder="Enter Vendor Address"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.vendor_address ? true : false}
            errorMsg={error.vendor_address}
            required={true}
          />
          <CustomInput
            value={vendor_location}
            onChange={(e) => {
              setVendor_location(e.target.value);
              let err = vendorLocationField({
                vendor_location: e.target.value,
              });
              setError(err);
            }}
            label="Vendor Location"
            placeholder="Enter Vendor Location"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.vendor_location ? true : false}
            errorMsg={error.vendor_location}
            required={true}
          />

          <CustomInput
            value={vendor_website}
            onChange={(e) => {
              setVendor_website(e.target.value);
              let err = vendorWebsiteField({ vendor_website: e.target.value });
              setError(err);
            }}
            label="Vendor Website"
            placeholder="Enter Vendor Website"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.vendor_website ? true : false}
            errorMsg={error.vendor_website}
            required={true}
          />

          <div className="rowBox">
            <Select
              value={country}
              defaultValue={country}
              options={countryopts}
              onChange={(option) => {
                setCountry(option);
                changeCountryState(option);
              }}
              className={error.country ? "error" : ""}
            />

            {/* ----Store Front State---- */}
            <Select
              value={state}
              defaultValue={state}
              options={stateOpts}
              onChange={(option) => setState(option)}
              className={error.state ? "error" : ""}
            />
          </div>

          <CustomButton title={"Update Vendor"} onClick={updateVendorInfo} />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default VendorUpdatePOP;
