import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import ForgetPassword from "../user/ForgetPassword";
import Login from "../user/Login";
import ErrorPage from "../error/ErrorPage";
import RootLayout from "../Layout/RootLayout";
import AdminLayout from "../Layout/AdminLayout";
import Dashboard from "../panel/dashboard/index";
import Register from "../user/Register";
import CreateVendor from "../panel/Vendor/Create";
import VendorList from "../panel/Vendor/List";
import CreateStoreFront from "../panel/StoreFront/Create";
import StoreFrontList from "../panel/StoreFront/List";
import ManageStoreFront from "../panel/StoreFront/Manage";
import { loader as manageLoader } from "../Loader/loaderData";
import ManageVendor from "../panel/Vendor/Manage";
import ChangePassword from "../panel/ChangePassword";
import Create from "../panel/setting/user/Create";
import Role from "../panel/setting/user/Role";
import List from "../panel/setting/user/List";
import Order from "../panel/order/Order";
import Permission from "../panel/setting/user/Permission";
import Profile from "../panel/profile";
import PageNotFound from "../PageNotFound";
import PayoutList from "../panel/Vendor/Payout/PayoutList";
import StoreFrontUserList from "../panel/User/List";
import CreateVendorAdmin from "../panel/setting/vendor/user/Create";
import VendorAdminList from "../panel/setting/vendor/user/List";
import ResetPassword from "../user/ResetPassword";
import PendingPayouts from "../panel/Vendor/Payout/PendingPayouts";
import Report from "../panel/Report/OrderReport";
import OrderList from "../panel/StoreFront/OrderList";
import Config from "../panel/setting/config/Config";
import PayoutReport from "../panel/Report/PayoutReport";
import StoreFrontUserCreate from "../../src/panel/User/Create";
import TransactionSummery from "../panel/Report/TransactionSummery";
import TipsReport from "../panel/Report/TipsReport";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="create-account" element={<Register />} />
        <Route
          path="user/password/:token"
          element={<ResetPassword />}
          loader={manageLoader}
        />
      </Route>
      <Route
        path="admin/*"
        element={<AdminLayout />}
        errorElement={<ErrorPage />}
      >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="my-account" element={<Dashboard />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="vendor/create" element={<CreateVendor />} />
        <Route path="vendor/list" element={<VendorList param="" />} />
        <Route
          path="vendor/list/pending"
          element={<VendorList param="Pending" />}
        />
        <Route path="vendor/payout/list" element={<PayoutList />} />
        <Route
          path="vendor/:id/:name"
          element={<ManageVendor />}
          loader={manageLoader}
        />
        <Route path="store-front/create" element={<CreateStoreFront />} />
        <Route path="store-front/list" element={<StoreFrontList />} />
        <Route
          path="store-front/:id/:name"
          element={<ManageStoreFront />}
          loader={manageLoader}
        />
        <Route
          path="store-front/user/create"
          element={<StoreFrontUserCreate />}
        />
        <Route path="store-front/user/list" element={<StoreFrontUserList />} />
        <Route path="order" element={<Order param="" />} />
        <Route path="order/today" element={<Order param="today" />} />
        <Route
          path="store-front/:id/order"
          element={<OrderList />}
          loader={manageLoader}
        />
        <Route
          path="order/payable/:id"
          element={<Order param="payable" />}
          loader={manageLoader}
        />
        {/* <Route
          path="report/order/report/:start/:end/:vendor/:storefront/:search"
          loader={manageLoader}
          element={<Report />}
        /> */}
        <Route path="report/order/report" element={<Report />} />
        <Route path="report/tips/report" element={<TipsReport />} />
        <Route path="report/order/summery" element={<TransactionSummery />} />
        <Route path="report/payout/report" element={<PayoutReport />} />
        <Route path="setting/user/create" element={<Create />} />
        <Route path="setting/user/list" element={<List />} />
        <Route path="setting/user/role" element={<Role />} />
        <Route
          path="setting/user/role/permission/:id/:name"
          element={<Permission />}
          loader={manageLoader}
        />
        <Route
          path="setting/vendor/user/create"
          element={<CreateVendorAdmin />}
        />
        <Route path="setting/vendor/user/list" element={<VendorAdminList />} />
        <Route path="setting/config" element={<Config />} />
        <Route path="profile" element={<Profile />} />
        <Route path="vendor/payouts-pending" element={<PendingPayouts />} />
        <Route
          path="vendor/payout/list/:id"
          element={<PayoutList />}
          loader={manageLoader}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default router;
