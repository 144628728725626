import axios from "axios";
import md5 from "md5";
import {
  API_URL,
  SECRET_KEY,
  BASE_API_URL,
  client_type,
  client_version,
} from "./ConstantData";
import sign from "jwt-encode";

const randomChars =
  "startedSiliconOrchardNewJobAlmost13Days18Hours20Minutes25Seconds";

export const getHashKey = async (length) => {
  let start = 0;
  let salt = "";
  while (start < length) {
    salt += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    start++;
  }

  const md5Raw = SECRET_KEY + salt;
  const signKey = md5(md5Raw);
  let HashKey = salt + "." + signKey;

  return HashKey;
};

export const getPayToken = async () => {
  let jwtKey = sign({ BASE_API_URL }, SECRET_KEY);
  return jwtKey;
};

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let hashKey = await getHashKey(64);
    let PayToken = await getPayToken();

    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json";
    config.headers["X-RequestHash"] = hashKey;
    config.headers["X-SMOOTPAY-TOKEN"] = PayToken;
    config.headers.Authorization = `Bearer ${config.token}`;

    config.params = {
      ...config.params,
      client_type: client_type,
      client_version: client_version,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
