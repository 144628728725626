import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdDashboard,
  MdExpandLess,
  MdAddchart,
  MdGridView,
  MdOutlineSettings,
} from "react-icons/md";
import { ImUser } from "react-icons/im";
import { ImUsers } from "react-icons/im";
import { FaFirstOrder } from "react-icons/fa";
import { CiBadgeDollar } from "react-icons/ci";
import { TbReportAnalytics } from "react-icons/tb";
import { FcDataConfiguration } from "react-icons/fc";
import { useSelector } from "react-redux";
import { rulePermission } from "../constants/Rules";

export default function Sidebar({ expCollapse }) {
  const [sideMenuHeight, setSideMenuHeight] = useState("365px");
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let menuHeight = height - 56;
    setSideMenuHeight(menuHeight);
  }, []);

  const expandMenu = (e) => {
    try {
      if (
        e.target.parentElement
          .querySelector("ul")
          .classList.contains("active-sidebar")
      ) {
        e.target.parentElement
          .querySelector("ul")
          .classList.remove("active-sidebar");
        e.target
          .querySelector("a>svg:last-child")
          .classList.remove("!rotate-180");
      } else {
        let nodes = e.target.parentElement.parentElement.querySelectorAll("ul");
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          node.classList.remove("active-sidebar");
        }

        let arrows =
          e.target.parentElement.parentElement.querySelectorAll(
            "a>svg:last-child"
          );
        for (let i = 0; i < arrows.length; i++) {
          let arrow = arrows[i];
          if (arrow !== e.target.querySelector("a>svg:last-child")) {
            arrow.classList.remove("!rotate-180");
          }
        }

        if (
          !e.target.parentElement
            .querySelector("ul")
            .classList.contains("active-sidebar")
        ) {
          e.target.parentElement
            .querySelector("ul")
            .classList.add("active-sidebar");
          e.target
            .querySelector("a>svg:last-child")
            .classList.add("!rotate-180");
        }
      }
    } catch (error) {
      let nodes = e.target.parentElement.parentElement.querySelectorAll("ul");
      for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i];
        node.classList.remove("active-sidebar");
      }

      let arrows =
        e.target.parentElement.parentElement.querySelectorAll(
          "a>svg:last-child"
        );
      for (let i = 0; i < arrows.length; i++) {
        let arrow = arrows[i];
        if (arrow !== e.target.querySelector("a>svg:last-child")) {
          arrow.classList.remove("!rotate-180");
        }
      }
    }
  };

  return (
    <>
      <div className={expCollapse ? "sidebar !-left-52" : "sidebar"}>
        {/* <div className="sidebar-header"></div> */}
        <div className="sidebar-menu">
          <ul
            style={{
              height: sideMenuHeight,
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            <li>
              <Link
                to={"/"}
                className={expCollapse ? "collapseMenu singleCollapse" : ""}
              >
                <div>
                  <MdDashboard /> {expCollapse ? "" : "Dashboard"}
                </div>
              </Link>
            </li>
            {rulePermission(20, user) && rulePermission(21, user) ? (
              <li>
                <a
                  href="#Vendor"
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <ImUsers />
                    {expCollapse ? "" : "Vendor Manage"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  {rulePermission(20, user) ? (
                    <li>
                      <Link
                        to={"/admin/vendor/create"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdAddchart /> {expCollapse ? "" : "Add Vendor"}
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {rulePermission(21, user) ? (
                    <li>
                      <Link
                        to={"/admin/vendor/list"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdGridView /> {expCollapse ? "" : "Vendor List"}
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {/* {rulePermission(37, user) ? (
                    <li>
                      <Link
                        to={"/admin/vendor/payout/list"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdGridView /> {expCollapse ? "" : "Payout List"}
                        </div>
                      </Link>
                    </li>
                  ) : null} */}
                </ul>
              </li>
            ) : null}

            {rulePermission(36, user) || rulePermission(37, user) ? (
              <li>
                <a
                  href="#Payments"
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <CiBadgeDollar />
                    {expCollapse ? "" : "Payments"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  <li>
                    <Link
                      to={"/admin/vendor/payouts-pending"}
                      className={
                        expCollapse ? "collapseMenu collapseSubMenu" : ""
                      }
                    >
                      <div>
                        <MdGridView />
                        {expCollapse ? "" : "Payouts Pending"}
                      </div>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to={"/admin/vendor/payout/list"}
                      className={
                        expCollapse ? "collapseMenu collapseSubMenu" : ""
                      }
                    >
                      <div>
                        <MdGridView />
                        {expCollapse ? "" : "Paid List"}
                      </div>
                    </Link>
                  </li> */}
                </ul>
              </li>
            ) : (
              ""
            )}

            {user.role_id === 11 || user.role_id === 14 ? (
              <li>
                <a
                  href={"#Store Front User"}
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <ImUser />
                    {expCollapse ? "" : "Store Front Manage"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  {rulePermission(24, user) ? (
                    <li>
                      <Link
                        to={"/admin/store-front/create"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdAddchart />
                          {expCollapse ? "" : "Add Store Front"}
                        </div>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {rulePermission(26, user) ? (
                    <li>
                      <Link
                        to={"/admin/store-front/list"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdGridView />
                          {expCollapse ? "" : "Store Front List"}
                        </div>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : null}
            {user.role_id === 13 ? (
              <li>
                <a
                  href={"#"}
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <ImUser />
                    {expCollapse ? "" : "Store Front User Manage"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  <li>
                    <Link
                      to={"/admin/store-front/user/create"}
                      className={
                        expCollapse ? "collapseMenu collapseSubMenu" : ""
                      }
                    >
                      <div>
                        <MdAddchart />
                        {expCollapse ? "" : "Add Store Front User"}
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/admin/store-front/user/list"}
                      className={
                        expCollapse ? "collapseMenu collapseSubMenu" : ""
                      }
                    >
                      <div>
                        <MdGridView />
                        {expCollapse ? "" : "Store Front User List"}
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            <li>
              <a
                onClick={(e) => {
                  expandMenu(e);
                  navigate("/admin/order");
                }}
                className={
                  expCollapse
                    ? "collapseMenu singleCollapse cursor-pointer"
                    : " cursor-pointer"
                }
              >
                <div>
                  <FaFirstOrder />
                  {expCollapse ? "" : "Orders"}
                </div>
              </a>
            </li>
            {rulePermission(33, user) || rulePermission(37, user) ? (
              <li>
                <a
                  href={"#"}
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <TbReportAnalytics />
                    {expCollapse ? "" : "Reports"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  {rulePermission(33, user) ? (
                    <li>
                      <a
                        onClick={(e) => {
                          expandMenu(e);
                          navigate("/admin/report/order/report");
                        }}
                        className={
                          expCollapse
                            ? "collapseMenu singleCollapse cursor-pointer"
                            : " cursor-pointer"
                        }
                      >
                        <div>
                          <TbReportAnalytics />
                          {expCollapse ? "" : "Order Reports"}
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {rulePermission(33, user) ? (
                    <li>
                      <a
                        onClick={(e) => {
                          expandMenu(e);
                          navigate("/admin/report/tips/report");
                        }}
                        className={
                          expCollapse
                            ? "collapseMenu singleCollapse cursor-pointer"
                            : " cursor-pointer"
                        }
                      >
                        <div>
                          <TbReportAnalytics />
                          {expCollapse ? "" : "Tips Report"}
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {rulePermission(33, user) ? (
                    <li>
                      <a
                        onClick={(e) => {
                          expandMenu(e);
                          navigate("/admin/report/order/summery");
                        }}
                        className={
                          expCollapse
                            ? "collapseMenu singleCollapse cursor-pointer"
                            : " cursor-pointer"
                        }
                      >
                        <div>
                          <TbReportAnalytics />
                          {expCollapse ? "" : "Transaction Summary"}
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {rulePermission(37, user) ? (
                    <li>
                      <a
                        onClick={(e) => {
                          expandMenu(e);
                          navigate("/admin/report/payout/report");
                        }}
                        className={
                          expCollapse
                            ? "collapseMenu singleCollapse cursor-pointer"
                            : " cursor-pointer"
                        }
                      >
                        <div>
                          <TbReportAnalytics />
                          {expCollapse ? "" : "Payout Reports"}
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
            {rulePermission(1, user) ||
            rulePermission(2, user) ||
            rulePermission(3, user) ||
            rulePermission(4, user) ||
            rulePermission(5, user) ||
            rulePermission(6, user) ||
            rulePermission(7, user) ||
            rulePermission(8, user) ||
            rulePermission(9, user) ||
            rulePermission(10, user) ||
            rulePermission(39, user) ||
            rulePermission(46, user) ||
            rulePermission(47, user) ? (
              <li>
                <a
                  href="#Vendor"
                  onClick={(e) => expandMenu(e)}
                  className={expCollapse ? "collapseMenu" : ""}
                >
                  <div>
                    <MdOutlineSettings />
                    {expCollapse ? "" : "Settings"}
                  </div>
                  <MdExpandLess />
                </a>
                <ul>
                  {rulePermission(1, user) ? (
                    <li>
                      <Link
                        to={"/admin/setting/user/list"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdAddchart /> {expCollapse ? "" : "User"}
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {rulePermission(6, user) ? (
                    <li>
                      <Link
                        to={"/admin/setting/user/role"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <MdGridView /> {expCollapse ? "" : "Role Permission"}
                        </div>
                      </Link>
                    </li>
                  ) : null}

                  {rulePermission(46, user) ? (
                    <li>
                      <Link
                        to={"/admin/setting/config"}
                        className={
                          expCollapse ? "collapseMenu collapseSubMenu" : ""
                        }
                      >
                        <div>
                          <FcDataConfiguration /> {expCollapse ? "" : "Config"}
                        </div>
                      </Link>
                    </li>
                  ) : null}

                  {user.role_id === 11 ? (
                    rulePermission(39, user) ? (
                      <li>
                        <Link
                          to={"/admin/setting/vendor/user/list"}
                          className={
                            expCollapse ? "collapseMenu collapseSubMenu" : ""
                          }
                        >
                          <div>
                            <MdGridView /> {expCollapse ? "" : "User"}
                          </div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
