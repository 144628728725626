import React from "react";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
      <div className="h-screen flex flex-col place-content-center place-items-center">
        <div className="flex flex-col gap-6">
          <img src="/4041.png" className="m-auto max-w-[180px] w-full" />
          <div>
            <h1 className="text-4xl text-center text-slate-700">
              Page Not Found
            </h1>
            <p className="text-center font-light text-slate-500">
              You are walking on wrong route to reach destination
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
