import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput";
import axiosInstance from "../../constants/axiosInstance";
import validity from "../../error/UserError";
import { logOut, updateLoading } from "../../store/actions/User";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../components/CustomButton";
import Select from "react-select";
import { Helmet } from "react-helmet";
import {
  confirmPasswordField,
  emailField,
  firstNameField,
  lastNameFiled,
  passwordField,
} from "../../error/FieldErrorCheck";

const Create = ({ storeFrontID, moveToList }) => {
  const hitLink = "save-store-front-users";
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [role, setRole] = useState({ label: "Basic User", value: 12 });
  const [error, setError] = useState({});
  const { user, access_token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const userCreate = async () => {
    const userData = {
      store_front_id:
        storeFrontID === undefined
          ? user.others_info.store_fronts[0].id
          : storeFrontID,
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      role: role.value,
      type: "STORE_USER",
    };

    const valid = validity(userData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitLink, userData, { token: access_token })
        .then((res) => {
          let data = res.data;
          if (data.result_code === 0) {
            toast.success("User added successfully", { autoClose: 1000 });
            emptyField();
            moveToList();
          } else {
            toast.error(res.data.error.message, { autoClose: 1000 });
          }
          dispatch(updateLoading(false));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.result_code === 11) {
              dispatch(logOut());
            }
            toast.error(err.response.data.error.message);
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setFirst_name("");
    setLast_name("");
    setEmail("");
    setPassword("");
    setConfirm_password("");
  };

  return (
    <>
      <Helmet>
        <title>Add Store Front User - Smooth Pay</title>
      </Helmet>
      <div className="adminBox createBox">
        <div className="loginHeader">
          <h1>Store Front User</h1>
        </div>
        <div className="inputBody">
          <Select
            value={role}
            defaultValue={role}
            placeholder={{ label: "Basic User", value: "12" }}
            autoFocus={true}
            options={[
              { label: "Basic User", value: "12" },
              { label: "Manager", value: "13" },
            ]}
            onChange={(option) => {
              setRole(option);
            }}
            className={error.role ? "error" : ""}
          />
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={first_name}
              onChange={(e) => {
                setFirst_name(e.target.value);
                let err = firstNameField({ first_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="First Name"
              placeholder="Enter First Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.first_name ? true : false}
              errorMsg={error.first_name}
              required={true}
            />
            <CustomInput
              value={last_name}
              onChange={(e) => {
                setLast_name(e.target.value);
                let err = lastNameFiled({ last_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Last Name"
              placeholder="Enter Last Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.last_name ? true : false}
              errorMsg={error.last_name}
              required={true}
            />
          </div>
          <CustomInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              let err = emailField({ email: e.target.value });
              err ? setError(err) : setError({});
            }}
            label="E-mail"
            placeholder="Enter E-mail"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.email ? true : false}
            errorMsg={error.email}
            required={true}
          />
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                let err = passwordField({ password: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Password"
              placeholder="Enter Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.password ? true : false}
              errorMsg={error.password}
              required={true}
            />
            <CustomInput
              value={confirm_password}
              onChange={(e) => {
                setConfirm_password(e.target.value);
                let err = confirmPasswordField({
                  confirm_password: e.target.value,
                  password,
                });
                err ? setError(err) : setError({});
              }}
              label="Password"
              placeholder="Enter Confirm Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.confirm_password ? true : false}
              errorMsg={error.confirm_password}
              required={true}
            />
          </div>
          <CustomButton title={"Create User"} onClick={userCreate} />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Create;
