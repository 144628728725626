import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import UserUpdatePOP from "../../components/UserUpdatePOP";
import VendorUpdatePOP from "../../components/VendorUpdatePOP";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";

const Index = () => {
  const hitLink = "auth/logout";
  const [userUpdate, setUserUpdate] = useState(false);
  const [vendorUpdate, setVendorUpdate] = useState(false);
  const { user, vendor, access_token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogOut = async () => {
    dispatch(updateLoading(true));
    await axiosInstance
      .post(hitLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          dispatch(logOut());
          navigate("/");
        }
        dispatch(updateLoading(false));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.result_code === 11) {
            dispatch(logOut());
            navigate("/");
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const updateUserProfile = () => {
    setUserUpdate(true);
  };

  const updateVendorInfo = () => {
    setVendorUpdate(true);
  };

  const viewClose = () => {
    setUserUpdate(false);
    setVendorUpdate(false);
  };

  return (
    <>
      {userUpdate ? (
        <UserUpdatePOP
          f_name={user.first_name}
          l_name={user.last_name}
          onClick={viewClose}
        />
      ) : vendorUpdate ? (
        <VendorUpdatePOP vendorID={vendor} viewChange={viewClose} />
      ) : (
        ""
      )}
      <div className="profileField">
        <div className="profileBox">
          <div className="profileLogo">
            <FaUserCircle size={112} className="text-theme m-auto" />
          </div>
          <div className="profileBody">
            <h1>{user.first_name + " " + user.last_name}</h1>
            <h2>{user.email}</h2>
            {Object.keys(vendor).length > 0 ? (
              <>
                <h1></h1>
              </>
            ) : (
              ""
            )}
            <div className="actionBtn">
              <Link to={"#Edit"} onClick={updateUserProfile}>
                Update Personal Info
              </Link>
              {Object.keys(vendor).length > 0 ? (
                <Link to={"#Edit"} onClick={updateVendorInfo}>
                  Update Vendor Info
                </Link>
              ) : (
                ""
              )}
              <Link to={"/admin/change-password"}>Change Password</Link>
              <Link onClick={userLogOut}>Log Out</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
