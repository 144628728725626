import validator from "validator";

const validity = (vendorData) => {
  let error = {};
  let require = " is required";

  if (!vendorData.first_name || vendorData.first_name === "") {
    error.first_name = "First name" + require;
  }
  if (!vendorData.last_name || vendorData.last_name === "") {
    error.last_name = "Last name" + require;
  }
  if (!vendorData.email || vendorData.email === "") {
    error.email = "E-mail" + require;
  } else {
    if (!validator.isEmail(vendorData.email)) {
      error.email = "Invalid E-mail";
    }
  }
  if (!vendorData.password || vendorData.password === "") {
    error.password = "Password" + require;
  }
  if (!vendorData.confirm_password || vendorData.confirm_password === "") {
    error.confirm_password = "Confirm Password" + require;
  }
  if (vendorData.password !== vendorData.confirm_password) {
    error.confirm_password = "Password is not matching";
  }
  if (!vendorData.vendor_name || vendorData.vendor_name === "") {
    error.vendor_name = "Vendor Name" + require;
  }
  if (!vendorData.vendor_phone || vendorData.vendor_phone === "") {
    error.vendor_phone = "Vendor Phone" + require;
  }
  if (!vendorData.vendor_address || vendorData.vendor_address === "") {
    error.vendor_address = "Vendor address" + require;
  }
  if (!vendorData.country_id || vendorData.country_id === "") {
    error.country = "Country" + require;
  }
  if (!vendorData.state_id || vendorData.state_id === "") {
    error.state = "State" + require;
  }
  if (!vendorData.vendor_location || vendorData.vendor_location === "") {
    error.vendor_location = "Vendor Location" + require;
  }
  if (!vendorData.vendor_website || vendorData.vendor_website === "") {
    error.vendor_website = "Vendor Website" + require;
  }

  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
