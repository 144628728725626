import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import axiosInstance from "../../../constants/axiosInstance";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logOut, updateLoading } from "../../../store/actions/User";
import { RulesList, userRulePermission } from "../../../constants/Rules";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";

const Permission = () => {
  const { id, name } = useLoaderData();
  const hitRolePermissionLink = "get-roles";
  const hitPermissionUpateLink = "update-role-permission";
  const [permissions, setPermissions] = useState([]);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getRolePermissionList(id);
  }, [id, reload]);

  const getRolePermissionList = async (id) => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitRolePermissionLink, {
        params: { ids: id, permissions: true },
        token: access_token,
      })
      .then((res) => {
        console.log(res);
        if (res.data.result_code === 0) {
          setPermissions(res.data.result.rows[0].permissions);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const managePermission = async (role_id, permission_key, permissions) => {
    let status = userRulePermission(permission_key, permissions);
    let new_permission = status ? false : true;

    confirmAlert({
      title: "Permission Change",
      message: "Do you want to change permission for this role?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));
            await axiosInstance
              .post(
                hitPermissionUpateLink,
                { role_id, permission_key, new_permission },
                { token: access_token }
              )
              .then((res) => {
                if (res.data.result_code === 0) {
                  toast.success("Permission Updated!");
                  reload ? setReload(false) : setReload(true);
                }
                dispatch(updateLoading(false));
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      closeOnClickOutside: false,
    });
  };

  const doNothing = () => {};

  return (
    <>
      <Helmet>
        <title>User Role Permission - Smooth Pay</title>
      </Helmet>
      <div className="adminBox">
        <div className="loginHeader">
          <h1>{name} Roles</h1>
        </div>
        <div className="mt-14">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Permission Title</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {RulesList.length > 0 ? (
                    RulesList.map((permission, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{permission.value}</TableCell>
                          <TableCell>
                            <span
                              className={
                                id !== "1" && id !== "2" ? "cursor-pointer" : ""
                              }
                              onClick={() => {
                                id !== "1" && id !== "2"
                                  ? managePermission(
                                      id,
                                      permission.key,
                                      permissions
                                    )
                                  : doNothing();
                              }}
                            >
                              {userRulePermission(
                                permission.key,
                                permissions
                              ) ? (
                                <MdCheckBox
                                  size={32}
                                  color={
                                    id !== "1" && id !== "2" ? "blue" : "#CCC"
                                  }
                                />
                              ) : (
                                <MdCheckBoxOutlineBlank
                                  size={32}
                                  color="#CCC"
                                />
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No Data Fround</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Permission;
