import { createSlice } from "@reduxjs/toolkit";

const initialStateData = {
  isLoading: false,
  isLoggedIn: false,
  user: {},
  vendor: {},
  access_token: "",
  refresh_token: "",
  maintenance_mode: false,
  resetToken: {},
};

const userSlice = createSlice({
  name: "user",
  initialState: initialStateData,
  reducers: {
    access: (state) => {
      return state;
    },
    login: (state, actions) => {
      state.isLoggedIn = true;
      state.user = actions.payload.user;
      state.access_token = actions.payload.access_token;
      state.refresh_token = actions.payload.refresh_token;
      state.vendor = actions.payload.vendor;
    },
    vendorUpdate: (state, actions) => {
      state.vendor = actions.payload;
    },
    updateLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    updatePermission: (state, actions) => {
      state.user = actions.payload;
    },
    userStateUpdate: (state, actions) => {
      state.user = actions.payload;
    },
    updateMaintenance: (state, actions) => {
      state.maintenance_mode = actions.payload;
    },
    updateResetToken: (state, actions) => {
      state.resetToken = actions.payload;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.user = {};
      state.access_token = "";
      state.refresh_token = "";
    },
  },
});

export const {
  access,
  login,
  logOut,
  userStateUpdate,
  vendorUpdate,
  updateLoading,
  updatePermission,
  updateMaintenance,
  updateResetToken,
} = userSlice.actions;
export default userSlice.reducer;
