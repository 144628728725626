import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import validity from "../error/PayoutError";
import axiosInstance from "../constants/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { logOut, updateLoading } from "../store/actions/User";
import { toast } from "react-toastify";
import { getPaymentType } from "../constants/ConstantData";
import moment from "moment";

const Payment = ({ store_front, closeView, successPaid }) => {
  const hitPaymentCompleteLink = "save-payouts";
  const [paid_amount, setPaid_amount] = useState(
    store_front.total_mature_sales
  );
  const [paid_id, setPaid_id] = useState("");
  const [note, setNote] = useState("");
  const [paid_date, setPaid_date] = useState("");
  const [sf_id] = useState(store_front.id);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  const completePayment = async () => {
    //dispatch(updateLoading(true));
    const payoutData = {
      paid_amount,
      paid_id,
      paid_date: moment(paid_date).format("YYYY-MM-DD HH:mm:ss"),
      storefront_id: sf_id,
      note,
      payment_type: store_front.payment_type,
      company_name: store_front.company_name,
      company_address: store_front.company_address,
      phone: store_front.phone,
      bank_name: store_front.bank_name,
      bank_address: store_front.bank_address,
      bank_account: store_front.bank_account,
      bank_route: store_front.bank_route,
    };

    let valid = validity(payoutData);

    if (valid.isValid) {
      await axiosInstance
        .post(hitPaymentCompleteLink, payoutData, { token: access_token })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Vendor payment has done");
            closeView();
            successPaid();
          }
          dispatch(updateLoading(false));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.result_code === 11) {
              dispatch(logOut());
            }
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
      dispatch(updateLoading(false));
    }
  };

  return (
    <div className="popupPanel">
      <div className="updateField max-w-md">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={closeView}
        />
        <div className="loginHeader">
          <h1>Vendor Payment</h1>
        </div>
        <div className="inputBody max-h-[480px] overflow-y-auto">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-4">
              <p className="w-[130px] text-sm">Payable</p>
              <p className="text-sm">${store_front.total_mature_sales}</p>
            </div>
            <div className="flex flex-row gap-4">
              <p className="w-[130px] text-sm">Transaction Fee</p>
              <p className="text-sm">${store_front.total_mature_trx_fee}</p>
            </div>
            <div className="flex flex-row gap-4">
              <p className="w-[130px] text-sm">Total Tips</p>
              <p className="text-sm">${store_front.total_mature_tips}</p>
            </div>
            <div className="flex flex-row gap-4">
              <p className="w-[130px] text-sm">Total to Send</p>
              <p className="text-sm">${store_front.payable_amount}</p>
            </div>
          </div>

          <CustomInput
            value={getPaymentType(store_front.payment_type)}
            disabled={true}
            label="Account Type"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
          />
          {store_front.payment_type !== 3 ? (
            <>
              <CustomInput
                value={
                  store_front.bank_name === null
                    ? ""
                    : store_front.bank_name +
                      " (" +
                      store_front.bank_route +
                      ")"
                }
                disabled={true}
                label="Bank Name"
                type={"text"}
                inputStyle={"!bg-white"}
                labelStyle={"!bg-white"}
              />
              <CustomInput
                value={store_front.bank_account}
                disabled={true}
                label="Bank Account"
                type={"text"}
                inputStyle={"!bg-white"}
                labelStyle={"!bg-white"}
              />
            </>
          ) : (
            ""
          )}
          <CustomInput
            value={store_front.company_name}
            disabled={true}
            label="Company Name"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
          />
          <CustomInput
            value={store_front.company_address}
            disabled={true}
            label="Company Address"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
          />
          <CustomInput
            value={paid_id}
            onChange={(e) => {
              setPaid_id(e.target.value);
            }}
            label="Paid ID"
            placeholder="Enter Paid Transaction ID"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.paid_id ? true : false}
            errorMsg={error.paid_id}
            required={true}
          />
          <CustomInput
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            label="Note"
            placeholder="Enter Note"
            type={"text"}
            inputStyle={"!bg-white"}
            labelStyle={"!bg-white"}
            error={error.note ? true : false}
            errorMsg={error.note}
            required={false}
          />
          <DatePicker
            selected={paid_date}
            onChange={(date) => {
              setPaid_date(date);
            }}
            placeholderText="Paid Date"
            showTimeSelect
            dateFormat={"P p"}
            className="border border-theme rounded w-full px-4 py-2 focus-within:border focus-within:border-theme focus-within:outline-none focus-within:shadow-sm focus-within:shadow-theme"
          />
          <CustomButton title={"Complete Payment"} onClick={completePayment} />
        </div>
      </div>
    </div>
  );
};

export default Payment;
