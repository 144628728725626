import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import { checkSortOrderUpdate } from "../../constants/SortCheckUp";
import { filterRow } from "../../constants/ConstantData";

const TipsReport = () => {
  const hitOrderLink = "get-orders";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [lastSortType, setLastSortType] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [reports, setReports] = useState([]);

  const [vendors, setVendors] = useState([]);
  const [selectStoreFront, setSelectStoreFront] = useState({
    label: "Select Store Front",
    value: "",
  });
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "vendor_name", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "order_no", value: "asc", active: false },
    { key: "product_qnty", value: "asc", active: false },
    { key: "total_price", value: "asc", active: false },
    { key: "product_qnty", value: "asc", active: false },
    { key: "total_price", value: "asc", active: false },
  ]);
  const headers = [
    { label: "Order ID", key: "order_no" },
    { label: "Vendor Name", key: "vendor_name" },
    { label: "Storefront Name", key: "sf_name" },
    { label: "Product Quantity", key: "quantity" },
    { label: "Product Cost", key: "price" },
    { label: "Tips", key: "tip" },
    { label: "Service Fee", key: "service_fee" },
    { label: "Final Price", key: "final_price" },
    { label: "Square ID", key: "square_id" },
    { label: "Blockchain ID", key: "blockchain_id" },
    { label: "Smooth Profit", key: "smooth_profit" },
  ];

  useEffect(() => {
    getOrderList(
      rowsPerPage,
      page,
      "",
      "",
      user.role_id !== 13
        ? selectStoreFront?.value
        : user?.others_info?.store_fronts[0]?.id,
      startDateTime,
      endDateTime
    );
  }, []);

  const rowsChangingData = async (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    getOrderList(
      e.target.value,
      0,
      "",
      searchStr,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  const pageChangingData = async (e, newPage) => {
    setPage(newPage);
    getOrderList(
      rowsPerPage,
      newPage,
      lastSortType,
      searchStr,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  const getOrderList = async (
    rowsPerPage,
    cPage,
    orderBy,
    search,
    storefronts,
    startDate,
    endDate
  ) => {
    dispatch(updateLoading(true));
    let params = {
      limit: rowsPerPage,
      page: cPage + 1,
      order_by: orderBy,
      search,
      storefronts,
      start_date:
        startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    if (startDate !== "" && endDate === "") {
      params = { ...params, start_date: "", end_date: "" };
    }
    let abortController = new AbortController();
    await axiosInstance
      .get(hitOrderLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          let data = res.data.result;
          setReports(data.rows);
          setCount(data.total);
          let sort = orderBy.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
    abortController.abort();
  };

  const searchOrderData = (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getOrderList(
      rowsPerPage,
      0,
      lastSortType,
      e.target.value,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  let vendorOpts = [];
  vendors.map((vendor) => {
    return vendorOpts.push({ label: vendor.vendor_name, value: vendor.id });
  });

  const orderChangeByStartDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    if (endDateTime !== "") {
      getOrderList(
        rowsPerPage,
        page,
        lastSortType,
        searchStr,
        store_Fronts,
        date,
        endDateTime
      );
    }
  };

  const orderChangeByEndDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      store_Fronts,
      startDateTime,
      date
    );
  };

  return (
    <>
      <Helmet>
        <title>Tips Report - Smooth Pay</title>
      </Helmet>

      <div className="adminBox">
        <div className="loginHeader relative">
          <h1 className="mb-4">Tips Report</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="flex flex-col md:flex-row gap-4 justify-between place-items-center mx-4">
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-4 place-self-center">
                <ReactDatePicker
                  selected={startDateTime}
                  onChange={(date) => {
                    setStartDateTime(date);
                    orderChangeByStartDate(date);
                  }}
                  className="searchField"
                  placeholderText="Start Date"
                />
                <div className="flex place-self-center ">
                  <span>-</span>
                </div>
                <ReactDatePicker
                  selected={endDateTime}
                  onChange={(date) => {
                    setEndDateTime(date);
                    orderChangeByEndDate(date);
                  }}
                  className="searchField"
                  placeholderText="End Date"
                />
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel>Order No</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Quantity</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Product Total</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Tips</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Service Fee</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Total</TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.length > 0 ? (
                    reports.map((report, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{report.order_no}</TableCell>

                          <TableCell>{report.product_qnty}</TableCell>
                          <TableCell>${report.total_price}</TableCell>
                          <TableCell>${report.tip_amount}</TableCell>
                          <TableCell>${report.service_fee}</TableCell>
                          <TableCell>${report.total_received}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} className="!text-center">
                        No Report Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {reports && reports.length > 0 ? (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={filterRow}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={rowsChangingData}
                        onPageChange={pageChangingData}
                      />
                    </TableRow>
                  </TableFooter>
                ) : (
                  ""
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default TipsReport;
