import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import OrderDetails from "../order/OrderDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { filterRow } from "../../constants/ConstantData";
import { checkSortOrderUpdate } from "../../constants/SortCheckUp";

const OrderList = () => {
  const hitOrderLink = "get-orders";
  const { id } = useLoaderData();
  const [preview, setPreview] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "vendor_name", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "order_no", value: "asc", active: false },
    { key: "product_qnty", value: "asc", active: false },
    { key: "total_price", value: "asc", active: false },
  ]);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [lastSortType, setLastSortType] = useState("");

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getOrderList(rowsPerPage, page, "", "", id, startDateTime, endDateTime);
  }, [id]);

  const closePreview = () => {
    setPreview(false);
  };

  const orderPreviewDetails = (order) => {
    setOrderData(order);
    setPreview(true);
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    getOrderList(
      rowsPerPage,
      page,
      sort.key + "," + sort.value,
      searchStr,
      id,
      startDateTime,
      endDateTime
    );
  };

  const getOrderList = async (
    rowsPerPage,
    cPage,
    orderBy,
    search,
    storefronts,
    startDate,
    endDate
  ) => {
    dispatch(updateLoading(true));
    dispatch(updateLoading(true));

    let params = {
      limit: rowsPerPage,
      page: cPage + 1,
      order_by: orderBy,
      search,
      storefronts,
      start_date:
        startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    if (startDate !== "" && endDate === "") {
      params = { ...params, start_date: "", end_date: "" };
    }
    let abortController = new AbortController();
    await axiosInstance
      .get(hitOrderLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          let data = res.data.result;
          setOrders(data.rows);
          setCount(data.total);
          let sort = orderBy.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
    abortController.abort();
  };

  const searchOrderData = (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getOrderList(
      rowsPerPage,
      0,
      lastSortType,
      e.target.value,
      id,
      startDateTime,
      endDateTime
    );
  };

  const orderChangeByStartDate = async (date) => {
    if (endDateTime !== "") {
      getOrderList(
        rowsPerPage,
        page,
        lastSortType,
        searchStr,
        id,
        date,
        endDateTime
      );
    }
  };

  const orderChangeByEndDate = async (date) => {
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      id,
      startDateTime,
      date
    );
  };

  const rowsChangingData = async (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    getOrderList(
      e.target.value,
      0,
      lastSortType,
      searchStr,
      id,
      startDateTime,
      endDateTime
    );
  };

  const pageChangingData = async (e, newPage) => {
    setPage(newPage);
    getOrderList(
      rowsPerPage,
      newPage,
      lastSortType,
      searchStr,
      id,
      startDateTime,
      endDateTime
    );
  };

  return (
    <>
      <Helmet>
        <title>Storefront Orders- Smooth Pay</title>
      </Helmet>
      {preview ? (
        <OrderDetails
          order={orderData}
          closePreview={closePreview}
          top=" top-0"
        />
      ) : (
        ""
      )}
      <div className="adminBox">
        <div className="loginHeader relative">
          <h1 className="mb-4">Storefront Order List</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="flex flex-col md:flex-row gap-4 justify-between place-items-center mx-4">
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-4 place-self-center">
                <>
                  <ReactDatePicker
                    selected={startDateTime}
                    onChange={(date) => {
                      setStartDateTime(date);
                      orderChangeByStartDate(date);
                    }}
                    className="searchField"
                    placeholderText="Start Date"
                  />
                  <div className="flex place-self-center ">
                    <span>-</span>
                  </div>
                  <ReactDatePicker
                    selected={endDateTime}
                    onChange={(date) => {
                      setEndDateTime(date);
                      orderChangeByEndDate(date);
                    }}
                    className="searchField"
                    placeholderText="End Date"
                  />
                </>
              </div>
              <div className="flex flex-col md:flex-row gap-4 justify-end">
                <div className="searchBox">
                  <input
                    placeholder="Search here"
                    value={searchStr}
                    onChange={searchOrderData}
                    className="searchField"
                  />
                </div>
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        Store Front
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Order No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Quantity
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[5].active}
                        direction={sortArr[5].value}
                        onClick={() => orderListSorting(sortArr[5])}
                      >
                        Price
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders && orders.length > 0 ? (
                    orders.map((order, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>{order.vendor_name}</TableCell>
                          <TableCell>{order.sf_name}</TableCell>
                          <TableCell>{order.order_no}</TableCell>
                          <TableCell>{order.product_qnty}</TableCell>
                          <TableCell>{"$ " + order.total_price}</TableCell>
                          <TableCell>
                            <span title="View Order Details">
                              <FaEye
                                size={25}
                                color="#fff"
                                className="p-[4px] cursor-pointer rounded-full bg-teal-700"
                                onClick={() => {
                                  orderPreviewDetails(order);
                                }}
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} className="!text-center">
                        No Order Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {orders && orders.length > 0 ? (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={filterRow}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={rowsChangingData}
                        onPageChange={pageChangingData}
                      />
                    </TableRow>
                  </TableFooter>
                ) : (
                  ""
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default OrderList;
