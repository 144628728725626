import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../constants/axiosInstance";
import { logOut, updateLoading } from "../store/actions/User";
import CustomButton from "./CustomButton";

const FeeUpdate = ({ sf_id, closeView }) => {
  const hitFeeLink = "get-storefront-fees/" + sf_id;
  const hitFeeUpdateLink = "update-storefront-fees/" + sf_id;

  const [fees, setFees] = useState({});
  const [c_fee, setC_fee] = useState(0);
  const [s_fee, setS_fee] = useState(0);
  const [t_fee, setT_fee] = useState(0);
  const [editFee, setEditFee] = useState(false);
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getStoreFrontFee();
  }, [reload]);

  const getStoreFrontFee = async () => {
    await axiosInstance
      .get(hitFeeLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setFees(res.data.result);
          setC_fee(res.data.result.ORDER_CUSTOMER_FEE.value);
          setT_fee(res.data.result.ORDER_TRX_FEE.value);
          setS_fee(res.data.result.ORDER_SERVICE_FEE.value);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
      });
  };

  const storeFeeUpdate = async () => {
    if (
      fees.ORDER_TRX_FEE.value === t_fee &&
      fees.ORDER_CUSTOMER_FEE.value === c_fee &&
      fees.ORDER_SERVICE_FEE.value === s_fee
    ) {
      toast.warning("No data change to update");
    } else {
      dispatch(updateLoading(true));
      const postData = {
        ORDER_TRX_FEE: t_fee,
        ORDER_CUSTOMER_FEE: c_fee,
        ORDER_SERVICE_FEE: s_fee,
      };
      await axiosInstance
        .post(hitFeeUpdateLink, postData, {
          token: access_token,
        })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Fees updated successfully");
            setEditFee(false);
            setReload(!reload);
          } else {
            toast.error("Failed to update fees");
          }
          dispatch(updateLoading(false));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.result_code === 11) {
              dispatch(logOut());
            }
          }
          dispatch(updateLoading(false));
        });
    }
  };

  return (
    <div className="popupPanel">
      <div className="updateField max-w-lg">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={closeView}
        />
        <div className="loginHeader">
          <h1>Store Front Fee</h1>
        </div>
        <div className="inputBody max-h-[480px] overflow-y-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(fees).map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.replaceAll("_", " ")}</TableCell>
                    <TableCell>
                      {editFee ? (
                        <input
                          type="text"
                          value={
                            index === 0 ? t_fee : index === 1 ? c_fee : s_fee
                          }
                          onChange={(e) =>
                            index === 0
                              ? setT_fee(e.target.value)
                              : index === 1
                              ? setC_fee(e.target.value)
                              : setS_fee(e.target.value)
                          }
                          className="editFeeClass"
                        />
                      ) : (
                        Object.values(fees)[index].value
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {editFee ? (
                    <CustomButton title={"Update"} onClick={storeFeeUpdate} />
                  ) : (
                    <CustomButton
                      title={"Edit"}
                      onClick={() => {
                        setEditFee(true);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default FeeUpdate;
