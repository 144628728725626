import React from "react";
import { Helmet } from "react-helmet";
import { BiError } from "react-icons/bi";

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>System Error - Smooth Pay</title>
      </Helmet>
      <div className="w-screen h-screen grid place-content-center">
        <div className="max-w-md w-full grid gap-4 place-content-center">
          <div className="flex place-content-center">
            <BiError size={62} color="red" />
          </div>
          <p className="text-center">
            An error occuered due to browse.<br></br> Please click the button
            below to try again.
          </p>
          <p className="text-center">
            <span
              onClick={() => {
                window.location.href = "/";
              }}
              className="border-b-[1px] border-blue-700 pb-[2px] cursor-pointer text-blue-700 hover:text-blue-800"
            >
              Redirect
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
