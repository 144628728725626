import React, { useState } from "react";
import { ImEye, ImEyeBlocked } from "react-icons/im";

const CustomInput = ({
  value,
  label,
  labelStyle,
  type,
  placeholder,
  onChange,
  error,
  errorMsg,
  required,
  inputStyle,
  onKeyUp,
  disabled = false,
  pattern,
}) => {
  const [iType, setIType] = useState(type);

  return (
    <>
      <div className="inputBlock">
        <div className={error ? "inputBox !border-red-600" : "inputBox"}>
          <label className={error ? "text-red-600 " + labelStyle : labelStyle}>
            {label}
            {required ? <span className="required-tag">*</span> : ""}
          </label>
          <input
            type={iType}
            placeholder={placeholder}
            onChange={onChange}
            className={inputStyle}
            value={value}
            onKeyUp={onKeyUp}
            autoComplete={"new-password"}
            disabled={disabled}
            pattern={pattern}
          />
          {type === "password" ? (
            iType === "password" ? (
              <ImEyeBlocked
                size={16}
                color="#777"
                onClick={() => {
                  setIType("text");
                }}
                className="absolute top-1/2 -translate-y-1/2 right-1 cursor-pointer"
              />
            ) : (
              <ImEye
                size={16}
                color="#333"
                onClick={() => {
                  setIType("password");
                }}
                className="absolute top-1/2 -translate-y-1/2 right-1 cursor-pointer"
              />
            )
          ) : null}
        </div>
        {error ? (
          <p className="input-error-msg whitespace-pre-wrap">{errorMsg}</p>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CustomInput;
