import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FiKey } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ChangePassword from "../../../components/ChangePassword";
import CustomButton from "../../../components/CustomButton";
import axiosInstance from "../../../constants/axiosInstance";
import { filterRow } from "../../../constants/ConstantData";
import { rulePermission } from "../../../constants/Rules";
import { checkSortOrderUpdate } from "../../../constants/SortCheckUp";
import { logOut, updateLoading } from "../../../store/actions/User";

const List = () => {
  const hitPortalUserLink = "get-users";
  const [userList, setUserList] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [user_id, setUser_id] = useState("");
  const dispatch = useDispatch();
  const { access_token, user } = useSelector((state) => state.user);
  const [lastSortType, setLastSortType] = useState("");
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "first_name", value: "asc", active: false },
    { key: "email", value: "asc", active: false },
    { key: "role_title", value: "asc", active: false },
    { key: "status", value: "asc", active: false },
  ]);

  useEffect(() => {
    getPortalUserList(searchStr, page, rowsPerPage, lastSortType);
  }, []);

  const getPortalUserList = async (searchStr, cPage, rowsPerPage, order_by) => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitPortalUserLink, {
        params: {
          search: searchStr,
          page: cPage + 1,
          limit: rowsPerPage,
          order_by,
        },
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setUserList(res.data.result);
          let sort = order_by.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
    getPortalUserList(searchStr, newPage, rowsPerPage, lastSortType);
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
    getPortalUserList(searchStr, 0, e.target.value, lastSortType);
  };

  const searchVendorData = async (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getPortalUserList(e.target.value, 0, rowsPerPage, lastSortType);
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    getPortalUserList(
      searchStr,
      page,
      rowsPerPage,
      sort.key + "," + sort.value
    );
  };

  const passwordChange = (id) => {
    setUser_id(id);
    setChangePassword(true);
  };

  const changeFieldView = () => {
    setUser_id("");
    setChangePassword(false);
  };

  return (
    <>
      <Helmet>
        <title>Portal User List - Smooth Pay</title>
      </Helmet>

      {changePassword && (
        <ChangePassword userID={user_id} viewChange={changeFieldView} />
      )}

      <div className="manageActionField p-2 place-content-end">
        {rulePermission(1, user) ? (
          <Link to={"/admin/setting/user/create"}>
            <CustomButton title={"Create User"} width={"!bg-teal-700"} />
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="adminBox">
        <div className="loginHeader">
          <h1>User List</h1>
        </div>
        <div className="tablePlace">
          <Paper>
            <div className="searchBox">
              <input
                placeholder="Search here"
                value={searchStr}
                onChange={searchVendorData}
                className="searchField"
              />
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        User Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        E-mail
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Role
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.hasOwnProperty("rows") ? (
                    userList.rows.map((tableData, index) => {
                      let status;
                      if (tableData.status === 1) {
                        status = <span className="greenTablet">Active</span>;
                      } else {
                        status = <span className="redTablet">Inactive</span>;
                      }
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>
                            {tableData.first_name + " " + tableData.last_name}
                          </TableCell>
                          <TableCell>{tableData.email}</TableCell>
                          <TableCell>{tableData.role_title}</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>
                            <div className="actionBody">
                              <span
                                onClick={(e) => {
                                  passwordChange(tableData.id);
                                }}
                                title="Change Password"
                                className="greenSignal"
                              >
                                <FiKey />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} className="!text-center">
                        No user Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {userList?.rows ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={userList.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default List;
