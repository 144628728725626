import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdList } from "react-icons/md";
import { FiDollarSign } from "react-icons/fi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from "../../../components/Payment";
import axiosInstance from "../../../constants/axiosInstance";
import { filterRow } from "../../../constants/ConstantData";
import { checkSortOrderUpdate } from "../../../constants/SortCheckUp";
import { logOut, updateLoading } from "../../../store/actions/User";
import { Link } from "react-router-dom";

const PendingPayouts = () => {
  const hitLink = "get-storefronts";
  const [storeList, setStoreList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reload, setReload] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [loader] = useState(true);
  const [store_front, setStore_front] = useState({});
  const [payment, setPayment] = useState(false);
  const [lastSortType, setLastSortType] = useState("id,desc");
  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "vendor_name", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "pending_payment", value: "asc", active: false },
    { key: "pending_payment", value: "asc", active: false },
    { key: "payable_amount", value: "asc", active: false },
    { key: "total_sales", value: "asc", active: false },
  ]);
  const abortController = new AbortController();

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getStoreFrontList(page, rowsPerPage, lastSortType, "");
  }, [page, reload]);

  const successPaid = () => {
    reload ? setReload(false) : setReload(true);
  };

  const getStoreFrontList = async (cPage, cRows, order_by, search) => {
    if (loader) {
      dispatch(updateLoading(true));
    }

    let params = {
      page: cPage + 1,
      limit: cRows,
      order_by,
      search,
      payable: 1,
    };

    await axiosInstance
      .get(hitLink, {
        params,
        token: access_token,
        signal: abortController.signal,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStoreList(res.data.result);
          let sort = order_by.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
    getStoreFrontList(newPage, rowsPerPage, lastSortType, searchStr);
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
    getStoreFrontList(0, e.target.value, lastSortType, searchStr);
  };

  const searchVendorData = async (e) => {
    setSearchStr(e.target.value);

    let requestTimeout = setTimeout(() => {
      if (
        e.target.value.length - 1 === searchStr.length ||
        e.target.value.length === searchStr.length - 1 ||
        e.target.value.length < 1
      ) {
        setPage(0);
        getStoreFrontList(0, rowsPerPage, lastSortType, e.target.value);
      }
    }, 500);
    if (e.target.value.length === 0) {
      clearTimeout(requestTimeout);
    }
  };

  const closeView = () => {
    setPayment(false);
  };

  const orderListSorting = async (sort) => {
    setLastSortType(sort.key + "," + sort.value);
    getStoreFrontList(
      page,
      rowsPerPage,
      sort.key + "," + sort.value,
      searchStr
    );
  };

  const payableAmountPay = (storeFront) => {
    setStore_front(storeFront);
    setPayment(true);
  };

  return (
    <>
      <Helmet>
        <title>Pending Payout List - Smooth Pay</title>
      </Helmet>
      {payment ? (
        <Payment
          store_front={store_front}
          closeView={closeView}
          successPaid={successPaid}
        />
      ) : (
        ""
      )}
      <div className="adminBox" id="vendor">
        <div className="loginHeader">
          <h1>{"Pending Payout List"}</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="searchBox">
              <input
                placeholder="Search here"
                value={searchStr}
                onChange={searchVendorData}
                className="searchField"
              />
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[0].active}
                        direction={sortArr[0].value}
                        onClick={() => orderListSorting(sortArr[0])}
                      >
                        Serial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[1].active}
                        direction={sortArr[1].value}
                        onClick={() => orderListSorting(sortArr[1])}
                      >
                        Vendor Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[2].active}
                        direction={sortArr[2].value}
                        onClick={() => orderListSorting(sortArr[2])}
                      >
                        Store Front
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[3].active}
                        direction={sortArr[3].value}
                        onClick={() => orderListSorting(sortArr[3])}
                      >
                        Total Product Sales
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Total Tips</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Sercice Fees
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Pending Payemnts
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[4].active}
                        direction={sortArr[4].value}
                        onClick={() => orderListSorting(sortArr[4])}
                      >
                        Payable
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortArr[5].active}
                        direction={sortArr[5].value}
                        onClick={() => orderListSorting(sortArr[5])}
                      >
                        Total Gross
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeList?.rows && storeList?.rows.length > 0 ? (
                    storeList?.rows.map((tableData, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>{tableData.vendor_name}</TableCell>
                          <TableCell>{tableData.sf_name}</TableCell>
                          <TableCell>${tableData.total_sales}</TableCell>
                          <TableCell>${tableData.total_tips}</TableCell>
                          <TableCell>${tableData.total_service_fee}</TableCell>
                          <TableCell>${tableData.pending_payment}</TableCell>
                          <TableCell>${tableData.payable_amount}</TableCell>
                          <TableCell>${tableData.total_received}</TableCell>
                          <TableCell>
                            <div className="flex flex-row gap-2">
                              {tableData.payable_amount > 0 ? (
                                <span
                                  className="flex flex-row gap-1 place-items-center greenTablet !px-4 hover:bg-green-600 hover:shadow hover:shadow-green-600 transition-all duration-300"
                                  onClick={() => {
                                    payableAmountPay(tableData);
                                  }}
                                >
                                  <FiDollarSign size={12} /> Pay
                                </span>
                              ) : (
                                <span className="text-theme text-lg font-medium">
                                  Paid
                                </span>
                              )}
                              <Link to={"/admin/order/payable/" + tableData.id}>
                                <span className="purpleTablet !px-4 hover:bg-purple-600 hover:shadow hover:shadow-purple-600 transition-all duration-300 flex flex-row gap-1 place-items-center">
                                  <MdList />
                                  View
                                </span>
                              </Link>
                              {tableData.payout_amount > 0 ? (
                                <Link
                                  to={
                                    "/admin/vendor/payout/list/" + tableData.id
                                  }
                                >
                                  <span className="tealTablet !px-4 hover:bg-teal-600 hover:shadow hover:shadow-teal-600 transition-all duration-300 flex flex-row gap-1 place-items-center">
                                    <FaFileInvoiceDollar size={12} />
                                    Invoice
                                  </span>
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} className="!text-center">
                        No Pending Payout Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {storeList?.rows && storeList?.rows.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={storeList.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PendingPayouts;
