import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validity from "../../../error/UserError";
import axiosInstance from "../../../constants/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { logOut, updateLoading } from "../../../store/actions/User";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { disableRole } from "../../../constants/ConstantData";
import { Helmet } from "react-helmet";
import { rulePermission } from "../../../constants/Rules";
import {
  confirmPasswordField,
  emailField,
  firstNameField,
  lastNameFiled,
  passwordField,
} from "../../../error/FieldErrorCheck";

const Create = () => {
  const hitUserCreateLink = "add-new-user";
  const hitUserRoleLink = "get-roles";
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({});
  const [roles, setRoles] = useState({});
  const [role_id, setRole_id] = useState({
    label: "Select User Role",
    value: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { access_token, user } = useSelector((state) => state.user);

  useEffect(() => {
    getUserRoles();
  }, []);

  const getUserRoles = async () => {
    await axiosInstance
      .get(hitUserRoleLink, { token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setRoles(res.data.result);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
          toast.error(error.response.data.error.message, { autoClose: 2000 });
        }
      });
  };

  const userCreate = async () => {
    const userData = {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      role_id: role_id.value,
      type: "ADMIN_USER",
    };
    const valid = validity(userData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitUserCreateLink, JSON.stringify(userData), {
          token: access_token,
        })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Panel User Created Successfully", {
              autoClose: 2000,
            });
            emptyField();
            navigate("/admin/setting/user/list");
          } else {
            toast.error(res.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.result_code === 11) {
              dispatch(logOut());
            }
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setFirst_name("");
    setLast_name("");
    setEmail("");
    setPassword("");
    setConfirm_password("");
  };

  let roleObj = [];
  if (roles.hasOwnProperty("rows")) {
    roles.rows.map((role) => {
      return roleObj.push({
        label: role.title,
        value: role.id,
        isDisabled: disableRole.includes(role.id) ? true : false,
      });
    });
  }

  return (
    <>
      <Helmet>
        <title>Create Portal user - Smooth Pay</title>
      </Helmet>
      <div className="manageActionField p-2 place-content-end">
        {rulePermission(35, user) ? (
          <Link to={"/admin/setting/user/list"}>
            <CustomButton title={"User List"} width={"!bg-teal-700"} />
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="adminBox createBox">
        <div className="loginHeader">
          <h1>Create New User</h1>
        </div>
        <div className="inputBody">
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={first_name}
              onChange={(e) => {
                setFirst_name(e.target.value);
                let err = firstNameField({ first_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="First Name"
              placeholder="Enter First Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.first_name ? true : false}
              errorMsg={error.first_name}
              required={true}
            />
            <CustomInput
              value={last_name}
              onChange={(e) => {
                setLast_name(e.target.value);
                let err = lastNameFiled({ last_name: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Last Name"
              placeholder="Enter Last Name"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.last_name ? true : false}
              errorMsg={error.last_name}
              required={true}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Select
              placeholder="Select User Role"
              defaultValue={"Select User Role"}
              value={role_id}
              options={roleObj}
              onChange={(option) => {
                setRole_id(option);
              }}
              className={error.role_id ? "error" : ""}
            />
            <CustomInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                let err = emailField({ email: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="E-mail"
              placeholder="Enter E-mail"
              type={"text"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.email ? true : false}
              errorMsg={error.email}
              required={true}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                let err = passwordField({ password: e.target.value });
                err ? setError(err) : setError({});
              }}
              label="Password"
              placeholder="Enter Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={
                error.password ? (error.password.length ? true : false) : false
              }
              errorMsg={error.password ? error.password : ""}
              required={true}
            />
            <CustomInput
              value={confirm_password}
              onChange={(e) => {
                setConfirm_password(e.target.value);
                let err = confirmPasswordField({
                  confirm_password: e.target.value,
                  password,
                });
                err ? setError(err) : setError({});
              }}
              label="Confirm Password"
              placeholder="Enter Confirm Password"
              type={"password"}
              inputStyle={"!bg-white"}
              labelStyle={"!bg-white"}
              error={error.confirm_password ? true : false}
              errorMsg={error.confirm_password}
              required={true}
            />
          </div>
          <CustomButton title={"Create User"} onClick={userCreate} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Create;
