import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import axiosInstance from "../../../../constants/axiosInstance";
import { filterRow } from "../../../../constants/ConstantData";
import { logOut, updateLoading } from "../../../../store/actions/User";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiKey } from "react-icons/fi";
import ChangePassword from "../../../../components/ChangePassword";

const List = () => {
  const hitPortalUserLink = "get-vendor-info";
  const hitRemoveUserLink = "remove-vendor-users";
  const hitUpdateStatusLink = "update-vendor-users";
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [changePassword, setChangePassword] = useState(false);
  const [user_id, setUser_id] = useState("");
  const dispatch = useDispatch();
  const { access_token, vendor } = useSelector((state) => state.user);

  useEffect(() => {
    getVendorUserList();
  }, []);

  const getVendorUserList = async () => {
    dispatch(updateLoading(true));
    await axiosInstance
      .get(hitPortalUserLink, {
        params: { vendor_id: vendor.id },
        token: access_token,
      })
      .then((res) => {
        console.log(res);
        if (res.data.result_code === 0) {
          if (Object.keys(res.data.result).length > 0) {
            setUserList(res.data.result?.vendor_users);
          }
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
  };

  const listUpdateOnPageChange = async (e, newPage) => {
    setPage(newPage);
  };

  const listUpdateOnRowsChange = async (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const removeVendorUser = async (vendorUserID) => {
    confirmAlert({
      title: "Remove Vendor User",
      message: "Are you sure to remove vendor user?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));
            const postData = {
              user_id: vendorUserID,
              vendor_id: vendor.id,
            };
            await axiosInstance
              .post(hitRemoveUserLink, postData, {
                token: access_token,
              })
              .then((res) => {
                if (res.data.result_code === 0) {
                  getVendorUserList();
                }
                dispatch(updateLoading(false));
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      closeOnClickOutside: false,
    });
  };

  const ChangeVendorUserStatus = (e, user_id) => {
    let stateText = e.target.innerText;
    let status = 0;
    if (stateText === "Inactive") {
      status = 1;
    }
    const statusData = {
      user_id: user_id,
      vendor_id: vendor.id,
      status: status,
    };
    confirmAlert({
      title: stateText === "Inactive" ? "User Activation" : "User Inactivation",
      message:
        stateText === "Inactive"
          ? "Are you sure to active this vendor user?"
          : "Are you sure to inactive this vendor user?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));

            await axiosInstance
              .post(hitUpdateStatusLink, statusData, { token: access_token })
              .then((res) => {
                if (res.data.result_code === 0) {
                  toast.success("User status changed successfully", {
                    autoClose: 1000,
                  });
                  if (status === 1) {
                    e.target.classList.remove("redTablet");
                    e.target.classList.add("greenTablet");
                    e.target.innerText = "Active";
                  } else {
                    e.target.classList.remove("greenTablet");
                    e.target.classList.add("redTablet");
                    e.target.innerText = "Inactive";
                  }
                } else {
                  toast.error(res.data.error.message, { autoClose: 1000 });
                }
                dispatch(updateLoading(false));
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      closeOnClickOutside: false,
    });
  };

  const passwordChange = (id) => {
    setUser_id(id);
    setChangePassword(true);
  };

  const changeFieldView = () => {
    setUser_id("");
    setChangePassword(false);
  };

  return (
    <>
      {changePassword && (
        <ChangePassword userID={user_id} viewChange={changeFieldView} />
      )}

      <Helmet>
        <title>Portal User List - Smooth Pay</title>
      </Helmet>
      <div className="manageActionField p-2 place-content-end">
        <Link to={"/admin/setting/vendor/user/create"}>
          <CustomButton title={"Create User"} width={"!bg-teal-700"} />
        </Link>
      </div>
      <div className="adminBox">
        <div className="loginHeader mb-4">
          <h1>User List</h1>
        </div>
        <div className="tablePlace">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.length > 0 ? (
                    userList.map((tableData, index) => {
                      let status;
                      if (tableData.vendor_user_status === 1) {
                        status = (
                          <span
                            className="greenTablet"
                            onClick={(e) => {
                              ChangeVendorUserStatus(e, tableData.id);
                            }}
                          >
                            Active
                          </span>
                        );
                      } else {
                        status = (
                          <span
                            className="redTablet"
                            onClick={(e) => {
                              ChangeVendorUserStatus(e, tableData.id);
                            }}
                          >
                            Inactive
                          </span>
                        );
                      }
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell>{tableData.first_name}</TableCell>
                          <TableCell>{tableData.last_name}</TableCell>
                          <TableCell>{tableData.email}</TableCell>
                          <TableCell>Vendor Admin</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>
                            <div className="actionBody">
                              {/* <span
                                onClick={(e) => {
                                  console.log("Comming Soon.....");
                                }}
                                title="Edit"
                                className="greenSignal"
                              >
                                <MdEditNote />
                              </span> */}
                              <span
                                onClick={(e) => {
                                  removeVendorUser(tableData.id);
                                }}
                                title="Remvoe"
                                className="redSignal"
                              >
                                <MdClose />
                              </span>
                              <span
                                onClick={(e) => {
                                  passwordChange(tableData.id);
                                }}
                                title="Change Password"
                                className="greenSignal"
                              >
                                <FiKey />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} className="!text-center">
                        No Vendor User Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {userList.rows ? (
              <TablePagination
                rowsPerPageOptions={filterRow}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={listUpdateOnPageChange}
                onRowsPerPageChange={listUpdateOnRowsChange}
              ></TablePagination>
            ) : null}
          </Paper>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default List;
