import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Triangle } from "react-loader-spinner";

const RootLayout = () => {
  const { isLoading, isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    userAuthCheck();
  }, []);

  const userAuthCheck = async () => {
    if (isLoggedIn) {
      navigate("/admin/dashboard");
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div>
            <Triangle
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <p className="loaderText">Loading....</p>
          </div>
        </div>
      ) : null}
      <Outlet />
    </>
  );
};

export default RootLayout;
