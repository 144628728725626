import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Create from "../User/Create";
import List from "../User/List";
import { MdArrowForward } from "react-icons/md";

const Manage = () => {
  const { name, id } = useLoaderData();
  const [view, setView] = useState("L");
  const [vendorName, setVendorName] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const updateAction = () => {
    view === "L" ? setView("C") : setView("L");
  };

  const changeVendorName = (vendor) => {
    setVendorName(vendor);
  };

  return (
    <>
      <div className="adminBox">
        <div className="manageActionField">
          <div className="bredcumList">
            {user.role_id === 11 || user.role_id === 14 ? (
              <>
                <Link to={"/admin/store-front/list"}>Store Front List</Link>
              </>
            ) : (
              <>
                <Link to={"/admin/vendor/list"}>Vendor List</Link>
                <MdArrowForward />
                <a onClick={() => navigate(-1)} className="cursor-pointer">
                  Store Front List
                </a>
              </>
            )}
            <MdArrowForward />
            <span>{view === "L" ? "User List" : "Create User"}</span>
          </div>
          <div className="sv_title">
            <h1 className="!font-bold">
              Vendor: <span className="font-light">{vendorName}</span>
            </h1>
            <h1 className="!font-bold !text-sm">
              Store Front: <span className="font-light">{name}</span>
            </h1>
          </div>
          <div className="alt">
            <div>
              <CustomButton
                title={"Store Front List"}
                onClick={() => navigate(-1)}
                width={"!bg-purple-700"}
              />
            </div>
            {view === "L" ? (
              <div>
                <CustomButton title={"Create User"} onClick={updateAction} />
              </div>
            ) : (
              <div>
                <CustomButton title={"User List"} onClick={updateAction} />
              </div>
            )}
          </div>
        </div>
        {view === "L" ? (
          <List storeFrontID={id} vendorName={changeVendorName} />
        ) : (
          <Create storeFrontID={id} moveToList={updateAction} />
        )}
      </div>
    </>
  );
};

export default Manage;
