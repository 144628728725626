import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import axiosInstance from "../constants/axiosInstance";
import { API_URL } from "../constants/ConstantData";
import validity from "../error/UserLoginResetError";
import "../style/login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { updateLoading, updateResetToken } from "../store/actions/User";
import { emailField } from "../error/FieldErrorCheck";

const ForgetPassword = () => {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const hitLink = "auth/send-reset-token";
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [serverError, setServerError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateResetToken({}));
  }, []);

  const resetPassword = async () => {
    const userData = {
      email,
      type: "RESET",
    };

    const valid = validity(userData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(API_URL + hitLink, JSON.stringify(userData))
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success(
              "An email sent to your E-mail address. Please check and reset.",
              { autoClose: 2000 }
            );
            let token = "";
            var charactersLength = characters.length;
            for (var i = 0; i < 25; i++) {
              token += characters.charAt(
                Math.floor(Math.random() * charactersLength)
              );
            }
            dispatch(updateResetToken({ email, token }));
            navigate("/user/password/" + token);
          }
          dispatch(updateLoading(false));
        })
        .catch((err) => {
          setServerError(err.response.data.error.message);
          toast.error(err.response.data.error.message, { autoClose: 2000 });
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot your password?</title>
      </Helmet>
      <div className="loginField">
        <div className="loginBox">
          <div className="loginHeader">
            <h1>Reset Password</h1>
            {serverError ? <h2 className="errorMsg">{serverError}</h2> : ""}
            <p>
              Forgot your password? Please enter your account email below and we
              will send you a reset link if your email is registered with us
            </p>
          </div>
          <div className="inputBody">
            <CustomInput
              label={"E-mail"}
              value={email}
              placeholder={"Type in your email address"}
              onChange={(e) => {
                setEmail(e.target.value);
                let err = emailField({ email: e.target.value });
                setError(err);
              }}
              type="text"
              error={error.email ? true : false}
              errorMsg={error.email}
            />

            <CustomButton title={"Send Reset Link"} onClick={resetPassword} />
          </div>
          <div className="alreadyRegister">
            <p>
              Remembered your password? <Link to={"/login"}>Login</Link>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ForgetPassword;
