export const RulesList = [
  { key: 1, value: "User.Create" },
  { key: 2, value: "User.View" },
  { key: 3, value: "User.Update" },
  { key: 4, value: "User.Banned" },
  { key: 5, value: "Role.Create" },
  { key: 6, value: "Role.View" },
  { key: 7, value: "Role.Update" },
  { key: 8, value: "Role.Permission.Update" },
  { key: 9, value: "User.Assign.Role.Update" },
  { key: 10, value: "User.Assign.Role" },
  { key: 20, value: "Vendor.Create" },
  { key: 21, value: "Vendor.View" },
  { key: 22, value: "Vendor.Update" },
  { key: 23, value: "Vendor.Approve" },
  { key: 24, value: "Storefront.Create" },
  { key: 25, value: "Storefront.Update" },
  { key: 26, value: "Storefront.View" },
  { key: 27, value: "Storefront.Delete" },
  { key: 28, value: "Storefront.User.Create" },
  { key: 29, value: "Storefront.User.Update" },
  { key: 30, value: "Storefront.User.view" },
  { key: 31, value: "Storefront.User.Banned" },
  { key: 32, value: "Order.Create" },
  { key: 33, value: "Order.View" },
  { key: 34, value: "Order.Summary.View" },
  { key: 35, value: "User.List.View" },
  { key: 36, value: "Payout.Create" },
  { key: 37, value: "Payout.View" },
  { key: 38, value: "Payout.Update" },
  { key: 39, value: "Vendor.User.Create" },
  { key: 40, value: "Vendor.User.Update" },
  { key: 41, value: "Vendor.User.Delete" },
  { key: 42, value: "Notification.Create" },
  { key: 43, value: "Notification.Update" },
  { key: 44, value: "Notification.List.View" },
  { key: 45, value: "Notification.Delete" },
  { key: 46, value: "SysConfig.View" },
  { key: 47, value: "SysConfig.Update" },
  { key: 48, value: "User.Password.Update" },
  { key: 49, value: "Storefront.Fee.Update" },
];

export const getRuleTitle = (id) => {
  const rules = {
    1: "User.Create",
    2: "User.View",
    3: "User.Update",
    4: "User.Banned",
    5: "Role.Create",
    6: "Role.View",
    7: "Role.Update",
    8: "Role.Permission.Update",
    9: "User.Assign.Role.Update",
    10: "User.Assign.Role",
    20: "Vendor.Create",
    21: "Vendor.View",
    22: "Vendor.Update",
    23: "Vendor.Approve",
    24: "Storefront.Create",
    25: "Storefront.Update",
    26: "Storefront.View",
    27: "Storefront.Delete",
    28: "Storefront.User.Create",
    29: "Storefront.User.Update",
    30: "Storefront.User.view",
    31: "Storefront.User.Banned",
    32: "Order.Create",
    33: "Order.View",
    34: "Order.Summary.View",
    35: "User.List.View",
    36: "Payout.Create",
    37: "Payout.View",
    38: "Payout.Update",
    39: "Vendor.User.Create",
    40: "Vendor.User.Update",
    41: "Vendor.User.Delete",
    42: "Notification.Create",
    43: "Notification.Update",
    44: "Notification.List.View",
    45: "Notification.Delete",
    46: "SysConfig.View",
    47: "SysConfig.Update",
    48: "User.Password.Update",
    49: "Storefront.Fee.Update",
  };
  return rules[id];
};

export const userRulePermission = (rule_id, permissions) => {
  const rules = {
    1: "User.Create",
    2: "User.View",
    3: "User.Update",
    4: "User.Banned",
    5: "Role.Create",
    6: "Role.View",
    7: "Role.Update",
    8: "Role.Permission.Update",
    9: "User.Assign.Role.Update",
    10: "User.Assign.Role",
    20: "Vendor.Create",
    21: "Vendor.View",
    22: "Vendor.Update",
    23: "Vendor.Approve",
    24: "Storefront.Create",
    25: "Storefront.Update",
    26: "Storefront.View",
    27: "Storefront.Delete",
    28: "Storefront.User.Create",
    29: "Storefront.User.Update",
    30: "Storefront.User.view",
    31: "Storefront.User.Banned",
    32: "Order.Create",
    33: "Order.View",
    34: "Order.Summary.View",
    35: "User.List.View",
    36: "Payout.Create",
    37: "Payout.View",
    38: "Payout.Update",
    39: "Vendor.User.Create",
    40: "Vendor.User.Update",
    41: "Vendor.User.Delete",
    42: "Notification.Create",
    43: "Notification.Update",
    44: "Notification.List.View",
    45: "Notification.Delete",
    46: "SysConfig.View",
    47: "SysConfig.Update",
    48: "User.Password.Update",
    49: "Storefront.Fee.Update",
  };

  const ruleArr = Object.keys(rules);
  if (permissions.indexOf(rule_id) > -1) {
    if (ruleArr.indexOf(rule_id.toString()) > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const rulePermission = (rule_id, userInfo) => {
  const permissions = userInfo.permissions;

  const rules = {
    1: "User.Create",
    2: "User.View",
    3: "User.Update",
    4: "User.Banned",
    5: "Role.Create",
    6: "Role.View",
    7: "Role.Update",
    8: "Role.Permission.Update",
    9: "User.Assign.Role.Update",
    10: "User.Assign.Role",
    20: "Vendor.Create",
    21: "Vendor.View",
    22: "Vendor.Update",
    23: "Vendor.Approve",
    24: "Storefront.Create",
    25: "Storefront.Update",
    26: "Storefront.View",
    27: "Storefront.Delete",
    28: "Storefront.User.Create",
    29: "Storefront.User.Update",
    30: "Storefront.User.view",
    31: "Storefront.User.Banned",
    32: "Order.Create",
    33: "Order.View",
    34: "Order.Summary.View",
    35: "User.List.View",
    36: "Payout.Create",
    37: "Payout.View",
    38: "Payout.Update",
    39: "Vendor.User.Create",
    40: "Vendor.User.Update",
    41: "Vendor.User.Delete",
    42: "Notification.Create",
    43: "Notification.Update",
    44: "Notification.List.View",
    45: "Notification.Delete",
    46: "SysConfig.View",
    47: "SysConfig.Update",
    48: "User.Password.Update",
    49: "Storefront.Fee.Update",
  };

  const ruleArr = Object.keys(rules);
  if (permissions.indexOf(rule_id) > -1) {
    if (ruleArr.indexOf(rule_id.toString()) > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
