import validator from "validator";

const validity = (userData) => {
  let error = {};

  if (!userData.email || userData.email === "") {
    error.email = "E-mail is requried";
  } else {
    if (!validator.isEmail(userData.email)) {
      error.email = "Invalid email";
    }
  }

  if (userData.type === "LOGIN") {
    if (!userData.password || userData.password === "") {
      error.password = "Password is requried";
    }
    // let passErr = getPasswordError(userData);
    // if (passErr !== "") {
    //   error.password = passErr;
    // }
  }

  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
