import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosInstance";
import { logOut, updateLoading } from "../../store/actions/User";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { MdDragIndicator } from "react-icons/md";
import ChangePassword from "../../components/ChangePassword";

const List = ({ storeFrontID, vendorName }) => {
  const hitLink = "get-store-fronts";
  const userStatusUpdateLink = "update-store-front-users";
  const dispatch = useDispatch();

  const [userList, setUserList] = useState([]);
  const [storeFrontId, setStoreFrontId] = useState(storeFrontID);
  const { user, access_token } = useSelector((state) => state.user);
  const [reload, setReload] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [user_id, setUser_id] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("click", autoClose);
    }

    return () => window.removeEventListener("click", autoClose);
  }, []);

  const autoClose = (e) => {
    let nodes = document.querySelectorAll(".subMenu");
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      node.classList.remove("!h-[60px]");
    }

    if (e.target.parentNode.classList.contains("subMenuPark")) {
      e.target.parentNode.querySelector(".subMenu").classList.add("!h-[60px]");
    } else {
      for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i];
        node.classList.remove("!h-[60px]");
      }
    }
  };

  useEffect(() => {
    if (!storeFrontID) {
      setStoreFrontId(user.others_info.store_fronts[0].id);
    }
    getUserList();
  }, [storeFrontId, reload]);

  const getUserList = async () => {
    if (storeFrontId !== undefined) {
      dispatch(updateLoading(true));
      await axiosInstance
        .get(hitLink + "/" + storeFrontId, { token: access_token })
        .then((res) => {
          if (res.data.result_code === 0) {
            if (vendorName !== undefined) {
              vendorName(res.data.result[0].vendor_name);
            }
            setUserList(res.data.result);
          }
          dispatch(updateLoading(false));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.result_code === 11) {
              dispatch(logOut());
            }
          }
          dispatch(updateLoading(false));
        });
    }
  };

  // const changeSubMenuView = (e) => {
  //   e.target.parentElement.parentElement
  //     .querySelector("div.subMenu")
  //     .classList.contains("!h-[30px]")
  //     ? e.target.parentElement.parentElement
  //         .querySelector("div.subMenu")
  //         .classList.remove("!h-[30px]")
  //     : e.target.parentElement.parentElement
  //         .querySelector("div.subMenu")
  //         .classList.add("!h-[30px]");
  // };

  // const userDelete = async (id) => {
  //   confirmAlert({
  //     title: "Delete User",
  //     message: "Are you sure to delete this user?",
  //     buttons: [
  //       {
  //         label: "Sure! Of course",
  //         onClick: async () => {
  //           // dispatch(updateLoading(true));
  //           // await axiosInstance
  //           //   .post(userDeleteLink, {
  //           //     user_id: id,
  //           //     store_front_id: storeFrontID,
  //           //     is_banned: 0,
  //           //   })
  //           //   .then((res) => {
  //           //     setTimeout(() => {
  //           //       updateLoading(false);
  //           //     }, 500);
  //           //   })
  //           //   .catch((error) => {
  //           //     setTimeout(() => {
  //           //       updateLoading(false);
  //           //     }, 500);
  //           //   });
  //         },
  //       },
  //       {
  //         label: "Not Sure",
  //         onClick: () => {
  //           onclose();
  //         },
  //       },
  //     ],
  //   });
  // };

  const updateUserStatus = async (e, user_id) => {
    let str = e.target.innerText;
    confirmAlert({
      title: str === "Inactive" ? "Inactive User" : "Active User",
      message:
        str === "Active"
          ? "Do you want active this user?"
          : "Do you want to inactive this user?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));
            let status = 1;
            if (str === "Inactive") {
              status = 0;
            }
            const postData = {
              user_id,
              store_front_id: storeFrontID,
              status,
            };
            await axiosInstance
              .post(userStatusUpdateLink, postData, { token: access_token })
              .then((res) => {
                if (res.data.result_code === 0) {
                  toast.success("User status updated successfully");
                  reload ? setReload(false) : setReload(true);
                } else {
                  toast.success("Failed to update status");
                }
                dispatch(updateLoading(false));
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      closeOnClickOutside: false,
    });
  };

  const passwordChange = (id) => {
    setUser_id(id);
    setChangePassword(true);
  };

  const changeFieldView = () => {
    setUser_id("");
    setChangePassword(false);
  };

  return (
    <>
      <Helmet>
        <title>Store Front User List - Smooth Pay</title>
      </Helmet>
      {/* {update ? (
        <Update
          userID={userID}
          f_name={first_name}
          l_name={last_name}
          onClick={() => setUpdate(false)}
        />
      ) : null} */}

      {changePassword && (
        <ChangePassword userID={user_id} viewChange={changeFieldView} />
      )}

      <div className="adminBox">
        {storeFrontID === undefined ? (
          <div className="manageActionField mb-6">
            <div>
              <h1 className="!font-light">
                Vendor:{" "}
                <span className="font-normal">
                  {userList.length > 0 ? userList[0].vendor_name : ""}
                </span>
              </h1>
              <h1 className="!font-light !text-sm">
                Store Front:{" "}
                <span className="font-normal">
                  {userList.length > 0 ? userList[0].sf_name : ""}
                </span>
              </h1>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="userListBox">
          <div className="userListField min-h-[280px]">
            {userList.length > 0 ? (
              userList[0].storefront_users.length > 0 ? (
                userList[0].storefront_users.map((data, index) => {
                  let status;
                  if (data.status === 0) {
                    status = (
                      <span
                        className="greenBar"
                        onClick={(e) => {
                          updateUserStatus(e, data.id);
                        }}
                      >
                        Active
                      </span>
                    );
                  } else {
                    status = (
                      <span
                        className="redBar"
                        onClick={(e) => {
                          updateUserStatus(e, data.id);
                        }}
                      >
                        Inactive
                      </span>
                    );
                  }
                  return (
                    <div className="userBox" key={index}>
                      <div className="subMenuPark">
                        <div>
                          <MdDragIndicator />
                        </div>
                        <div className={"subMenu"}>
                          <ul>
                            <li>{status}</li>
                            {/* <li>
                              <a
                                href="#update account"
                                onClick={() => {
                                  setUpdate(true);
                                  setUserID(data.id);
                                  setFirst_name(data.first_name);
                                  setLast_name(data.last_name);
                                }}
                              >
                                Update Account
                              </a>
                            </li> */}
                            <li>
                              <span
                                className="greenBar"
                                onClick={() => {
                                  passwordChange(data.id);
                                }}
                              >
                                Change Password
                              </span>
                            </li>
                            {/* <li>
                              <a
                                href="#update account"
                                onClick={() => userDelete(data.id)}
                              >
                                Delete
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="bodyDetails">
                        <div>
                          <p>First Name:</p>
                          <p>Last Name:</p>
                          <p>E-mail:</p>
                          <p>Role:</p>
                          <p>Status:</p>
                        </div>
                        <div>
                          <p>{data.first_name}</p>
                          <p>{data.last_name}</p>
                          <p>{data.email}</p>
                          <p className="font-bold">
                            {data.role_id === 12 ? "Basic User" : "Manager"}
                          </p>
                          <p>{data.status === 1 ? "Active" : "Inactive"}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="min-h-[280px] grid place-content-center">
                  <p className="font-light text-slate-500">
                    No Store Front User Found
                  </p>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default List;
