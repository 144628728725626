import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../constants/axiosInstance";
import validity from "../error/ChangePassError";
import {
  confirmPasswordField,
  newPasswordField,
} from "../error/FieldErrorCheck";
import { logOut, updateLoading } from "../store/actions/User";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";

const ChangePassword = ({ userID, viewChange }) => {
  const hitPassChangeLink = "auth/update-user-password";
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.user);

  const updatePassword = async () => {
    const postData = {
      user_id: userID,
      new_password,
      confirm_password,
      type: "Admin",
    };
    console.log(postData);
    const valid = validity(postData);
    if (valid.isValid) {
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitPassChangeLink, postData, { token: access_token })
        .then((res) => {
          if (res.data.result_code === 0) {
            toast.success("Password changed successfully!", {
              theme: "colored",
            });
            emptyField();
            viewChange();
          } else {
            toast.error(res.data.error.message, { autoClose: 2000 });
          }
          dispatch(updateLoading(false));
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.data.result_code === 11) {
              dispatch(logOut());
            }
          } else {
            toast.error(err.message, {
              theme: "colored",
            });
          }
          dispatch(updateLoading(false));
        });
    } else {
      setError(valid.error);
    }
  };

  const emptyField = () => {
    setNew_password("");
    setConfirm_password("");
  };

  return (
    <div className="popupPanel">
      <div className="updateField max-w-md">
        <MdClose
          size={18}
          color="#000"
          className="closeTag"
          onClick={viewChange}
        />
        <div className="loginHeader">
          <h1>Change Password</h1>
        </div>

        <div className="inputBody max-h-[480px] overflow-y-auto">
          <CustomInput
            label={"New Password"}
            value={new_password}
            type="password"
            placeholder={"Enter New Password"}
            onChange={(e) => {
              setNew_password(e.target.value);
              let err = newPasswordField({ new_password: e.target.value });
              err ? setError(err) : setError({});
            }}
            error={error.new_password ? true : false}
            errorMsg={error.new_password}
            required={true}
          />
          <CustomInput
            label={"Confirm Password"}
            value={confirm_password}
            type="password"
            placeholder={"Enter Confirm Password"}
            onChange={(e) => {
              setConfirm_password(e.target.value);
              let err = confirmPasswordField({
                confirm_password: e.target.value,
                password: new_password,
              });
              err ? setError(err) : setError({});
            }}
            error={error.confirm_password ? true : false}
            errorMsg={error.confirm_password}
            required={true}
          />

          <CustomButton title={"Change Password"} onClick={updatePassword} />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default ChangePassword;
