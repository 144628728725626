import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axiosInstance from "../constants/axiosInstance";
import { logOut, updateLoading } from "../store/actions/User";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { rulePermission } from "../constants/Rules";

const PendingVendorView = ({ changeView }) => {
  const hitPendingVendorList = "get-vendors";
  const approveLink = "approve-vendors/";
  const [venodrs, setVendors] = useState([]);

  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  useEffect(() => {
    getCurrentvendors();
  }, []);

  const getCurrentvendors = async () => {
    const params = {
      page: 1,
      limit: 7,
      is_approved: 0,
      order_by: "id,desc",
    };

    await axiosInstance
      .get(hitPendingVendorList, {
        params: params,
        token: access_token,
      })
      .then((res) => {
        if (res.data.result_code === 0) {
          if (res.data.result.rows.length > 0) {
            changeView(true);
          } else {
            changeView(false);
          }
          setVendors(res.data.result.rows);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.result_code === 11) {
            dispatch(logOut());
          }
        }
      });
  };

  const changeVendorStatus = (e, vendor_id) => {
    confirmAlert({
      title: "Vendor Approval",
      message: "Are you sure to approve this vendor?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Confirm",
          onClick: async () => {
            dispatch(updateLoading(true));
            const approveData = {
              is_approved: 1,
            };
            await axiosInstance
              .post(approveLink + vendor_id, JSON.stringify(approveData), {
                token: access_token,
              })
              .then((res) => {
                if (res.data.result_code === 0) {
                  toast.success("Approved successfully", { autoClose: 1000 });
                  getCurrentvendors();
                } else {
                  toast.error(res.data.error.message, { autoClose: 1000 });
                }
                dispatch(updateLoading(false));
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.result_code === 11) {
                    dispatch(logOut());
                  }
                }
                dispatch(updateLoading(false));
              });
          },
        },
      ],
      onClickOutside: false,
    });
  };

  return (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venodrs.length > 0 ? (
                venodrs.map((vendor, index) => {
                  let status;
                  if (vendor.is_approved === 0) {
                    status = (
                      <span
                        className="statusTag pending !text-xs !p-2 !py-1"
                        onClick={(e) => {
                          changeVendorStatus(e, vendor.id);
                        }}
                      >
                        Pending
                      </span>
                    );
                  } else {
                    status = (
                      <span
                        className="statusTag approved"
                        onClick={(e) => {
                          changeVendorStatus(e, vendor.id);
                        }}
                      >
                        Approved
                      </span>
                    );
                  }
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{vendor.vendor_name}</TableCell>
                      <TableCell>{vendor.email}</TableCell>
                      <TableCell>{vendor.vendor_phone}</TableCell>
                      <TableCell>
                        {rulePermission(22, user)
                          ? status
                          : vendor.is_approved === 0
                          ? "Pending"
                          : "Approved"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No Data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="flex justify-end mr-2">
            <Link
              to={"/admin/vendor/list/pending"}
              className="text-sm text-theme my-2"
            >
              See More
            </Link>
          </div>
        </TableContainer>
      </Paper>
      <ToastContainer />
    </>
  );
};

export default PendingVendorView;
