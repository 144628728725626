import React, { useEffect, useState } from "react";
import "../style/login.css";
import "../style/global.css";
import { Helmet } from "react-helmet";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import validity from "../error/UserLoginResetError";
import axiosInstance from "../constants/axiosInstance";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { login, updateLoading, updateResetToken } from "../store/actions/User";
import { emailField } from "../error/FieldErrorCheck";

const Login = () => {
  const hitLink = "auth/login";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverError, setServerError] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    dispatch(updateLoading(false));
    dispatch(updateResetToken({}));
  }, []);

  const userLogin = async () => {
    const userData = {
      email,
      password,
      type: "LOGIN",
    };

    const valid = validity(userData);
    if (valid.isValid) {
      setError({});
      dispatch(updateLoading(true));
      await axiosInstance
        .post(hitLink, JSON.stringify(userData))
        .then(async (res) => {
          console.log(res);
          let data = res.data;

          if (data.result_code === 0 && data.result.userInfo.role_id !== 12) {
            setServerError("");
            let result = data.result;
            toast.success("Logged in successfully", { autoClose: 1000 });
            dispatch(
              login({
                user: result.userInfo,
                access_token: result.access_token,
                refresh_token: result.refresh_token,
                vendor:
                  data.result.userInfo.role_id === 11 ||
                  data.result.userInfo.role_id === 14
                    ? data.result.userInfo?.others_info?.vendors[0]
                    : {},
              })
            );
            dispatch(updateLoading(false));
            navigate("/admin/dashboard");
          } else if (data.result.userInfo.role_id === 12) {
            setServerError("User not permitted to login");
            dispatch(updateLoading(false));
          } else {
            setServerError(res.data.error.message);
            dispatch(updateLoading(false));
          }
        })
        .catch((err) => {
          setServerError(err.response.data.error.message);
          dispatch(updateLoading(false));
        });
    } else {
      setServerError("");
      setError(valid.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Smooth Pay Admin Panel</title>
      </Helmet>
      <div className="loginField">
        <div className="loginBox">
          {/* ----Login Box header View---- */}
          <div className="loginHeader">
            <h1>Login</h1>
            {serverError ? <h2 className="errorMsg">{serverError}</h2> : ""}
          </div>

          {/* ----Login Box Body View---- */}
          <div className="inputBody">
            {/* ----Sign in e-mail field---- */}
            <CustomInput
              label={"E-mail"}
              value={email}
              type={"text"}
              placeholder={"Enter E-mail Address"}
              onChange={(e) => {
                setEmail(e.target.value);
                let err = emailField({ email: e.target.value, type: "email" });
                err ? setError(err) : setError({});
              }}
              error={error?.email ? true : false}
              errorMsg={error?.email}
              required={true}
            />

            {/* ----Sign in password field---- */}
            <CustomInput
              label={"Password"}
              value={password}
              type={"password"}
              placeholder={"Enter Password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              error={error?.password ? true : false}
              errorMsg={error?.password}
              required={true}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  userLogin();
                }
              }}
            />

            <CustomButton
              title={"Login"}
              onClick={userLogin}
              width={"w-full"}
            />
          </div>
          <div className="loginFooter">
            <Link to={"/forget-password"} className="forgetLink">
              Forgot Password?
            </Link>
            <p>
              Don't have an account?
              <Link to={"/create-account"}>Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
