const validity = (payout) => {
  let error = {};

  if (!payout.paid_amount || payout.paid_amount === "") {
    error.paid_amount = "Paid amount is required";
  }

  if (!payout.paid_id || payout.paid_id === "") {
    error.paid_id = "Paid ID is required";
  }
  return {
    error,
    isValid: Object.keys(error).length === 0,
  };
};

export default validity;
