import validator from "validator";
let error = {};
const required = " is required";
let regex = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;

const otpField = (data) => {
  error = {};
  if (!data.otp || data.otp === "") {
    error.otp = "OTP" + required;
  }
  return error;
};

const firstNameField = (data) => {
  error = {};
  if (!data.first_name || data.first_name === "") {
    error.first_name = "First name" + required;
  }
  return error;
};

const lastNameFiled = (data) => {
  error = {};
  if (!data.last_name || data.last_name === "") {
    error.last_name = "Last name" + required;
  }
  return error;
};

const emailField = (data) => {
  error = {};
  if (!data.email || data.email === "") {
    error.email = "E-mail" + required;
  } else {
    if (!validator.isEmail(data.email)) {
      error.email = "Invalid email";
    }
  }
  return error;
};

const passwordField = (data) => {
  error = {};
  let password = passwordCheck(data.password);

  if (password.length > 0) {
    error.password = password.join("\n");
  }
  return error;
};

const confirmPasswordField = (data) => {
  error = {};

  if (!data.confirm_password || data.confirm_password === "") {
    error.confirm_password = "Password" + required;
  } else {
    if (data.confirm_password !== data.password) {
      error.confirm_password = "Password didn't match";
    }
  }
  return error;
};

const vendorNameField = (data) => {
  error = {};
  if (!data.vendor_name || data.vendor_name === "") {
    error.vendor_name = "Vendor name" + required;
  }
  return error;
};

const vendorPhoneField = (data) => {
  error = {};
  if (
    (!data.vendor_phone ||
      data.vendor_phone === "" ||
      data.vendor_phone === "+(") &&
    data.vendor_phone !== "E"
  ) {
    error.vendor_phone = "Vendor phone" + required;
  }
  return error;
};

const vendorAddressField = (data) => {
  error = {};
  if (!data.vendor_address || data.vendor_address === "") {
    error.vendor_address = "Vendor address" + required;
  }
  return error;
};

const vendorLocationField = (data) => {
  error = {};
  if (!data.vendor_location || data.vendor_location === "") {
    error.vendor_location = "Vendor location" + required;
  }
  return error;
};

const vendorWebsiteField = (data) => {
  error = {};
  if (!data.vendor_website || data.vendor_website === "") {
    error.vendor_website = "Vendor website" + required;
  }
  // else {
  //   let url = new URL(data.vendor_website);
  //   console.log(url);
  //   if (!url) {
  //     error.vendor_website = "Invalid Site Url";
  //   }
  // }
  return error;
};

const storeFrontNameField = (data) => {
  error = {};
  if (!data.sf_name || data.sf_name === "") {
    error.sf_name = "Store front name" + required;
  }
  return error;
};

const storeFrontPhoneField = (data) => {
  error = {};
  if (
    (!data.sf_phone || data.sf_phone === "" || data.sf_phone === "+(") &&
    data.sf_phone !== "E"
  ) {
    error.sf_phone = "Store front phone" + required;
  }
  return error;
};

const contactPersonNameField = (data) => {
  error = {};
  if (!data.contact_person_name || data.contact_person_name === "") {
    error.contact_person_name = "Contact person name" + required;
  }
  return error;
};

const contactPersonPhoneField = (data) => {
  error = {};
  if (
    !data.contact_person_phone ||
    data.contact_person_phone === "" ||
    (data.contact_person_phone === "+(" && data.contact_person_phone !== "E")
  ) {
    error.contact_person_phone = "Contact person phone" + required;
  }
  return error;
};

const sfAddressField = (data) => {
  error = {};
  if (!data.sf_address || data.sf_address === "") {
    error.sf_address = "Store front address" + required;
  }
  return error;
};

const sfLocationField = (data) => {
  error = {};
  if (!data.sf_location || data.sf_location === "") {
    error.sf_address = "Store front location" + required;
  }
  return error;
};

const cityField = (data) => {
  error = {};
  if (!data.city || data.city === "") {
    error.city = "City" + required;
  }
  return error;
};

const stateField = (data) => {
  error = {};
  if (!data.state || data.state === "") {
    error.state = "State" + required;
  }
  return error;
};

const countryField = (data) => {
  error = {};
  if (!data.country || data.country === "") {
    error.country = "Country" + required;
  }
  return error;
};

const oldPasswordField = (data) => {
  error = {};
  let password = passwordCheck(data.old_password);
  if (password.length > 0) {
    error.old_password = password.join("\n");
  }
  return error;
};

const newPasswordField = (data) => {
  error = {};
  let password = passwordCheck(data.new_password);
  if (password.length > 0) {
    error.new_password = password.join("\n");
  }
  return error;
};

const passwordCheck = (password) => {
  let passwordErr = [];
  if (!password || password === "") {
    passwordErr.push("Password" + required);
  }
  if (passwordErr.length === 0) {
    if (!regex.test(password)) {
      passwordErr.push(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  }
  return passwordErr;
};

const inputFieldValidator = (data) => {
  const validArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+"];
  if (validArr.indexOf(data.key) > -1) {
    return true;
  } else {
    return false;
  }
};

const companyNameField = (data) => {
  error = {};
  if (!data.company_name || data.company_name === "") {
    error.company_name = "Company Name" + required;
  }
  return error;
};

const companyAddressField = (data) => {
  error = {};
  if (!data.company_address || data.company_address === "") {
    error.company_address = "Company Address" + required;
  }
  return error;
};

const phoneNumberField = (data) => {
  error = {};
  if (
    !data.phone ||
    data.phone === "" ||
    (data.phone === "+(" && data.phone !== "E")
  ) {
    error.phone = "Phone Number" + required;
  }
  return error;
};

const bankNameField = (data) => {
  error = {};
  if (!data.bank_name || data.bank_name === "") {
    error.bank_name = "Bank Name" + required;
  }
  return error;
};

const bankAddressField = (data) => {
  error = {};
  if (!data.bank_address || data.bank_address === "") {
    error.bank_address = "Bank Address" + required;
  }
  return error;
};

const bankAccountField = (data) => {
  error = {};
  if (!data.bank_account || data.bank_account === "") {
    error.bank_account = "Bank Account" + required;
  }
  return error;
};

const bankRouteField = (data) => {
  error = {};
  if (!data.bank_route || data.bank_route === "") {
    error.bank_route = "Bank Route" + required;
  }
  return error;
};

const confirmBankAccountField = (data) => {
  error = {};
  if (!data.confirmBank_account || data.confirmBank_account === "") {
    error.confirmBank_account = "Confirm Bank Account" + required;
  }

  if (data.confirmBank_account !== data.bank_account) {
    error.confirmBank_account = "Bank Account Didn't match";
  }
  return error;
};

const configValueField = (data) => {
  error = {};
  if (!data.value || data.value === "") {
    error.value = "Value" + required;
  }

  return error;
};

const configDetailsField = (data) => {
  error = {};
  if (!data.description || data.description === "") {
    error.description = "Description" + required;
  }

  return error;
};

export {
  configValueField,
  configDetailsField,
  inputFieldValidator,
  firstNameField,
  lastNameFiled,
  emailField,
  passwordField,
  confirmPasswordField,
  vendorNameField,
  vendorPhoneField,
  vendorLocationField,
  vendorAddressField,
  vendorWebsiteField,
  storeFrontNameField,
  storeFrontPhoneField,
  contactPersonNameField,
  contactPersonPhoneField,
  sfAddressField,
  sfLocationField,
  cityField,
  stateField,
  countryField,
  oldPasswordField,
  newPasswordField,
  otpField,
  passwordCheck,
  companyNameField,
  companyAddressField,
  phoneNumberField,
  bankNameField,
  bankAddressField,
  bankAccountField,
  bankRouteField,
  confirmBankAccountField,
};
